import React from 'react';
import { arrayOf, func, shape, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { formatCurrency } from '@tesla/coin-common-components';
import {
  FormInputOptionGroup,
  FormLayout,
  FormInputOption,
  FormInputChoiceGroup,
  FormInputChoice,
} from '@tesla/design-system-react';
import { toggleAccessoryItemWithShipping } from 'actions';
import { i18n } from 'utils';
import {
  getAccessoriesListByGroup,
  getSelectedAccessories,
  isEnterpriseOrder,
  isInventory,
  getAccessoriesAssetsBase,
  getTrimCode,
} from 'selectors';
import { ACCESSORIES_OPTION_MAP, ACCESSORIES_VARIANT_MAP } from 'dictionary';
import AssetLoader from '../AssetLoader';
import cx from 'classnames';
import { htmlToReact } from 'utils';

const AccessoriesList = ({
  chargingConnectorIncluded, 
  list,
  toggleItem,
  selectedItems,
  isEnterprise,
  isInventory,
  isReserve,
  currency,
  configOptions,
  showAsset,
  assetsBase,
  isCoinReloaded,
  compact,
  enableCyberpunk,
  trimVariant,
  islightThemeEnabled,
}) => {
  if (!list.length) {
    return null;
  }

  return (
    <Choose>
      <When condition={isCoinReloaded}>
        <FormLayout
          className={cx('accessories-list-layout',
            { 'tds-flex--justify-center': showAsset },
            { 'tds--padding_top-8': compact },
            { 'tds--padding_top-16': !compact })}
        >
          <FormInputOptionGroup
            className={cx(
              { 'group--options_block-container_choice_fullwidth': !showAsset },
              { 'accessories-block-wtih-asset': showAsset }
            )}
          >
            {list.map(({ name, options, productType, productBadge, productMetaTag, productFeature, productGroup }) =>
              options.map(({ purchasePrice, alternateCurrencyPurchasePrice, partNumber, features, sizeOption }) => {
                const optionCode = ACCESSORIES_OPTION_MAP?.[sizeOption?.code] || '';
                const variantMap = ACCESSORIES_VARIANT_MAP?.[sizeOption?.code] || '';
                const isSelectedOption = Boolean(selectedItems[partNumber]);
                if (productGroup === "MOBILE_CONNECTOR" && chargingConnectorIncluded) {
                  return null;
                }
            
                if ((optionCode && !configOptions?.includes(optionCode)) || (variantMap && !variantMap?.includes(trimVariant))) {
                  if (isSelectedOption) {
                    toggleItem({
                      partNumber,
                    });
                  }
                  return null;
                }

                return (
                  <FormInputOption
                    key={`Accessories:CheckboxWrapper:${productType}:${partNumber}`}
                    id={`${productType}:${partNumber}`}
                    name={`${productType}:${partNumber}`}
                    onClick={() =>
                      toggleItem({
                        partNumber,
                      })
                    }
                    showVisualCheckbox
                    data-code={partNumber}
                    checked={isSelectedOption}
                    leading={
                      !showAsset && (
                        <span className={cx("tds-text--body", {
                          "tds-text--bold" : !islightThemeEnabled,
                          "tds-text--medium" : !enableCyberpunk,
                        })}>
                          {isReserve ? i18n('common.reserve_today') : name}
                        </span>
                      )
                    }
                    trailing={
                      !showAsset && (
                        <span className={cx("tds-text--body", {
                          "tds-text--medium" : !islightThemeEnabled,
                        })}>
                          {formatCurrency(purchasePrice?.amount, { currency })}
                        </span>
                      )
                    }
                    className="accessories-list-option tds-flex--top tds-flex-item"
                    label={
                      <If condition={showAsset}>
                        <div className="accessories-list-option--label tds-text--contrast-high tds-flex-item tds-flex--justify-center">
                          <AssetLoader
                            asset={{
                              url: `${assetsBase}${productMetaTag.image}`,
                              type: 'image',
                              absolute: true,
                              ratio: 'slice',
                            }}
                            label={name}
                            title={name}
                            className="accessories-asset--container"
                          />

                          <span className="tds-text--contrast-high tds-text--caption tds-text--medium tds-text--center">
                             <Choose>
                                <When condition={isEnterprise && alternateCurrencyPurchasePrice?.amount}>
                                   {formatCurrency(alternateCurrencyPurchasePrice?.amount)}
                                </When>
                                <Otherwise>
                                   {formatCurrency(purchasePrice?.amount, { currency })}
                                </Otherwise>
                             </Choose>
                          </span>
                          <span className="tds-text--body tds-text--medium tds-text--center">{name}</span>
                          <If condition={productFeature}>
                          <span className="tds-text--caption tds-text--center">{productFeature}</span>
                          </If>
                        </div>
                      </If>
                    }
                  />
                );
              })
            )}
          </FormInputOptionGroup>
        </FormLayout>
      </When>
      <Otherwise>
        <FormLayout>
          <FormInputChoiceGroup className="group--options_block-container_choice_fullwidth">
            {list.map(({ name, options, productType, productBadge, productGroup }) =>
              options.map(({ purchasePrice, partNumber, features, sizeOption }) => {
                const optionCode = ACCESSORIES_OPTION_MAP?.[sizeOption?.code] || '';
                const variantMap = ACCESSORIES_VARIANT_MAP?.[sizeOption?.code] || '';
                const isSelectedOption = Boolean(selectedItems[partNumber]);
                if (productGroup === "MOBILE_CONNECTOR" && chargingConnectorIncluded) {
                  return null;
                }
  
                if ((optionCode && !configOptions?.includes(optionCode)) || (variantMap && !variantMap?.includes(trimVariant))) {
                  if (isSelectedOption) {
                    toggleItem({
                      partNumber,
                    });
                  }
                  return null;
                }
                return (
                  <FormInputChoice
                    key={`Accessories:CheckboxWrapper:${productType}:${partNumber}`}
                    id={`${productType}:${partNumber}`}
                    name={`${productType}:${partNumber}`}
                    onChange={() =>
                      toggleItem({
                        partNumber,
                      })
                    }
                    type="checkbox"
                    data-code={partNumber}
                    checked={isSelectedOption}
                    label={
                      <>
                        <span className="tds-flex-item group--options_block-container_label">
                          {isReserve ? i18n('common.reserve_today') : name}
                        </span>
                        <span className="tds-flex-item tds-flex--col_1of3 tds-text_color--black tds-text--medium group--options_block-container_price tds-o-inline-auto">
                          {formatCurrency(purchasePrice?.amount, { currency })}
                        </span>
                      </>
                    }
                    labelProps={{
                      caption: isEnterprise || isInventory || features ? '' : productBadge,
                      className: 'group--options_block-container_label_wrap',
                    }}
                  />
                );
              })
            )}
          </FormInputChoiceGroup>
        </FormLayout>
      </Otherwise>
    </Choose>
  );
};

function mapStateToProps(state, ownProps) {
  const { isCoinReloaded = false, enableCyberpunk, islightThemeEnabled } = state?.App || {};
  const { productType, isReserve = false, listType = 'category', code, productGroup} = ownProps;
  const list = getAccessoriesListByGroup(state, { listType, productType, code, productGroup });
  const selectedItems = getSelectedAccessories(state);
  const isEnterprise = isEnterpriseOrder(state);
  const assetsBase = getAccessoriesAssetsBase(state);

  return {
    list,
    selectedItems,
    isEnterprise,
    isInventory: isInventory(state),
    isReserve,
    currency: state?.Payment?.CurrencyCode,
    configOptions: state?.Configuration?.option_codes || [],
    assetsBase,
    isCoinReloaded,
    enableCyberpunk,
    trimVariant: _get(state, `OMS.lexicon.sku.trims.${getTrimCode(state)}.variant.code`, ''),
    islightThemeEnabled,
  };
}

const mapDispatchToProps = dispatch => ({
  toggleItem: item => dispatch(toggleAccessoryItemWithShipping(item)),
});

AccessoriesList.propTypes = {
  list: arrayOf(shape({})),
  toggleItem: func,
  selectedItems: shape({}),
  isEnterprise: bool.isRequired,
  isInventory: bool.isRequired,
  isReserve: bool,
  currency: string.isRequired,
  configOptions: arrayOf(string),
  compact: bool,
  trimVariant: string.isRequired,
};

AccessoriesList.defaultProps = {
  list: [],
  selectedItems: {},
  isReserve: false,
  configOptions: [],
  compact: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesList);
