import _get from 'lodash/get';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _intersection from 'lodash/intersection';
import {
  STRIPE_PAYMENTS,
  PUBLIC_ORDER,
  EDIT_DESIGN_SAVE,
  RESERVATION_ORDER,
  CONTEXT_DEFAULT,
  PAYMENT_SOURCE_RESERVATION,
  PICKUP_SERVICE_CENTER,
} from 'dictionary';
import { getSuperRegion } from '@tesla/intl-display-names';
import { Price } from '@web/tesla-rest-ds-services';
import {
  getInventoryBasePrice,
  getAccessoriesPayloadEnterprise,
  getDeviceType,
  getSelectedCompoundLocation,
  getMultiFlexDetails,
  getDeliveryContactDetails,
  getFalconDeliverySectionVersion,
  getTrimCode,
  getFinanceProductId
} from 'selectors';
import {
  getPriceReturnedFromAgent,
  getUiOptionsNotInConfiguration,
  getPricingContextCurrency,
  getTrafficSource,
  getTrafficSourceHistory,
  getVatRateForForwardCalculation,
} from 'utils';
import { formatCurrency } from '@tesla/coin-common-components';

export const getPricingContext = (state, pricingContext) => {
  const contextMap = _get(state?.OMS.lexicon, 'metadata.pricing.context_mapping', {});
  const context = pricingContext || state?.App?.defaultPricingContext;
  return getPricingContextCurrency({ context, contextMap });
};

export const getPayorName = state => state?.Payment?.PaymentDetail?.PayorName || null;

export const getPaymentType = state => state?.Payment?.PaymentDetail?.RedirectPaymentName || state?.Payment?.PaymentDetail?.PaymentType || 'CC';

export const getStateProvince = state => state?.Payment?.PaymentDetail?.BillingInfoDetail?.StateProvince || state?.SummaryPanel?.region_code || '';

export const getPostalCode = state => state?.ReviewDetails?.DeliveryDetails?.PostalCode || state?.Payment?.PaymentDetail?.BillingInfoDetail?.ZipCode || '';

/**
 * Returns true/false if client side encryption is enabled for this particular market
 * @param  {Object}  state [redux state]
 * @return {Boolean}
 */
export function isClientSideEncryptionEnabledMarket(state) {
  const processorList = ['Stripe', 'Adyen'];
  return !!_intersection(processorList, Object.keys(_get(state, 'Payment.configs'), {})).length;
}

/**
 * Returns params required by CreditCardEncryptor.encrypt method
 * @param  {Object} state [redux state]
 * @return {[type]}       [description]
 */
export function getEncryptorParams(state) {
  const { time, encryptor, configs, PaymentDetail } = _get(state, 'Payment', {});
  const countryCode = PaymentDetail.CountryCode || _get(state, 'OMS.oms_params.market');
  const region = getSuperRegion(countryCode);
  const encryptorKeyName =
    encryptor === STRIPE_PAYMENTS && region.code === 'REEU' ? 'key_other' : 'key';
  const key = _get(configs, `${encryptor}.${encryptorKeyName}`);
  return {
    encryptor,
    key,
    time,
  };
}

/**
 * Returns credit card information in format used by CreditCardEncryptor class
 * @param  {Object} state [redux state]
 * @return {Object}       [returns cc info entered by user]
 */
export function getCardData(state) {
  const { PayorName, CreditCardDetail = {}, BillingZipCode } = _get(
    state,
    'Payment.PaymentDetail',
    {}
  );
  const { AccountNumber, ExpirationMonth, ExpirationYear, VerificationNumber } = CreditCardDetail;
  return {
    number: AccountNumber,
    cvc: VerificationNumber,
    name: PayorName,
    month: ExpirationMonth,
    year: ExpirationYear,
    zip: BillingZipCode,
  };
}

/**
 * SCHEMA FOR INVENTORY (M3) ORDER PAYLOAD
 * Constructs payload needed for inventory order
 * @param  {Object} state [redux state]
 * @return {Object}       [required Model 3 Inventory order payload]
 */
export function getInventoryOrderWithPaymentSchema(state, opts = {}) {
  const { platform, countryCode: market, language } = _get(state, 'App');
  const useExisting = _get(state, 'Payment.CreditCardDetail.IsProfileExists', false);
  const VehiclePrice = _get(state, 'ReviewDetails.product.data.PurchasePrice', {});
  const { FirstName, LastName, Email, PhoneNumber, Password } = _get(
    state,
    'ReviewDetails.AccountDetail',
    {}
  );
  const { PayorName, CreditCardDetail, BillingZipCode: BillingPostalCode } = _get(
    state,
    'Payment.PaymentDetail',
    {}
  );

  const SaveWithProfile = _get(CreditCardDetail, 'AgreedToSaveProfile', false);
  const AccountDetails = { FirstName, LastName, Email, PhoneNumber, Password };
  const BrowserInfo = {
    ...platform,
    DeviceType: getDeviceType(state.App),
    trafficSource: getTrafficSource(),
    trafficSourceHistory: getTrafficSourceHistory(),
  };
  const { VIN: Vin } = _get(state, 'ReviewDetails.product.data');

  const { ExpirationMonth, ExpirationYear, CardType, AccountNumber = '' } = CreditCardDetail;
  const LastFourDigits = AccountNumber.substring(AccountNumber.length - 4);

  const cardInfo = SaveWithProfile
    ? {
        ExpirationMonth,
        ExpirationYear,
        PayorName,
        CardType,
        LastFourDigits,
      }
    : {};

  const session = {
    SessionToken: opts.CreditCardToken || null,
  };

  // strip out null & undefined params
  const result = _omitBy(
    {
      useExisting,
      VehiclePrice,
      SaveWithProfile,
      AccountDetails,
      BrowserInfo,
      Vin,
      BillingPostalCode,
      market,
      language,
      ...cardInfo,
      ...session,
    },
    _isNil
  );

  return result;
}

export function addModifiedApplication({ state }) {
  const rn = _get(state, 'Configuration.rn', '');
  const canModifyOrder = _get(state, 'ApplicationFlow.canModifyOrder', false);
  let modifiedApplication = PUBLIC_ORDER;
  if (rn) {
    modifiedApplication = canModifyOrder ? EDIT_DESIGN_SAVE : RESERVATION_ORDER;
  }
  return modifiedApplication;
}

export function stateToOrderSchema({ state }) {
  const { App, Configuration, Pricing } = state;
  const { isEnterpriseOrder, countryCode = '' } = App;
  const { grossPrice = 0 } = Pricing;
  const { marketingLexiconDate } = Configuration;
  const pricingDate =
    isEnterpriseOrder && marketingLexiconDate ? marketingLexiconDate : new Date().toJSON();

  const sendGrossPrice = countryCode === 'CN';
  let totalPrice = _get(state, 'FinancingOptions.sendTotalPrice', true)
    ? state.Pricing.total
    : state.Pricing.subtotal;

  if (getVatRateForForwardCalculation(state) > 1) {
    totalPrice = state.Pricing.subtotal;
  }

  let mktOptionCodes = state.Configuration.option_codes.map(option => ({
    code: option,
    price: getPriceReturnedFromAgent(
      state.Pricing.calculatorResult.data.apiResults.price.matchedCodes,
      option
    ),
    isCustomPricing: false,
  }));

  if (_get(state, 'Payment.calculatePriceOnOrder', false) === true) {
    const pricingContext = getPricingContext(state, CONTEXT_DEFAULT);
    const unselectedOptions = getUiOptionsNotInConfiguration(state);
    let vehiclePrice = null;

    if (_get(state, 'ReviewDetails.product.isInventory')) {
      vehiclePrice = getInventoryBasePrice(state);
    }

    const calculatorResult = Price.total(
      {
        Lexicon: state.OMS.lexicon,
        Configuration: { options: state.Configuration.option_codes },
        UnselectedOptions: unselectedOptions,
      },
      {
        options: state.Configuration.option_codes,
        vehiclePrice,
        priceContext: pricingContext,
      }
    );

    totalPrice = calculatorResult.vehiclePrice;
    mktOptionCodes = state.Configuration.option_codes.map(option => ({
      code: option,
      price: getPriceReturnedFromAgent(calculatorResult.matchedCodes, option),
      isCustomPricing: false,
    }));
  }

  const initialEDD = _get(state, 'initialEDD', null);

  return {
    config: {
      model: _get(state, 'OMS.lexicon.product', '') || _get(state, 'OMS.oms_params.model', ''),
      countryCode: state.Payment.CountryCode,
      currencyCode: state.Payment.CurrencyCode,
      pricingDate,
      priceBookName: _get(state, 'OMS.lexicon.name', ''),
      languageCode: _get(state, 'OMS.lexicon.language', _get(state, 'App.language', '')),
      totalPrice,
      ...(sendGrossPrice ? { grossPrice } : {}),
      series: _get(state, 'ReviewDetails.VehicleDetail.series', ''),
      qty: _get(state, 'ReviewDetails.VehicleDetail.qty', ''),
      mktOptionCodes,
      trimCode: getTrimCode(state),
      sourceStoreInfo: {
        sourceDevice: getDeviceType(state),
      },
      feeDiscount: {
        comments: '',
        docFee: 0,
        recyclingFee: 0,
        taxCredit: 0,
        taxJson: null,
        vat: {
          amount: 0,
          percentage: 0,
        },
      },
      isConfigurationAccepted: true,
      locale: _get(state, 'App.locale', 'en_US'),
    },
    order: {
      edd: {
        eddStartDate: _get(state, 'DeliveryDate.startDate'),
        eddEndDate: _get(state, 'DeliveryDate.endDate'),
      },
    },
    comments: 'Configurator comment',
    impersonatedUserName: '',
    modifiedApplication: addModifiedApplication({ state }),
    ...(initialEDD !== null && {
      initialEDD: {
        deliveryWindowDisplay: _get(state, 'initialEDD.deliveryWindowDisplay', null),
        deliveryWindowStart: _get(state, 'initialEDD.deliveryWindowStart', null),
        deliveryWindowEnd: _get(state, 'initialEDD.deliveryWindowEnd', null),
      },
    }),
  };
}

export function getEnterpriseOrderSchema({ state }) {
  const {
    App: { isEnterpriseOrderPickup } = {},
    ReviewDetails: {
      DeliveryDetails: { PostalCode, StateProvince = '' } = {},
      RegistrationDetail: registrationDetail = {},
    } = {},
    SummaryPanel: { showEmirateSelection = false },
  } = state || {};
  const isInventory = _get(state, 'ReviewDetails.product.isInventory', false);
  let getStateSchema = stateToOrderSchema({ state });
  const accessories = getAccessoriesPayloadEnterprise(state);
  const multiFlexDetail = getMultiFlexDetails(state);
  const deliveryContact = getDeliveryContactDetails(state);
  const falconDeliverySelectionVersion = getFalconDeliverySectionVersion(state);
  const { trtId, addressId, trt_id: locationId } = getSelectedCompoundLocation(state) || {};
  if (_get(registrationDetail, 'RegistrantType')) {
    getStateSchema = {
      ...getStateSchema,
      registrationDetail,
    };
  }

  if (accessories) {
    getStateSchema = {
      ...getStateSchema,
      accessories,
    };
  }
  if (multiFlexDetail) {
    getStateSchema = {
      ...getStateSchema,
      multiFlexDetail,
    };
  }

  if (showEmirateSelection && StateProvince) {
    getStateSchema = {
      ...getStateSchema,
      deliveryStateProvince: StateProvince,
    };
  }

  if (isEnterpriseOrderPickup) {
    getStateSchema = {
      ...getStateSchema,
      deliveryContact,
      deliveryLocationSelectionDetails: {
        locationId,
        pickupType: PICKUP_SERVICE_CENTER,
        searchZipCode: PostalCode,
        version: falconDeliverySelectionVersion,
      },
    };
  }

  if (isInventory) {
    const { VIN: Vin } = _get(state, 'ReviewDetails.product.data');
    const { countryCode: market, language } = _get(state, 'App');
    const VehiclePrice = _get(state, 'ReviewDetails.product.data.PurchasePrice', 0);
    const optionCodeData = _get(state, 'ReviewDetails.product.data.OptionCodeData', []);
    return {
      ...getStateSchema,
      Vin,
      VehiclePrice,
      market,
      language,
      optionCodeData,
      trtId: locationId || trtId,
      addressId,
    };
  }
  return getStateSchema;
}

/**
 * Get Order Payment Details (amount, currency, disclaimer)
 * @param  {Object} state [redux state]
 * @param  {Object} props [props]
 * @return {Object}       [order payment]
 */
export function getOrderPayment(state, props) {
  const { OMS, Pricing } = state;
  const { CurrencyCode } = props || {};
  const { apiResults, extraPriceContexts } = Pricing?.calculatorResult?.data || {};
  const { orderFee } = apiResults?.price || {};
  if (!orderFee) {
    return;
  }
  const { price, source_subtype: subType, currency_code: currency, is_reservation: isReservation } = orderFee || {};
  const disclaimerType = isReservation ? `${PAYMENT_SOURCE_RESERVATION}_${subType}` : subType;
  const { label: disclaimer = '', title = '' } =
    OMS?.lexicon?.metadata?.copy?.disclaimers?.find(
      ({ selected_by = {} }) =>
      disclaimerType === selected_by?.sub_type || !Object.values(selected_by).length
    ) || {};
  const { orderFee: alternateCurrencyDetails } =
    extraPriceContexts?.[CurrencyCode]?.apiResults?.price || {};
  return {
    amount: price,
    formattedAmount: formatCurrency(price),
    currency,
    disclaimer,
    subType,
    isReservation,
    title,
    ...(alternateCurrencyDetails
      ? {
          alternateCurrency: {
            [CurrencyCode]: {
              amount: alternateCurrencyDetails?.price || 0,
            },
          },
        }
      : {}),
  };
}

/**
 * A flag to display transportation fee amount or not
 * @param  {Object} state [redux state]
 */
export function displayTransportationFeeAmount(state) {
  const { ReviewDetails, App } = state;
  const { isTransportFeeEnabled, isDeliveryDetailsValid } = ReviewDetails;
  const { isPickupOnlyEnabled } = App;
  const isDeliveryDetailsValidState = isPickupOnlyEnabled ? true : isDeliveryDetailsValid;

  return isTransportFeeEnabled && isDeliveryDetailsValidState;
}

/**
 * Get delivery postal code.
 * @param  {Object} state [redux state]
 */
export function getDeliveryPostalCode(state) {
  const { ReviewDetails } = state;
  const { DeliveryDetails = {} } = ReviewDetails;
  const { PostalCode = '' } = DeliveryDetails || {};
  return PostalCode || '';
}

export const isReservationToOrder = state => state?.isReservationToOrderFlow || false;

export const getFinanceSnapshot = (state) => {
  const { Pricing = {}, Forms = {} } = state;
  const { termLength, cashDownPayment, distanceAllowed } = Pricing.finplat?.output?.inputs ?? {};
  const financeId = getFinanceProductId(state);
  const userSelectedInputs = Forms?.finplatUserInputs?.[financeId] ?? {};

  const inputsObj = {
    financeId,
    ...userSelectedInputs,
    termLength,
    cashDownPayment,
    distanceAllowed,
  };

  const ignoreNonExistingValues = Object.entries(inputsObj).reduce((acc, [key, value]) => {
    if ([null, undefined].includes(value)) {
      return acc;
    }
    return { ...(acc ?? {}), [key]: value };
  }, null);

  return ignoreNonExistingValues;
}