import React from 'react';
import { connect } from 'react-redux';
import { string, number, bool, arrayOf } from 'prop-types';
import { formatCurrency } from '@tesla/coin-common-components';
import { i18n, isGalleryState, isPreProduction } from 'utils';
import { isVehicleOnSite, hasInStateDeliveryRestrictions, getIsFalconDeliverySelectionEnabled } from 'selectors';
import _get from 'lodash/get';
import DeliveryDate from './DeliveryDate';
import { FIELD_ZIP_CODE, PROVINCE } from 'dictionary'

/* InventoryDeliveryAddress component */
const InventoryDeliveryAddress = ({
  title,
  address_line_1: address1,
  address_line_2: address2,
  province,
  city,
  street2,
  postal_code: zipCode,
  transportFee: { fee, distance },
  isPickupOnlyEnabled,
  classes,
  trt_id,
  isOnSiteVehicle,
  hasInStateRestrictionsForDelivery,
  isTransportFeeEnabled,
  isTransportFeeCollectionEnabled,
  singleLocation,
  vehicleVrl,
  isEnterpriseOrder,
  showTransitStatus,
  rangeRestrictedSalesMetro,
  atLocation,
  isGalleryState,
  isPreProduction,
  IsAtLocation,
  InTransit,
  IsFactoryGated,
  isUsed,
  isRefurbComplete,
  isFalconDeliverySelectionEnabled,
  hideFalconDeliveryStatus,
  ignoreFieldsForDeliveryLocation,
  vehicleDataIsFactoryGated,
  CountryHasVehicleAtLocation,
}) => {
  if (!trt_id) {
    return null;
  }

  // use isAtLocation or isFactoryGated per TRT sent from prioritized falcon selection if present otherwise use values from state 
  let isPreProductionVehicle = typeof IsFactoryGated === 'boolean' ? !IsFactoryGated : isPreProduction;
  let isAtLocationVehicle = IsAtLocation ?? atLocation ?? !InTransit;

  if (ignoreFieldsForDeliveryLocation?.includes(FIELD_ZIP_CODE) && ignoreFieldsForDeliveryLocation?.includes(PROVINCE)) {
    // when no postal code lookup done get values from listing card
    isPreProductionVehicle = isPreProduction;
    isAtLocationVehicle = CountryHasVehicleAtLocation ?? isAtLocationVehicle;
  }

  return (
    <div className={classes}>
      {title && <p className="tds-text--medium">{title}</p>}
      {address1 && <p className="tds-text--regular">{address1}</p>}
      {address2 && <span className="tds-text--regular">{address2}&nbsp;</span>}
      {street2 && <p className="tds-text--regular">{street2}</p>}
      {city && zipCode && (
        <span className="tds-text--regular">
          {i18n(`DeliveryLocation.${province ? 'address_with_state' : 'address_without_state'}`, {
            CITY: city,
            STATE: province,
            COUNTRY: zipCode,
            SUBURB: city,
          })}
        </span>
      )}
      {/* TODO: Move conditions to Utils */}
        <Choose>
          <When condition={isOnSiteVehicle && (vehicleVrl === trt_id || isAtLocationVehicle)}>
            <If condition={!hasInStateRestrictionsForDelivery}>
              <p className="tds-text--regular tds-o-vertical_padding-top--3x">
                {i18n('DeliveryLocation.testdrive_disclaimer')}
              </p>
            </If>
          </When>
          <When condition={(rangeRestrictedSalesMetro || showTransitStatus || (isFalconDeliverySelectionEnabled && !hideFalconDeliveryStatus)) && !isTransportFeeCollectionEnabled}>
            <Choose>
              <When condition={isGalleryState}>
                <p className="tds-text--regular tds-o-vertical_padding-top--3x">
                  {i18n('common.availableForFasterPickup')}
                </p>
              </When>
              <When condition={isPreProductionVehicle || (isUsed && !isRefurbComplete)}>
                <p className="tds-text--regular tds-o-vertical_padding-top--3x">
                  {i18n('common.comingSoon')}
                </p>
              </When>
              <Otherwise>
                <p className="tds-text--regular tds-o-vertical_padding-top--3x">
                  {isAtLocationVehicle ? i18n('common.availablePickup') : i18n('common.intransitArriveSoon')}
                </p>
              </Otherwise>
            </Choose>
          </When>
          <When
            condition={
              isPickupOnlyEnabled && !hasInStateRestrictionsForDelivery && isTransportFeeEnabled
            }
          >
            <p className="tds-text--regular tds-o-vertical_padding-top--3x">
              {i18n(
                `DeliveryLocation.${fee ? 'pickup_only_fee_label' : 'pickup_only_nofee_label'}`,
                {
                  FEE: formatCurrency(fee || 0),
                }
              )}
            </p>
          </When>
          <When condition={singleLocation && !isTransportFeeEnabled}>
            <p className="tds-text--regular tds-o-vertical_padding-top--3x">
              {i18n('DeliveryLocation.pickup_only_label')}
            </p>
          </When>
          <Otherwise>
            <If condition={isTransportFeeEnabled}>
              <p className="tds-text--regular tds-o-vertical_padding-top--3x">
                {i18n(
                  `DeliveryLocation.${
                    fee ? 'estimate_transport_fee_label' : 'estimate_transport_nofee_label'
                  }`,
                  {
                    FEE: formatCurrency(fee || 0),
                  }
                )}
              </p>
            </If>
          </Otherwise>
        </Choose>
        <If condition={isUsed}>
          <DeliveryDate classes="tds-text--start tds-text--regular" distance={distance}/>
        </If>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { App, ReviewDetails } = state;
  const { isPickupOnlyEnabled, isEnterpriseOrder, isTransportFeeCollectionEnabled } = App;
  const { isTransportFeeEnabled, rangeRestrictedSalesMetro, showTransitStatus, product, ignoreFieldsForDeliveryLocation, hideFalconDeliveryStatus, isRefurbComplete } = ReviewDetails;
  const { isInventory, isUsedInventory, data: vehicleData } = product || {};
  const { province: locationProvince } = ownProps || {};

  const { IsFactoryGated: vehicleDataIsFactoryGated, CountryHasVehicleAtLocation, InTransit } = vehicleData || {};

  return {
    isPickupOnlyEnabled,
    isOnSiteVehicle: isVehicleOnSite(state),
    hasInStateRestrictionsForDelivery: hasInStateDeliveryRestrictions(state),
    isTransportFeeEnabled,
    isTransportFeeCollectionEnabled,
    isEnterpriseOrder,
    rangeRestrictedSalesMetro,
    isGalleryState: isGalleryState(state, locationProvince),
    isPreProduction: isPreProduction(state),
    isUsed: isUsedInventory,
    isRefurbComplete,
    showTransitStatus,
    isFalconDeliverySelectionEnabled: getIsFalconDeliverySelectionEnabled(state) && isInventory && !isUsedInventory,
    hideFalconDeliveryStatus,
    ignoreFieldsForDeliveryLocation,
    vehicleDataIsFactoryGated,
    CountryHasVehicleAtLocation,
    InTransit,
  };
};

InventoryDeliveryAddress.propTypes = {
  title: string,
  address_line_1: string,
  address_line_2: string,
  province: string,
  city: string,
  postal_code: string,
  transportFee: number,
  isPickupOnlyEnabled: bool,
  classes: string,
  trt_id: number.isRequired,
  isOnSiteVehicle: bool,
  hasInStateRestrictionsForDelivery: bool,
  isTransportFeeEnabled: bool,
  isTransportFeeCollectionEnabled: bool,
  singleLocation: bool,
  isEnterpriseOrder: bool,
  rangeRestrictedSalesMetro: bool,
  isGalleryState: bool.isRequired,
  isPreProduction: bool.isRequired,
  showTransitStatus: bool,
  isFalconDeliverySelectionEnabled: bool.isRequired,
  hideFalconDeliveryStatus: bool,
  ignoreFieldsForDeliveryLocation: arrayOf(string),
  vehicleDataIsFactoryGated: bool,
  CountryHasVehicleAtLocation: bool,
  isUsed: bool,
  isRefurbComplete: bool,
  InTransit: bool,
};

InventoryDeliveryAddress.defaultProps = {
  title: '',
  address_line_1: '',
  address_line_2: '',
  province: '',
  city: '',
  postal_code: '',
  transportFee: {},
  isPickupOnlyEnabled: false,
  classes: '',
  isOnSiteVehicle: false,
  hasInStateRestrictionsForDelivery: false,
  isTransportFeeEnabled: false,
  isTransportFeeCollectionEnabled: false,
  singleLocation: false,
  isEnterpriseOrder: false,
  rangeRestrictedSalesMetro: false,
  showTransitStatus: false,
  hideFalconDeliveryStatus: false,
  ignoreFieldsForDeliveryLocation: [],
  vehicleDataIsFactoryGated: false,
  CountryHasVehicleAtLocation: undefined,
  InTransit: undefined,
  isUsed: false,
  isRefurbComplete: false,
};

export default connect(mapStateToProps)(InventoryDeliveryAddress);
