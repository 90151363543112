import React from 'react';
import { string, bool, func } from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { formatCurrency, formatMonthlyPrice } from '@tesla/coin-common-components';

import { openModal, closeModal, selectTab } from 'actions';
import { NAVIGATION_VIEW_PAYMENT, FinanceTypes } from 'dictionary';
import { i18n, isPreOrder, isPriceAcceptance } from 'utils';
import {
  getPriceAfterSavings,
  getPrice,
  getFinanceType,
  getFinanceTabs,
  getSelectedFinanceProduct,
  showSavingsAmountInFooter,
  hideAfterSavingsPrice,
  getSavingsAmount,
  hideFinanceModal,
  getInterestRate,
  getFuelYears,
  getLocale,
  getDepositAmount,
  getFinanceProductId,
  getFuelSavingsPerMonth,
  getTradeInType,
  getDownPayment,
  getExtraPricingContextAmounts,
  getFinanceProductData,
  getFinanceProductToHide,
  getPricePostfix,
  getDeltasByProductId,
  showSavingsPriceForTrims,
} from 'selectors';

import FooterRepresentation from './representation';
import { navigationSelectKey } from '../../reducers';

const Footer = props => {
  if (isPreOrder() || isPriceAcceptance() || props?.shouldHideFooter) {
    return null;
  }

  return <FooterRepresentation {...props} />;
};

function mapStateToProps(state) {
  const financeType = getFinanceType(state);
  const financeTabs = getFinanceTabs(state);
  const financeProduct = getSelectedFinanceProduct(state);
  const { product, showPurchasePriceOnFooter = false, isDeliveryDetailsValid = false } =
    state?.ReviewDetails || {};
  const { condition, isInventory } = product || {};
  const financeTab = financeTabs.find(tab => {
    return tab.id === financeProduct;
  });

  const { section: navigationSection } = state.Navigation;

  const {
    isLayoutMobile,
    isLayoutTablet,
    isEnterpriseOrder,
    lexiconComponentContext: appContext,
    isPostOrderSwap,
    countryCode,
    enableCyberpunk,
    pricingContext: defaultCurrency,
    displayDoubleCurrency,
    isCoinReloaded,
    isTotalPriceInFooter,
    showPostOrderConfirmation,
    islightThemeEnabled,
  } = state.App;

  const {
    showFooterFinanceLink,
    showFuelMonthlySavings,
    coeBidAmount,
    reversePricingOrder = false,
    showFinanceProductName = false,
    showTotalPaymentFooter = false,
    showIncludesVATinFooter = false,
    showAsterixAtPriceEnd = false,
    showSavingAmtWithoutMinusInFooter = false,
    showAmountIncludingFeesInModal = false,
    showAfterSavingsPriceInFooter = false,
    showFooterCashWithMonthly
  } = state.FinancingOptions;

  const distinguishFinanceForFooterLabel = _get(
    state,
    'SummaryPanel.distinguishFinanceForFooterLabel',
    true
  );

  const beforeSavingsPrice = getPrice(state, financeType, {
    includeAccessories: isEnterpriseOrder,
  });

  const beforeSavingCashPrice = getPrice(state, FinanceTypes.CASH, {
    includeAccessories: isEnterpriseOrder,
  });

  const afterSavingsPrice = getPriceAfterSavings(state, financeType);
  const afterSavingsLabel = i18n('common.afterSavings');
  const placeHolder = distinguishFinanceForFooterLabel ? financeType : 'default';
  const purchasePriceLabel =
    i18n(`SummaryPanel.netPrice__${placeHolder}`, { INDICATOR: '' }, null, {
      specificOverride: [condition],
      returnNullWhenEmpty: true,
      specificOverrideOperator: 'OR',
    }) || financeTab?.label;

  const showSecondDepositDisclaimer = _get(
    state,
    'ReviewDetails.showSecondDepositDisclaimer.source',
    false
  );
  const loanApr = getInterestRate(state);
  const locale = getLocale(state);
  const fuelYears = getFuelYears(state);
  const depositAmount = formatCurrency(getDepositAmount(state));
  const downPayment = formatCurrency(
    getDownPayment(state, {
      context: 'footer',
    })
  );

  const { calculatorResult = {}, lease = {}, finplat = {}, finance = {} } = state.Pricing ?? {};

  const modelCode = _get(state, 'Configuration.model_code', '');
  const productData = getFinanceProductData(state);

  const effectiveRate =
    {
      [FinanceTypes.LEASE]: lease?.effectiveRate,
      [FinanceTypes.FINPLAT]: finplat?.output?.outputs?.effectiveInterestRate,
    }[financeType] || finance?.effectiveRate;

  const residualAmount = formatCurrency(
    {
      [FinanceTypes.LEASE]: lease?.residualAmount,
      [FinanceTypes.FINPLAT]: Math.ceil(finplat?.output?.outputs?.residualValue ?? 0),
    }[financeType] || 0,
    { precision: state.Forms?.residualValuePrecision }
  );

  const intlCurrency = _get(state, 'OMS.lexicon.metadata.pricing.context_mapping.configurator');
  const conversionRate = _get(
    state,
    `OMS.lexicon.metadata.copy.conversion_rate.${intlCurrency}.${defaultCurrency}`,
    0
  );
  const shouldHideFooter =
    showPostOrderConfirmation ||
    (countryCode === 'CN' && isPostOrderSwap) ||
    (isCoinReloaded && isInventory && navigationSection === NAVIGATION_VIEW_PAYMENT);

  const {
    showInterestRateInFooter = false,
    showEffectiveRateInFooter = false,
    showDownpaymentInFooter = false,
    showResidualValueInFooter = false,
  } = productData?.uiSettings?.forms ?? {};
  const productId = getFinanceProductId(state);

  let showFinanceFieldsInFooter = getDeltasByProductId(state.FinancingOptions?.showFinanceFieldsInFooter, productId);
  
  if (!showFinanceFieldsInFooter?.length) {
    showFinanceFieldsInFooter = Object.entries({
      interestRate: showInterestRateInFooter,
      effectiveRate: showEffectiveRateInFooter,
      downpayment: showDownpaymentInFooter,
      residualAmount: showResidualValueInFooter,
    }).reduce((acc, [key, value]) => (value ? [key, ...acc] : acc), []);
  }

  if (financeType === FinanceTypes.CASH) {
    showFinanceFieldsInFooter = [];
  }

  const { canModifyOrder = false } = state.ApplicationFlow;
  const { showPaymentOverview = false, serverError: paymentError } = state.Payment;

  return {
    modelCode,
    loanType: state.SummaryPanel?.loanType ?? null,
    locale,
    financeType,
    beforeSavingsPrice,
    afterSavingsPrice,
    afterSavingsLabel,
    purchasePriceLabel,
    isLayoutMobile,
    isLayoutTablet,
    reversePricingOrder,
    hideAfterSavingsPrice: hideAfterSavingsPrice(state, 'footer'),
    showSavingsAmount: showSavingsAmountInFooter(state),
    savingsAmount: getSavingsAmount(state, { showSavingAmtWithoutMinusInFooter }),
    savingsAmountLabel: i18n('common.savingsAmount', { NUM_FUEL_YEAR: fuelYears }),
    hidePaymentModalTrigger: hideFinanceModal(state),
    showSecondDepositDisclaimer,
    showAsterixAtPriceEnd,
    showOtherLoanTerms: _get(state, 'Forms.showOtherLoanTerms', false),
    loanApr,
    showBeforeSavingsPriceFinancingLabel: _get(
      state,
      'ReviewDetails.showBeforeSavingsPriceFinancingLabel',
      true
    ),
    isReservation: _get(state, 'ReviewDetails.product.isReservation', false),
    depositAmount,
    downPayment,
    residualAmount,
    showFooterFinanceLink,
    effectiveRate,
    showFinanceFieldsInFooter: showFinanceFieldsInFooter?.length ? showFinanceFieldsInFooter : null,
    interestRate: getInterestRate(state),
    showFinanceProductName,
    showTotalPaymentFooter,
    showIncludesVATinFooter,
    totalAmtPaid: calculatorResult?.data?.result?.loan?.regional?.totalAmtPaid ?? null,
    monthlyLeasePayment: lease?.monthlyPayment,
    financeProductId: productId,
    showFuelMonthlySavings,
    fuelSavingsPerMonth: getFuelSavingsPerMonth(state),
    isLoading: !calculatorResult?.data,
    tradeInType: getTradeInType(state),
    showVehiclePricePlusFees: state?.ReviewDetails?.showVehiclePricePlusFees || false,
    showAmountIncludingFeesInModal,
    extraPricingContextAmounts: getExtraPricingContextAmounts(state),
    displayDoubleCurrency,
    leaseMonthlyPaymentInIntlCurrency: formatMonthlyPrice(lease?.monthlyPayment / conversionRate, {
      currency: intlCurrency,
      useDynamicRounding: true,
    }),
    coeBidAmount,
    appContext,
    showPurchasePriceOnFooter,
    shouldHideFooter,
    productType: productId ? productId.split(':')[0] : '',
    hideFinanceProduct: getFinanceProductToHide(state),
    enableCyberpunk,
    countryCode,
    isCoinReloaded,
    showAfterSavingsPriceForTrims: showSavingsPriceForTrims(state),
    isInventory,
    isPostOrderSwap,
    isEnterpriseOrder,
    isEditDesign: canModifyOrder,
    showPaymentOverview,
    hasPriceTotal: state?.Pricing?.total || 0,
    showAfterSavingsPriceInFooter,
    isTotalPriceInFooter,
    pricePostfix: getPricePostfix(state),
    paymentError,
    beforeSavingCashPrice,
    showFooterCashWithMonthly,
    navigationSection,
    footerMonthlyPaymentPostFix: state?.FinancingOptions?.footerMonthlyPaymentPostFix ?? '',
    isDeliveryDetailsValid,
    islightThemeEnabled,
  };
}

Footer.propTypes = {
  isLayoutMobile: bool.isRequired,
  selectTab: func,
  showFooterFinanceLink: bool,
  showVehiclePricePlusFees: bool,
  shouldHideFooter: bool,
  pricePostfix: string,
  isTotalPriceInFooter: bool,
  isPostOrderSwap: bool,
};

Footer.defaultProps = {
  selectTab: () => {},
  showFooterFinanceLink: false,
  showVehiclePricePlusFees: false,
  pricePostfix: '',
  isTotalPriceInFooter: false,
  isPostOrderSwap: false,
};

function mapDispatchToProps(dispatch) {
  return {
    goToPayment: () => dispatch(navigationSelectKey({ key: NAVIGATION_VIEW_PAYMENT })),
    openModal: cfg => dispatch(openModal(cfg.componentName, cfg)),
    closeModal: () => dispatch(closeModal()),
    selectTab: tabId => dispatch(selectTab(tabId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
