import React, { memo } from 'react';
import { string, bool, shape } from 'prop-types';

import LexiconGroup from './LexiconGroup';
import CustomGroup from './CustomGroup';
import AssetLoader from '../AssetLoader';
import InventoryAssetLoader from './InventoryAssetLoader';
import EditDesignLink from '../../containers/PaymentOverview/Sections/EditDesignLink';
import PaymentOverviewTrigger from './PaymentOverviewTrigger';
import GroupDetails from './GroupDetails';
import PaymentSummary from '../PaymentSummary';
import PaymentOverview from '../../containers/PaymentOverview';
import LocationObserver from '../LocationObserver';
import OrderSummary from '../../containers/Enterprise/OrderSummary';
import OrderOverview from '../../containers/Enterprise/OrderOverview';
import OrderNavigation from '../../containers/Enterprise/OrderNavigation';
import MiniConfigurator from '../../containers/Enterprise/MiniConfigurator';

import InventorySummary from './InventorySummary';
import FeaturesList from './FeaturesList';
import CompareDesign from './CompareDesign';
import WarrantyBlock from './WarrantyBlock';
import AdditionalDetailsBlock from './AdditionalDetailsBlock';
import PostOrderSwapConfirmation from './PostOrderSwapConfirmation';
import InventoryFeatureHighlights from './InventoryFeatureHighlights';
import VehicleDetailsBlock from './VehicleDetailsBlock';
import AccessoriesGroup from './AccessoriesGroup';
import InventoryDeliverySelection from './InventoryDeliverySelection';
import InventoryConditionBlock from './InventoryConditionBlock';
import InventorySwap from '../InventorySwap';
import ConditionNotificationBlock from './ConditionNotificationBlock';
import ConfigAssetLoader from './ConfigAssetLoader';

const availableComponents = {
  LexiconGroup,
  CustomGroup,
  PaymentOverview,
  PaymentSummary,
  OrderSummary,
  OrderOverview,
  MiniConfigurator,
  AssetLoader,
  EditDesignLink,
  PaymentOverviewTrigger,
  OrderNavigation,
  InventorySummary,
  FeaturesList,
  CompareDesign,
  WarrantyBlock,
  VehicleDetailsBlock,
  AdditionalDetailsBlock,
  InventoryAssetLoader,
  InventoryFeatureHighlights,
  AccessoriesGroup,
  InventoryDeliverySelection,
  InventoryConditionBlock,
  InventorySwap,
  PostOrderSwapConfirmation,
  ConditionNotificationBlock,
  ConfigAssetLoader,
};

const GroupSection = memo(
  ({
    group,
    sectionDataStringified,
    isDesktop = false,
    isAsset = false,
    withAsset,
    isStarting = false,
  }) => {
    const parsedSectionData = sectionDataStringified ? JSON.parse(sectionDataStringified) : {};
    const { component, props: componentProps = {}, id } = parsedSectionData;
    const ComponentName = availableComponents[component] || null;
    const { asset } = componentProps;
    // TODO: figure out how to check if a group has an asset
    if ((!ComponentName && !group) || (isAsset && !group && !asset)) {
      return null;
    }
    return (
      <div
        className="group-section--container"
        key={`${isAsset ? 'asset' : 'widget'}_${group || id}`}
        data-group-id={group || id}
      >
        <If condition={withAsset && group}>
          {/* (Asset on Mobile) */}
          <LocationObserver type="Group" key={`LocationObserver:Group:${group}:${group?.code}`}>
            <GroupDetails
              group={group}
              detail_size="full-asset"
              key={`GroupDetails_OptionWidget_${group}_Mobile`}
            />
          </LocationObserver>
        </If>
        {/* Aside Section (Aside Content on Desktop / Main Content on Mobile) */}
        <ComponentName
          code={group}
          isDesktop={isDesktop}
          isMain={false}
          isStarting={isStarting}
          {...componentProps}
        />
      </div>
    );
  }
);

GroupSection.propTypes = {
  group: string,
  sectionDataStringified: string,
  isStarting: bool,
  isAsset: bool,
  isActiveElement: bool,
  isDesktop: bool,
  isActive: bool,
  withAsset: bool,
};

GroupSection.defaultProps = {
  group: null,
  isDesktop: false,
  isStarting: false,
  isAsset: false,
  isActiveElement: false,
  isActive: false,
  sectionDataStringified: '',
  withAsset: false,
};

export default GroupSection;
