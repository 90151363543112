import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import _upperFirst from 'lodash/upperFirst';
import { Loader, FormFieldset, Button, Link } from '@tesla/design-system-react';
import { Form, Input } from '@tesla/informed-tds';
import { utils } from 'informed';
import { i18n, zipCodeValidator, constructUrl, htmlToReact } from 'utils';
import {
  getPostalCode,
  getRegionName,
  getEstimateDeliveryDate,
  isEarlyDesignSelected,
  isNV36Trim,
  getModel,
} from 'selectors';
import { PAYMENT_SUMMARY, MODEL_FULL_NAME } from 'dictionary';
import {
  getEarlyAvailability,
  getGeoLocation,
  setDeliveryDetailsValidFlag,
  resetVehicleDesign,
} from 'actions';
import { bool, func, string } from 'prop-types';
import cx from 'classnames';
import Analytics from 'analytics';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import PreOrderSwap from '../PreOrderSwap';
import { isPuertoRicoZipCode } from 'utils';

const DeliveryZip = ({
  isAvailableNow,
  estimatedDeliveryDate,
  county,
  sibling,
  model,
  isEnabled,
  getAvailability,
  postalCode,
  stateCode,
  onPostalChange,
  market,
  regionName,
  options,
  isLoading,
  deliveryTiming,
  source = '',
  validateOnMount,
  error,
  enableCyberpunk,
  isModalOpen,
  showStaticRegion,
  resetToCustomDesign,
  addressString,
}) => {
  const [isVisible, ref] = useIntersectionObserver({
    threshold: 0,
  });
  const [isAnalyticsRendered, setIsAnalyticsRendered] = useState(false);
  const [showInput, setShowInput] = useState(!postalCode || !regionName);
  const formApiRef = useRef();
  const formState = formApiRef?.current?.getFormState();
  const { values, invalid, valid } = formState || {};
  const deliveryZip = values?.postalCode || '';
  const PUERTO_RICO_URL = constructUrl(`en_PR/${MODEL_FULL_NAME[model]}/design#overview`, sibling);
  const inputRef = useRef();

// When changing to non-native GPS, need to retrigger setShowInput
  useEffect(() => {
    const noZipFound = !postalCode || !regionName;
    if(noZipFound){ 
      setShowInput(noZipFound) }
  }, [postalCode, regionName]);

  useEffect(() => {
      const handleFocusOut = (e) => {
        if (
          inputRef?.current &&
          inputRef?.current?.contains(e.target) &&
          !error &&
          valid &&
          postalCode &&
          inputRef?.current?.value === postalCode
        ) {
          setShowInput(false);
        }
      };
      inputRef.current?.focus();
      document.addEventListener('focusout', handleFocusOut);
      return () => document.removeEventListener('focusout', handleFocusOut);
  }, [showInput]);

  useEffect(() => {
    if (isVisible && isEnabled) {
      if (postalCode && !error) {
        getAvailability();
      }
    }

    if (valid && deliveryZip && deliveryZip !== postalCode) {
      resetToCustomDesign();
      formApiRef?.current?.reset();
    }
  }, [isVisible, options, postalCode]);

  useEffect(() => {
    setIsAnalyticsRendered(true);
    if (!isLoading && isAnalyticsRendered && source !== PAYMENT_SUMMARY) {
      const AnalyticsObject = {
        event: Analytics.event,
        interaction: isAvailableNow ? 'available-today' : `available-${estimatedDeliveryDate}`,
        'cfg-type': Analytics.cfgType,
        'region': regionName,
        'postal_code': postalCode,
      }
      Analytics.fireTagEvent(AnalyticsObject);
    }
  }, [isLoading]);

  if (!isEnabled) {
    return null;
  }

  const { debounce } = utils;

  const onChangeZip = ({ value, valid }) => {
    if (valid && value) {
      onPostalChange(value);
      Analytics.fireInteractionEvent('update-delivery-location-payment');
      setTimeout(() => {
        setShowInput(false);
      }, 300);
    }
  };

  const validateInvalidZip = value => {
    return new Promise(resolve => {
      setTimeout(() => {
        if (error && source !== PAYMENT_SUMMARY && value === postalCode) {
          return resolve(i18n('TradeIn.error__postalCode'));
        }
        return resolve();
      }, 0);
    });
  };

  const PostalCode = ({ label, classes }) => {
    return (
      <Input
        inputRef={inputRef}
        name="postalCode"
        id="postalCode"
        maxLength={20}
        label={label}
        validate={value => zipCodeValidator(value, market)}
        asyncValidate={validateInvalidZip}
        required
        showErrorIfError
        validateOn="change"
        data-id="delivery-postal-code-textbox"
        placeholder={i18n('DeliveryLocation.enter_delivery_zip')}
        onChange={debounce(value => onChangeZip(value), 800)}
        keep={{ value: true, touched: true }}
        autocomplete="off"
        className={classes}
      />
    );
  };

  return (
    <div
      ref={ref}
      className={cx('observer-placeholder delivery-zip--container', {
        'tds-text--center': !source,
      })}
      id="early-delivery"
      key={`early-delivery:${source}`}
    >
      <Form
        formApiRef={formApiRef}
        allowEmptyStrings
        validateOnMount={validateOnMount}
        initialValues={{ postalCode }}
        errorMessage={{
          required: _upperFirst(i18n('common.errors__required')),
        }}
        className="tds-o-horizontail-margin--4x"
        keepState
      >
        <Choose>
          <When condition={source === PAYMENT_SUMMARY}>
            <FormFieldset className="tds--vertical_padding--large">
              <PostalCode
                label={i18n('DeliveryLocation.delivery_zip')}
                classes="tds-o-font-size--16"
              />
            </FormFieldset>
          </When>
          <Otherwise>
            <Choose>
              <When condition={showInput || error}>
                <p className="tds-o-padding_bottom-4 tds-text--h3 tds-text--contrast-high tds-text--medium">
                  {i18n('DeliveryLocation.enter_delivery_zip')}
                </p>
                <div
                  className={cx(
                    'tds-o-flex--no-wrap region-selector--container tds-o-padding_bottom-4',
                    { 'tds-o-flex--inline': !enableCyberpunk }
                  )}
                >
                  <div className="tds-flex-item">
                    <PostalCode />
                  </div>
                </div>
              </When>
              <Otherwise>
                <Loader show={isLoading && !isModalOpen} />
                <If condition={deliveryTiming && (!isLoading || isModalOpen)}>
                  <div className="tds-text--h3 tds-text--contrast-high tds-text--medium">{deliveryTiming}</div>
                </If>
                <div className="tds-o-padding_bottom-4">
                  {i18n('DeliveryLocation.delivery_zip')}
                </div>
                <Choose>
                  <When condition={showStaticRegion}>
                    <span className="tds-text--contrast-low">
                      {addressString}
                    </span>
                  </When>
                  <Otherwise>
                      <button onClick={() => {
                        Analytics.fireInteractionEvent('change-delivery-location-payment');
                        setShowInput(true);
                      }}>
                      <span className="tds-text--contrast-low tds-link">
                        {addressString}
                      </span>
                      </button>
                    <If condition={stateCode == 'HI' && county !== 'Honolulu' }>
                      <p className="tds-text--caption tds-text--center tds-o-padding_top-4">
                        {i18n('DeliveryLocation.hawaii_disclaimer')}
                      </p>
                    </If>
                    <If condition={market == 'US' && isPuertoRicoZipCode(postalCode)}>
                      <p className="tds-text--caption tds-text--center tds-o-padding_top-4">
                        {htmlToReact(
                          i18n('Review.puertoRico_registration_disclaimer', {
                            LINK: PUERTO_RICO_URL,
                          })
                        )}
                      </p>
                    </If>
                  </Otherwise>
                </Choose>
                <PreOrderSwap />
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </Form>
    </div>
  );
};

function mapStateToProps(state, { source = '' }) {
  const {
    App,
    OMS,
    DeliveryTiming: { isAvailableNow, isLoading = false } = {},
    Configuration: { option_string } = {},
    ReviewDetails: {
      isDeliveryDetailsValid,
      DeliveryDetails: { error, city, stateCode, county } = {},
    } = {},
    Modal: { open: isModalOpen } = {},
    CustomGroups: { BATTERY_AND_DRIVE = {} } = {},
  } = state;
  const { market } = OMS?.oms_params || {};
  const zipCode = getPostalCode(state);
  const model = getModel(state);
  const {
    enableCyberpunk,
    isEarlyAvailabilityEnabledWithLimits = false,
    isEarlyAvailabilityEnabled = false,
    sibling,
  } = App || {};
  const estimatedDeliveryDate = getEstimateDeliveryDate(state)?.delivery_date_by_region;
  const deliveryTiming = isAvailableNow
    ? i18n('common.available_today')
    : i18n('common.available_later_date', {
        DATE: estimatedDeliveryDate || '',
      });
  const modelName = BATTERY_AND_DRIVE?.name_new || BATTERY_AND_DRIVE?.name || '';
  const postalCode = source !== PAYMENT_SUMMARY && !isDeliveryDetailsValid && !error ? '' : zipCode;
  let addressString = [];
  if (city) {
    addressString.push(city);
  }
  if (stateCode && city !== stateCode) {
    addressString.push(stateCode);
  }
  if (postalCode) {
    addressString.push(postalCode);
  }
  return {
    isAvailableNow,
    estimatedDeliveryDate,
    county,
    model,
    sibling,
    isEnabled: App?.isEarlyAvailabilityEnabled,
    postalCode,
    market,
    regionName: getRegionName(state),
    stateCode,
    options: option_string,
    isLoading,
    deliveryTiming: isEarlyAvailabilityEnabledWithLimits || (isNV36Trim(state) && !isEarlyAvailabilityEnabled) ? i18n('Review.model_label', { MODEL: modelName }) : deliveryTiming,
    isDeliveryDetailsValid,
    validateOnMount: !!(zipCode && !isDeliveryDetailsValid) || !!error,
    error,
    enableCyberpunk,
    isModalOpen,
    showStaticRegion: isEarlyDesignSelected(state),
    addressString: addressString.length > 2 ? addressString.join(', ') : addressString.join(' '),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAvailability: () => dispatch(getEarlyAvailability()),
    onPostalChange: zipCode => dispatch(getGeoLocation(zipCode, { setPreferredAddress: true })),
    setZipValidFlag: flag => dispatch(setDeliveryDetailsValidFlag(flag)),
    resetToCustomDesign: () => dispatch(resetVehicleDesign()),
  };
}

DeliveryZip.propTypes = {
  isEnabled: bool,
  postalCode: string,
  stateCode: string,
  market: string,
  regionName: string,
  validateOnMount: bool.isRequired,
  enableCyberpunk: bool,
  showStaticRegion: bool,
  resetToCustomDesign: func.isRequired,
  isAvailableNow: bool.isRequired,
  estimatedDeliveryDate: string,
  addressString: string,
};

DeliveryZip.defaultProps = {
  isEnabled: false,
  postalCode: '',
  stateCode: '',
  market: '',
  regionName: '',
  enableCyberpunk: false,
  showStaticRegion: false,
  estimatedDeliveryDate: '',
  addressString: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryZip);
