import React from 'react';
import { connect } from 'react-redux';
import { bool, number, oneOfType, string } from 'prop-types';
import _get from 'lodash/get';
import { formatCurrency } from '@tesla/coin-common-components';
import { i18n, htmlToReact, getOrderDisclaimerSource } from 'utils';
import { NON_REFUNDABLE_ORDER_PAYMENT_TYPE, BRAND_NEW } from 'dictionary';
import {
  isOrderProcessingFeeEnabled,
  getDepositAmount,
  getTransportationFeeAmount,
  isReservationOrder,
  isAccessoriesSelected,
  getAccessoriesTotal,
} from 'selectors';

const OrderRefundDisclaimer = ({
  showOrderFeeDisclaimer,
  orderRefundWithTransportFee,
  orderRefund,
  showOrderRefundDisclaimer,
  showNonRefundableDisclaimer,
  showExtraCurrencyInOrderRefundDisclaimer,
  showNonRefundPolicy,
  showDeliveryDueDisclaimer,
  deliveryDueDays,
  isPreSale,
  isUsedInventory,
  isDemo,
  isPaymentShortExpireTimeEnabled,
  showNonTransferrable,
  showInventoryOrderFirstPaymentPolicy,
  hideRefund,
  extraPricingContextAmount,
  hasAccessories,
  accessoriesTotal,
  conversionRate,
  displayDoubleCurrency,
  intlCurrency,
  showSubsidyPolicyDisclaimer,
}) => {
  if (!showOrderRefundDisclaimer || hideRefund) {
    return false;
  }

  let nonRefundDisclaimer = i18n('SummaryPanel.disclaimers.orderNotRefundShortDisclaimer');
  const nonRefundDisclaimerEditDesign = i18n(
    'SummaryPanel.disclaimers.orderNotRefundShortDisclaimerEditDesign.content',
    null,
    null,
    { returnNullWhenEmpty: true }
  );

  const deliveryDueDisclaimer = showDeliveryDueDisclaimer
    ? i18n(
        'SummaryPanel.disclaimers.deliveryDueDisclaimer',
        { DELIVERY_DUE_DAYS: deliveryDueDays },
        null,
        { returnNullWhenEmpty: true }
      )
    : '';

  if (isReservationOrder() && nonRefundDisclaimerEditDesign != null) {
    nonRefundDisclaimer = nonRefundDisclaimerEditDesign;
  }

  const selfPaidFeeDisclaimer = (isUsedInventory || isDemo) ? i18n(
    'SummaryPanel.disclaimers.selfPaidFeeDisclaimer',
    null,
    null,
    { returnNullWhenEmpty: true }
  ) : null;

  return (
    <p className="tds-text--caption tds-o--maxinline--none">
      <Choose>
        <When condition={showOrderFeeDisclaimer}>
          <span>{showOrderFeeDisclaimer}</span>
        </When>
        <When condition={orderRefundWithTransportFee}>
          <span>{htmlToReact(orderRefundWithTransportFee)}</span>
        </When>
        <Otherwise>
          <span>{htmlToReact(orderRefund)}</span>
        </Otherwise>
      </Choose>
      <If condition={showExtraCurrencyInOrderRefundDisclaimer && extraPricingContextAmount}>
        <span>&nbsp;{extraPricingContextAmount}</span>
      </If>
      <If condition={isUsedInventory && showNonTransferrable}>
        <span className="tds--horizontal_margin-5">
          {i18n('SummaryPanel.disclaimers.nonTransferrable')}
        </span>
      </If>
      <If condition={selfPaidFeeDisclaimer}>
      <br />
      <br />
      <span>{selfPaidFeeDisclaimer}</span>
      </If>
      <If condition={hasAccessories}>
        <br />
        <span>
          {i18n('Accessories.includes_accessories_amount', {
            AMOUNT: formatCurrency(accessoriesTotal),
          })}
        </span>
      </If>
      <If condition={showNonRefundPolicy && showNonRefundableDisclaimer && !isPreSale}>
        <br />
        <br />
        <If condition={displayDoubleCurrency}>
          {i18n('SummaryPanel.disclaimers.exchangeRateDisclaimer', {
            CONVERSION_RATE: formatCurrency(1 / conversionRate, {
              currency: intlCurrency,
              precision: 5,
            }),
          })}
          <br />
          <br />
        </If>
        <span>{nonRefundDisclaimer}</span>
      </If>
      <If condition={showInventoryOrderFirstPaymentPolicy}>
        <span>
          <br />
          <br />
          {i18n('SummaryPanel.disclaimers.inventoryorderFirstPayment', {
            TIME_IN_MINUTES: !isUsedInventory && isPaymentShortExpireTimeEnabled ? 15 : 30,
          })}
        </span>
      </If>
      <If condition={showDeliveryDueDisclaimer}>
        <span>
          <br />
          <br />
          {htmlToReact(deliveryDueDisclaimer)}
        </span>
      </If>
      <If condition={showSubsidyPolicyDisclaimer}>
        <span>
          <br />
          <br />
          {i18n('SummaryPanel.disclaimers.subsidyPolicyDisclaimer')}
        </span>
      </If>
    </p>
  );
};

OrderRefundDisclaimer.propTypes = {
  showOrderFeeDisclaimer: oneOfType([string, bool]).isRequired,
  orderRefundWithTransportFee: string,
  orderRefund: string,
  showOrderRefundDisclaimer: bool,
  showNonRefundableDisclaimer: bool,
  showNonRefundPolicy: bool,
  showDeliveryDueDisclaimer: bool,
  deliveryDueDays: number,
  isPreSale: bool,
  isUsedInventory: bool,
  isDemo: bool,
  isPaymentShortExpireTimeEnabled: bool,
  showNonTransferrable: bool,
  showInventoryOrderFirstPaymentPolicy: bool,
  hideRefund: bool,
  extraPricingContextAmount: oneOfType([string, number]),
  hasAccessories: bool,
  accessoriesTotal: number,
  showExtraCurrencyInOrderRefundDisclaimer: bool,
  conversionRate: number,
  displayDoubleCurrency: bool,
  intlCurrency: string,
  showSubsidyPolicyDisclaimer: bool,
};

OrderRefundDisclaimer.defaultProps = {
  orderRefundWithTransportFee: '',
  orderRefund: '',
  showOrderRefundDisclaimer: false,
  showNonRefundableDisclaimer: false,
  showNonRefundPolicy: false,
  showDeliveryDueDisclaimer: false,
  isPreSale: false,
  isUsedInventory: false,
  isDemo: false,
  isPaymentShortExpireTimeEnabled: false,
  showNonTransferrable: false,
  showInventoryOrderFirstPaymentPolicy: false,
  hideRefund: false,
  extraPricingContextAmount: '',
  hasAccessories: false,
  accessoriesTotal: 0,
  conversionRate: 0,
  displayDoubleCurrency: false,
  intlCurrency: '',
  showSubsidyPolicyDisclaimer: false,
};

function mapStateToProps(state) {
  const { App, ReviewDetails, OMS, Payment } = state;
  const {
    isTransportFeeCollectionEnabled = false,
    enableCyberpunk = false,
    isPaymentShortExpireTimeEnabled = false,
  } = App;
  const {
    DeliveryDetails = {},
    nonRefundPolicy = {},
    nonRefundPolicyDisclaimer,
    showOrderRefundDisclaimer,
    showExtraCurrencyInOrderRefundDisclaimer,
    isPreSale,
    displayOrderRefundLongCopy,
    showDepositDisclaimer,
    product,
    showNonTransferrable,
    showInventoryOrderFirstPaymentPolicy,
  } = ReviewDetails;
  const { disclaimer: orderDisclaimer } = Payment?.orderPayment || {};
  const lexiconOrderFeeDislaimer = enableCyberpunk ? _get(
    state,
    'CustomGroups.TRIM.order_disclaimer',
    _get(state, 'CustomGroups.TRIM.currentSelected[0].order_disclaimer')
  ) : null;
  const { isUsedInventory = false, isInventory = false, data = {} } = product || {};
  const { source: showNonRefundPolicy = false } = nonRefundPolicy || {};
  const { oms_params = {} } = OMS;
  const { model: modelCode, market } = oms_params;
  const { PostalCode: deliveryPostalCode } = DeliveryDetails;
  const transportationFee = getTransportationFeeAmount(state);
  const depositAmountWithType = getDepositAmount(state, true);
  const depositAmountBase = depositAmountWithType?.orderPayment || 0;
  const depositAmountType = depositAmountWithType?.paymentSourceSubType || null;
  const orderRefundWithTransportFee =
    isTransportFeeCollectionEnabled && transportationFee
      ? showDepositDisclaimer && depositAmountType === NON_REFUNDABLE_ORDER_PAYMENT_TYPE
        ? i18n('SummaryPanel.disclaimers.orderNotRefundDepositWithTransportFee', {
            FEE: formatCurrency(transportationFee),
            ORDER_DEPOSIT: formatCurrency(depositAmountBase),
            POSTAL_CODE: deliveryPostalCode,
          })
        : i18n('SummaryPanel.disclaimers.orderNotRefundShortWithTransportFee', {
            FEE: formatCurrency(transportationFee),
            ORDER_DEPOSIT: formatCurrency(depositAmountBase),
            POSTAL_CODE: deliveryPostalCode,
          })
      : '';
  let orderRefundDisclaimerSrc = displayOrderRefundLongCopy?.source
    ? 'orderRefundLong'
    : 'orderRefundShort';
  orderRefundDisclaimerSrc =
    showDepositDisclaimer && depositAmountType === NON_REFUNDABLE_ORDER_PAYMENT_TYPE
      ? 'orderNotRefundDeposit'
      : orderRefundDisclaimerSrc;
  const orderRefund =
    orderDisclaimer || lexiconOrderFeeDislaimer ||
    getOrderDisclaimerSource(orderRefundDisclaimerSrc, modelCode, {
      showNonRefundPolicy,
    });
  const intlCurrency = _get(OMS, 'lexicon.metadata.pricing.context_mapping.configurator');
  const defaultCurrency = _get(state, 'App.pricingContext');
  const showDeliveryDueDisclaimer =
    market === 'CN' && !(isUsedInventory || modelCode === 'mt')
      ? depositAmountType === NON_REFUNDABLE_ORDER_PAYMENT_TYPE
      : false;
  const deliveryDueDays = _get(state, 'ReviewDetails.deliveryDueDays');
  const titleSubStatus = _get(state, 'ReviewDetails.product.data.TitleSubStatus', '');
  const currentSelectedTrim = _get(state, 'CustomGroups.TRIM.currentSelected[0].code', '');
  const showSubsidyPolicyDisclaimer = _get(state, 'ReviewDetails.showSubsidyPolicyDisclaimer', false)
    && (titleSubStatus === BRAND_NEW || !isInventory && currentSelectedTrim !== '$MTY21');
  return {
    showOrderFeeDisclaimer: isOrderProcessingFeeEnabled(state),
    showNonRefundableDisclaimer: nonRefundPolicyDisclaimer,
    showOrderRefundDisclaimer,
    showExtraCurrencyInOrderRefundDisclaimer,
    orderRefundWithTransportFee,
    orderRefund,
    showNonRefundPolicy,
    isPreSale: isPreSale?.source || false,
    isUsedInventory,
    isDemo: data?.IsDemo ?? false,
    showNonTransferrable,
    isPaymentShortExpireTimeEnabled,
    showInventoryOrderFirstPaymentPolicy,
    showDeliveryDueDisclaimer,
    deliveryDueDays,
    hasAccessories: isAccessoriesSelected(state),
    accessoriesTotal: getAccessoriesTotal(state),
    conversionRate: _get(
      OMS,
      `lexicon.metadata.copy.conversion_rate.${intlCurrency}.${defaultCurrency}`,
      0
    ),
    intlCurrency: _get(state, 'OMS.lexicon.metadata.pricing.context_mapping.configurator'),
    displayDoubleCurrency: _get(state, 'App.displayDoubleCurrency'),
    showSubsidyPolicyDisclaimer,
  };
}

export default connect(mapStateToProps)(OrderRefundDisclaimer);
