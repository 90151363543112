import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import TextLoader from '../TextLoader';

const InfoHeader = ({
  badgeImg = '',
  badgeImgLabel = '',
  title,
  secondaryTitle,
  pricing,
  secondaryBadge,
}) => {
  return (
    <div className="coin-vehicle-card--header">
      <If condition={badgeImg}>
        <div>
          <img
            className="coin-vehicle-card--badge-img"
            iria-label={badgeImgLabel}
            src={badgeImg}
          />
        </div>
      </If>
      <div className="coin-vehicle-card--details tds-layout tds-o-layout--grid tds-layout-2col tds-layout--no_offset">
        <div className="tds-layout-item tds-text--contrast-high tds-text--medium">
          <If condition={title}>
            <TextLoader data={title} />
          </If>
        </div>
        <div className="tds-layout-item tds-text--contrast-high tds-text--medium tds-o-text-align--right">
          {pricing}
        </div>
      </div>
      <If condition={secondaryTitle}>
        <div className="coin-vehicle-card--subdetails tds-layout tds-o-layout--grid tds-layout--no_offset">
          <div className="tds-layout-item tds-text--contrast-low">
            <TextLoader data={secondaryTitle} />
          </div>
        </div>
      </If>
      <If condition={secondaryBadge?.length}>
        {secondaryBadge?.map(badge => (
          <div className="coin-vehicle-card--subdetails tds-layout tds-o-layout--grid tds-layout--no_offset" key={badge?.key}>
            <div className="tds-layout-item tds-text--contrast-low">
              <TextLoader data={badge?.data} />
            </div>
          </div>
        ))}
      </If>
    </div>
  );
};

InfoHeader.propTypes = {
  title: string,
  secondaryTitle: string,
  pricing: string,
  secondaryBadge: arrayOf(shape({
    key: string,
    data: string,
  })),
};

InfoHeader.defaultProps = {
  title: '',
  secondaryTitle: '',
  pricing: '',
  secondaryBadge: '',
};

export default InfoHeader;
