import React from 'react';
import { connect } from 'react-redux';
import _has from 'lodash/has';
import _get from 'lodash/get';
import { string, shape, bool } from 'prop-types';
import { parseSelectedBy, parseConditionField } from 'utils';
import TextLoader from '../TextLoader';

/* Copy component */
const Copy = ({
  group,
  field,
  filteredField,
  component,
  classes,
  isEnabledForEditDesign,
  inheritClasses,
  isSelected,
  isCN,
}) => {
  const targetField = isCN ? filteredField : field;
  if (!isSelected || !group || !_has(group, targetField)) {
    return null;
  }
  const rn = _get(window.tesla, 'rn', null);
  const isEditDesign = !!rn;

  if (isEditDesign && !isEnabledForEditDesign) {
    return null;
  }

  return (
    <TextLoader
      data={group}
      field={targetField}
      key={`Copy:${group}:${targetField}`}
      tag={{ component }}
      inheritClasses={inheritClasses}
      className={`tds-text_color--30${classes ? ` ${classes}` : ''}`}
    />
  );
};

function mapStateToProps(state, ownProps) {
  const { group, field } = ownProps;
  const {
    App: { countryCode = '' },
  } = state;
  const filteredField = parseConditionField(state, group, field, { fieldReturn: true });
  return {
    isSelected: ownProps?.selected_by ? parseSelectedBy(
      _get(ownProps, 'selected_by', {}),
      state?.Configuration.option_string
    ) : true,
    filteredField,
    isCN: countryCode === 'CN',
  };
}

Copy.propTypes = {
  group: shape({
    selected: bool,
    code: string,
    name: string,
    formattedPrice: string,
  }).isRequired,
  field: string.isRequired,
  component: string,
  classes: string,
  isEnabledForEditDesign: bool,
  inheritClasses: bool,
  isSelected: bool,
  isCN: bool,
  filteredField: string,
};

Copy.defaultProps = {
  component: 'div',
  classes: '',
  isEnabledForEditDesign: true,
  inheritClasses: true,
  isSelected: true,
  isCN: false,
  filteredField: false,
};

export default connect(mapStateToProps)(Copy);
