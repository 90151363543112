import React from 'react';
import classnames from 'classnames';
import { FormInputCheckbox } from '@tesla/design-system-react';
import { htmlToReact } from '@tesla/coin-common-components';

const FinanceCheckboxes = ({
  checkboxes: items = [],
  goToView, triggerAnalytics,
  timeframe = 'yearly',
  dispatch,
  i18nStrings = {},
  totalPriceInclSavingsAndIncentives,
}) => {
  const showTotalPriceLine = (
    items?.some(item => item.checked) &&
    totalPriceInclSavingsAndIncentives &&
    i18nStrings?.common?.totalPriceInclSavingsAndIncentives
  );

  return items?.length ? (
    <>
      <div className="financial-modal-grid financial-modal-checkboxes">
        {items.map(({ key, label, disabled = false, description, action, data = {}, checked, errorMessage = null, caption, descriptionAdditional, isDefaultChecked = false, isHidden = false, hasLineBreak = false, text }) => {
          const onActionDispatch = () => action && dispatch(action);
          const onClick = () => {
            goToView(key);
            triggerAnalytics(key);
          };
          const onChange = (e) => {
            onActionDispatch();
            triggerAnalytics(key, e?.target?.checked);
          };
          return (
            <div key={key} className={classnames({'coin-financial-block--linebreak': hasLineBreak})}>
              <FormInputCheckbox
                name={key}
                checked={checked}
                disabled={disabled && !checked}
                label={
                  <>
                    <div className='financial-modal-checkboxes--label'>
                      <label>{label}</label>
                      <span className='financial-modal-checkboxes--timeframe'>{data[timeframe] || ''}</span>
                    </div>
                    {descriptionAdditional && (
                      <span
                        className={classnames('tds-text--regular tds-text--caption tds-o-padding_right-8', {'tds-display--block': caption})}
                      >
                        {descriptionAdditional}
                      </span>
                    )}
                    {caption && (
                      <span className='tds-text--caption tds-o-padding_right-4 tds-text--regular'>{caption}</span>
                    )}
                    {description && (
                      <span
                        className="tds-link tds-text--caption financial-modal-checkboxes--caption"
                        onClick={onClick}
                      >
                        {description}
                      </span>
                    )}
                    {errorMessage && (
                      <span className="tds-text--caption tds-inline-status_msg--error">
                        {errorMessage}
                      </span>
                    )}
                    {text && <span className="finance-checkbox--text tds-text--contrast-low">{text}</span>}
                  </>
                }
                onChange={disabled ? null : onChange}
                className={classnames('coin-finance-checkbox', {'tds-o-hover-disabled': isDefaultChecked }, {'tds-o-checkbox-hidden': isHidden })}
              />
            </div>
          )
        })}
      </div>
      {showTotalPriceLine && (
        <div className='financial-modal-checkboxes--label financial-modal-checkboxes--total'>
          <label>{i18nStrings?.common?.totalPriceInclSavingsAndIncentives}</label>
          {htmlToReact(totalPriceInclSavingsAndIncentives ?? '')}
        </div>
      )}
    </>
  ) : null
};

export default FinanceCheckboxes;