import React from 'react';
import { connect } from 'react-redux';
import { shape, string, number, bool, arrayOf, oneOfType, node, func }  from 'prop-types';
import { formatCurrency } from '@tesla/coin-common-components';
import {
  htmlToReact,
  i18n,
  constructUrl,
  getApplicableExtraIncentives
} from 'utils';
import _get from 'lodash/get';
import { INCENTIVE_UMWELT } from 'dictionary';
import { getFinanceProductId } from 'selectors';

const ExtraIncentives = ({
  extraIncentives,
  registrationType,
  configuration,
  incentiveTotalInPurchasePrice,
  governmentIncentivesTotal,
  showZeroRegistrationTax,
  registrationTax,
  sellerDiscount,
  stateContribution,
  financeProductId,
  OMVICFee,
  ecoBonus,
  governmentGrant,
  specialTax,
  regionalTax,
  winterTireFee,
  tireFee,
  recyclingFee,
  weightTax,
  sctTax,
  luxuryTax,
  sibling,
  locale,
  keyPrefix,
  wrapper,
  modelCode,
  driverType,
  vehicleExciseDuty,
}) => {
  const Wrapper = wrapper;
  return getApplicableExtraIncentives({
    incentives: extraIncentives,
    options: configuration,
    registrationType,
    governmentIncentivesTotal,
    sellerDiscount,
    registrationTax,
    showZeroRegistrationTax,
    winterTireFee,
    weightTax,
    governmentGrant,
    financeProductId,
  }).map(incentive => (
    <Wrapper key={`${keyPrefix}:${incentive}`}>
      {htmlToReact(
        i18n(
          `Incentives.extraIncentives.${incentive}`,
          {
            LINK_TARGET: '_blank',
            REGISTRATION_TAX: formatCurrency(registrationTax),
            REGIONAL_TAX: formatCurrency(regionalTax, null, { precision: 2 }),
            SPECIAL_TAX: formatCurrency(specialTax),
            LINK_SUPPORT_INCENTIVES: constructUrl(
              'support/incentives?redirect=no',
              sibling,
              locale
            ),
            GOVERNMENT_GRANT: formatCurrency(governmentGrant),
            INCENTIVES_INCLUDED_TOTAL: formatCurrency(
              Math.abs(
                incentive === INCENTIVE_UMWELT
                  ? governmentIncentivesTotal
                  : incentiveTotalInPurchasePrice
              )
            ),
            TOTAL_GOVERNMENT_INCENTIVES: formatCurrency(Math.abs(governmentIncentivesTotal)),
            ECO_BONUS: formatCurrency(ecoBonus),
            OMVIC_FEE: formatCurrency(OMVICFee),
            RECYCLING_FEE: recyclingFee,
            TIRE_FEE: tireFee,
            WINTER_TIRE_FEE: winterTireFee,
            WEIGHT_TAX: formatCurrency(weightTax, { useDynamicRounding: true }),
            SCT_TAX: formatCurrency(sctTax),
            STATE_CONTRIB: formatCurrency(Math.abs(stateContribution)),
            SELLER_DISCOUNT: formatCurrency(Math.abs(sellerDiscount)),
            LUXURY_TAX: formatCurrency(luxuryTax),
            VEHICLE_EXCISE_DUTY: formatCurrency(vehicleExciseDuty),
          },
          null,
          {
            specificOverride: [modelCode, driverType],
            specificOverrideOperator: 'OR',
            returnNullWhenEmpty: true,
          }
        )
      )}
    </Wrapper>
  ));
};

function mapStateToProps(state, ownProps) {
  const { hideZeroRegistrationTax = null } = ownProps;
  const { Pricing, Configuration, App, ReviewDetails, SummaryPanel, Financial } = state;
  const { incentives = {} } = Financial;
  const { current: currentIncentives } = incentives || {};
  const { formattedPrices = {} } = SummaryPanel;
  const { locale, sibling } = App;
  const { calculatorResult = {} } = Pricing;
  const {
    AccountDetail = {},
    showZeroRegistrationTax: showZeroRegistrationTaxState = false,
  } = ReviewDetails;
  const { AccountType = null } = AccountDetail || {};
  const { option_codes } = Configuration;
  const { winterTireFee = 0, tireFee = 0, recyclingFee = 0 } = formattedPrices || {};

  const governmentIncentivesTotal = _get(
    calculatorResult,
    'data.apiResults.incentives.current.government.total',
    0
  );
  const regionalTax = _get(calculatorResult, 'data.variables.fees.regional.total', 0);
  const specialTax = _get(calculatorResult, 'data.variables.fees.special_tax.total', 0);
  const weightTax = _get(calculatorResult, 'data.variables.fees.weight_tax.total', 0);
  const sctTax =
    _get(calculatorResult, 'data.variables.fees.sct_tax.total', 0);
  const luxuryTax = _get(calculatorResult, 'data.variables.fees.luxury_tax.total', 0);
  const governmentGrant = _get(
    calculatorResult,
    'data.variables.incentives.government_grant.total',
    0
  );
  const ecoBonus = Math.abs(_get(currentIncentives, 'government[0].amount', 0));
  const OMVICFee = _get(calculatorResult, 'data.variables.fees.OMVIC_fee.total', 0);
  const stateContribution = _get(currentIncentives, 'stateContribution[0].amount', 0);
  const sellerDiscount = _get(currentIncentives, 'sellerDiscount[0].amount', 0);
  const registrationTax = _get(calculatorResult, 'data.variables.fees.registration.total', 0);
  const vehicleExciseDuty = _get(calculatorResult, 'data.variables.fees.vehicle_excise_duty.total', 0)
  const incentiveTotalInPurchasePrice = _get(
    calculatorResult,
    'data.apiResults.incentives.total.includedInPurchasePrice',
    0
  );
  const showZeroRegistrationTax =
    hideZeroRegistrationTax !== null ? false : showZeroRegistrationTaxState;
  const modelCode = _get(state, 'Configuration.model_code', '');
  const driverType = state?.FinancingOptions?.driverType;
  return {
    registrationType: AccountType,
    configuration: option_codes,
    incentiveTotalInPurchasePrice,
    governmentIncentivesTotal,
    showZeroRegistrationTax,
    financeProductId: getFinanceProductId(state),
    extraIncentives: ReviewDetails.extraIncentives ?? [],
    registrationTax,
    sellerDiscount,
    stateContribution,
    OMVICFee,
    ecoBonus,
    governmentGrant,
    specialTax,
    regionalTax,
    weightTax,
    sctTax,
    luxuryTax,
    winterTireFee,
    tireFee,
    recyclingFee,
    sibling,
    locale,
    modelCode,
    driverType,
    vehicleExciseDuty
  };
}

ExtraIncentives.propTypes = {
  governmentIncentivesTotal: number,
  evCredit: number,
  extraIncentives: arrayOf(string),
  registrationType: string,
  configuration: arrayOf(string),
  incentiveTotalInPurchasePrice: number,
  showZeroRegistrationTax: bool,
  registrationTax: number,
  sellerDiscount: number,
  stateContribution: number,
  OMVICFee: number,
  ecoBonus: number,
  governmentGrant: number,
  specialTax: number,
  regionalTax: number,
  winterTireFee: oneOfType([number, bool]),
  tireFee: oneOfType([number, bool]),
  recyclingFee: oneOfType([number, bool]),
  weightTax: number,
  sctTax: number,
  luxuryTax: number,
  sibling: string,
  locale: string,
  keyPrefix: string,
  wrapper: oneOfType([node, shape({}), func]),
  modelCode: string,
  driverType: string,
  vehicleExciseDuty: oneOfType([number, bool])
};

ExtraIncentives.defaultProps = {
  governmentIncentivesTotal: 0,
  evCredit: 0,
  keyPrefix: 'ExtraIncentives',
  wrapper: ({ children }) => <>{children}</>,
};

export default connect(mapStateToProps)(ExtraIncentives);
