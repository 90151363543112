import _get from 'lodash/get';
import { Storage } from '@tesla/coin-common-components';
import {
  REGISTRATION_TYPE_PRIVATE,
  REGISTRATION_TYPE_BUSINESS,
  GENERAL_PRODUCTION,
  FIELD_COMPANY_NAME,
  PAYX_BILLING_ADDRESS_UNDEFINED,
  PICKUP_LOCATION,
  DOC_FEE_DISCLAIMER,
  AC_FEE_DISCLAIMER,
  LUXURY_TAX_DISCLAIMER,
  REGIONAL_INCENTIVE,
  FIELD_VAT_ID,
  FIELD_PRIVATE_VAT_ID,
  CHAMBER_OF_COMMERCE,
  PROVINCE,
  FIELD_ZIP_CODE,
  CUSTOMER_LAT_LON,
  GROUP_SUPER_CHARGER,
} from 'dictionary';
import { filterSalesRegion, getStateCodeFromFullname, i18n } from 'utils';

const windowTesla = window.tesla;
const RegistrationData = Storage.get('RegistrationType', data => {
  if (_get(windowTesla, 'omsParams.market') === _get(data, 'value.ReviewDetails.countryCode', '')) {
    return _get(data, 'value.ReviewDetails.registrantType');
  }
  return _get(data, 'value.ReviewDetails.registrantType');
});

const SummaryData = Storage.get('SummaryPanel', data => {
  if (_get(windowTesla, 'omsParams.market') === _get(data, 'value.SummaryPanel.country_code', '')) {
    return _get(data, `value.SummaryPanel.region_code`);
  }
  return false;
});

const region_code = _get(SummaryData, 'SummaryPanel.region_code', null);

const registrantType = _get(RegistrationData, 'ReviewDetails.registrantType');

const isOrderModification = _get(windowTesla, 'Order.enableOrderModification', false);
const isEnterpriseCustomOrderEdit = _get(windowTesla, 'isEnterpriseCustomOrderEdit', false);

let registrantTypeSaved = isOrderModification || isEnterpriseCustomOrderEdit ? _get(windowTesla, 'registrantType') : null;
registrantTypeSaved =
  typeof registrantTypeSaved === 'string' ? registrantTypeSaved.toLowerCase() : null;
let { deliveryRegion } = filterSalesRegion();
const { isEnterpriseOrder, enterpriseRegistrationAddress } = windowTesla || {};
if (isEnterpriseOrder && enterpriseRegistrationAddress && (!isEnterpriseCustomOrderEdit || !registrantTypeSaved)) {
  registrantTypeSaved = REGISTRATION_TYPE_BUSINESS;
  const { state, country } = enterpriseRegistrationAddress || {};
  deliveryRegion = getStateCodeFromFullname(country, state);
}
const regionCodeSaved = isOrderModification || isEnterpriseCustomOrderEdit
  ? _get(windowTesla, 'regDeliveryData.stateProvince')
  : null;
const isReservation = _get(windowTesla, 'rn');
const omsModelCode = _get(windowTesla, 'omsParams.model');
const isLatestLexicon = _get(windowTesla, 'DSServices')[`Lexicon.${omsModelCode}`]?.latest;
const isEnableLoadLatestLexicon = _get(windowTesla, 'isEnableLoadLatestLexicon', false);
const CNEvSubsidyCutOff = new Date().getTime() > new Date('2022-12-31 23:59:59').getTime();

export default ({ object }) => ({
  merge_method: 'assign',
  object: {
    ...object,
    RegistrationDetail: {
      RegistrantType: null,
      RegistrationAddress: {
        CountryCode: _get(windowTesla, 'omsParams.market'),
      },
    },
    asset: {
      type: 'image-compositor',
      view: 'STUD_3QTR',
      bkba: isReservation ? 2 : 1,
      zoom: 1,
    },
    showVehicleLocation: true, // Decide if we want to move these to DS services
    showVehicleLocationDisclaimer: false,
    showLocationPrefix: false,
    isIntlPhoneInputEnabled: windowTesla?.isAdvancedPhoneInputEnabled || false,
    isBusinessVerificationEnabled: windowTesla?.isBusinessVerificationEnabled || false,
    hideActionField: [],
    hideCallbackLink: false,
    defaultAccountType: REGISTRATION_TYPE_PRIVATE,
    callbackData: {
      location: {
        provinceList: {},
        cityList: {},
        selectedProvince: '',
        selectedCity: '',
      },
    },
    callbackStatus: null,
    showCallbackConsent: false,
    enabledZipcode: true,
    showVehicleTotalWithTax: false,
    DeliveryLocations: null,
    yourModelPriceLabel: 'Review.model_label',
    formatWithPrecision: false,
    hasAvailableInventory: undefined,
    checkAvailableInventoryForMarket: false,
    isInventoryUpsellEnabled: false,
    inventoryLocation: null,
    displayDecimalPoints: false,
    genericOtherFeeDisclaimer: false,
    hidePlaceHolderInput: false,
    migratePricing: false,
    isMigratePricingFeature: false,
    vehicleDesign: {
      selectedConfig: '',
      isEarlyDesignSelected: false,
      initialDesign: {
        options: '',
        price: 0,
        grossPrice: 0,
        specs: {},
      },
      earlyVehicleDesigns: [],
      swapConfig: {},
      error: null,
    },
    displayFinanceToggleOnSwap: false,
    checkDynamicIncentive: false,
    showDynamicIncentive: false,
    crossBorderRegistrationFields: {
      // This needs to be stay in app-find-my-tesla because of special use case. It shouldn't be moved to ds-services
      private: [
        {
          market: ['ES', 'HR', 'IT', 'PT', 'IS'],
          fields: [FIELD_PRIVATE_VAT_ID],
        }
      ],
      business: [
        {
          market: [
            'AT',
            'BE',
            'CH',
            'CZ',
            'DK',
            'ES',
            'FI',
            'GR',
            'HR',
            'HU',
            'IE',
            'IL',
            'IT',
            'LU',
            'NL',
            'NO',
            'PL',
            'PT',
            'RO',
            'SE',
            'SI',
            // Non-Core Markets
            'EE',
            'LT',
            'LV',
            'SK',
            // Markets without Configurator
            'BG',
            'CY',
            'LI',
            'MT',
          ],
          fields: [FIELD_COMPANY_NAME, FIELD_VAT_ID],
        },
        {
          market: ['IS'],
          fields: [FIELD_COMPANY_NAME, CHAMBER_OF_COMMERCE],
        },
        {
          market: ['FR'],
          fields: [FIELD_COMPANY_NAME, CHAMBER_OF_COMMERCE, FIELD_VAT_ID],
        },
      ],
    },
    useLexiconBaseGroupForInventory: false,
    showBanner: true,
    mktOptionGroups: [
      'PAINT',
      'WHEELS',
      'TOWING',
      'TOW_PACKAGE',
      'INTERIOR',
      'INTERIOR_COLORWAY',
      'REAR_SEATS',
      'STEERING_WHEEL',
    ],
    enableInventoryNewVehicleCombo: false,
    ignoreFieldsForDeliveryLocation: [],
    referralFromCookieEnabled: true,
    DeliveryContact: {},
    savingsToggleTabs: [
      {
        id: 'purchase_price',
        label: i18n('Review.purchase_price'),
      },
      {
        id: 'potential_savings',
        label: i18n('Review.est_savings_label'),
      },
    ],
    showRegionalSelector: false,
    hasPublicInventory: true,
    inventorySwapGroupsOrder: [],
    hcaptchaToken: '',
  },
  deltas: {
    market: {
      NZ: {
        RegistrationDetail: {
          RegistrantType: registrantTypeSaved || registrantType || REGISTRATION_TYPE_PRIVATE,
        },
      },
      AU: {
        RegistrationDetail: {
          RegistrantType: registrantTypeSaved || registrantType || REGISTRATION_TYPE_PRIVATE,
          RegistrationAddress: {
            CountryCode: _get(windowTesla, 'omsParams.market'),
            StateProvince: deliveryRegion || regionCodeSaved || region_code || 'NSW',
          },
        },
      },
      CN: {
        showIncentivesDisclaimerCopy: false,
        enabledZipcode: false,
        enableMigratePricing: isReservation && isEnableLoadLatestLexicon,
        migratePricing: isReservation && !isLatestLexicon && isEnableLoadLatestLexicon,
        enableInventoryNewVehicleCombo: true,
        ...(CNEvSubsidyCutOff
          ? { showVehicleConfigurationPrice: false }
          : ''),
        referralFromCookieEnabled: false,
        timeOfClickPlaceOrderButton: '',
        isReservationOriginallyRefundable: _get(
          windowTesla,
          'isReservationOriginallyRefundable',
          null
        ),
      },
      NO: {
        showLocationPrefix: true,
      },
      MO: {
        showVehiclePricePlusFees: true,
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      CA: {
        showCallbackConsent: true,
        showVehiclePricePlusFees: true,
        yourModelPriceLabel: 'Review.purchase_price',
        formatWithPrecision: true,
        savingsToggleTabs: [
          {
            id: 'purchase_price',
            label: i18n('Review.vehicle_price'),
          },
          {
            id: 'potential_savings',
            label: i18n('Review.est_savings_label'),
          },
        ],
        showRegionalSelector: true
      },
      HK: {
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      IE: {
        enabledZipcode: false,
      },
      AE: {
        DeliveryDetails: {
          StateProvince: deliveryRegion || regionCodeSaved || region_code || 'Dubai',
        },
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      CL: {
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      HR: {
        displayDecimalPoints: true,
      },
      IL: {
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      PH: {
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      PR: {
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      QA: {
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      SG: {
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
      US: {
        showVehiclePricePlusFees: true,
        showDynamicIncentive: true,
        displayFinanceToggleOnSwap: true,
        showRegionalSelector: true
      },
      TH: {
        hidePlaceHolderInput: true,
      },
      MY: {
        showVehiclePricePlusFees: true,
        enabledZipcode: false,
        ignoreFieldsForDeliveryLocation: [PROVINCE, FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      },
    },
    model: {
      ms: {
        asset: {
          type: 'image-compositor',
          view: 'STUD_3QTR_V2',
          bkba: isReservation ? 2 : 1,
          zoom: 1,
        },
        inventorySwapGroupsOrder:  [
            "DRIVE",
            "PAINT",
            "WHEELS",
            "INTERIOR_PACKAGE",
            "INTERIOR_COLORWAY",
            "STEERING_WHEEL",
            "AUTOPILOT",
            "DRIVE_ANYWHERE",
        ],
      },
      mx: {
        asset: {
          type: 'image-compositor',
          view: 'STUD_3QTR_V2',
          bkba: isReservation ? 2 : 1,
          zoom: 1.5,
        },
        inventorySwapGroupsOrder: [
            "DRIVE",
            "PAINT",
            "WHEELS",
            "INTERIOR_PACKAGE",
            "INTERIOR_COLORWAY",
            "STEERING_WHEEL",
            "REAR_SEATS",
            "TOW_PACKAGE",
            "AUTOPILOT",
            "DRIVE_ANYWHERE",
        ],
      },
      m3: {
        inventorySwapGroupsOrder: [
            "PAINT",
            "WHEELS",
            "INTERIOR",
            "TOWING",
            "AUTOPILOT",
        ],
      },
      my: {
        inventorySwapGroupsOrder: [
            "PAINT",
            "WHEELS",
            "INTERIOR",
            "REAR_SEATS",
            "TOWING",
            "AUTOPILOT",
        ],

      },
      ts: {
        showQtyInput: true,
        VehicleDetail: {
          modelCode: 'ts',
          series: GENERAL_PRODUCTION,
          qty: 1,
          valid: true,
        },
        hideBaseField: [PICKUP_LOCATION],
        financeMenuOptions: false,
        showSavingsDisclaimer: false,
        showDisclaimerLink: false,
        showPurchasePrice: false,
        extraIncentives: [],
        nonRefundPolicy: {
          source: false,
          target: false,
        },
        AccountDetail: {
          AccountType: 'business',
          CompanyName: '',
        },
        extraBusinessFields: [FIELD_COMPANY_NAME],
        defaultAccountType: REGISTRATION_TYPE_BUSINESS,
      },
    },
  },
  deltasV2: [
    {
      selected_by: {
        isUsedInventory: true,
      },
      object: {
        cosmeticInspections: ['paint', 'panelOrTrim', 'glass', 'wheel', 'interior', 'sealsAndTrim'],
        cosmeticInspectionsList: [
          'paintScratches_metric',
          'dents_metric',
          'paintChips',
          'panelOrTrimScratches_metric',
          'glassChips_metric',
          'glassScratches',
          'wheelRash_metric',
          'interiorUsage_metric',
          'entryPoints',
          'sealsAndTrimDamage',
        ],
        mechanicalInspections: ['standards', 'functional'],
        mechanicalInspectionsList: [
          'structural',
          'tires_metric',
          'brakes',
          'autopilot',
          'display',
          'powerOperated',
          'steering',
          'powertrain',
          'braking',
          'lighting',
          'controls',
          'restraint',
          'charging',
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        model: ['ms', 'mx'],
      },
      object: {
        asset: {
          bkba: 1,
          type: 'image-compositor',
          view: _get(windowTesla, 'product.data.IsLegacy') ? 'STUD_3QTR' : 'STUD_3QTR_V2',
        },
      },
    },
    {
      selected_by: {
        isInventory: false,
        market: 'US',
        model: 'my',
      },
      object: {
        hasAvailableInventory: true,
        isInventoryUpsellEnabled: false,
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: true,
        market: ['US'],
      },
      object: {
        cosmeticInspections: [
          'paint',
          'paintInterior',
          'panelOrTrim',
          'glass',
          'wheel',
          'interior',
        ],
        cosmeticInspectionsList: [
          'paintScratches',
          'scratchesDents',
          'dents',
          'paintChips',
          'panelOrTrimScratches',
          'glassChips',
          'glassScratches',
          'wheelRash',
          'interiorUsage',
          'entryPoints',
        ],
        mechanicalInspectionsList: [
          'structural',
          'tires',
          'brakes',
          'autopilot',
          'display',
          'powerOperated',
          'steering',
          'powertrain',
          'braking',
          'lighting',
          'controls',
          'restraint',
          'charging',
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: true,
        market: ['CA'],
      },
      object: {
        cosmeticInspections: [
          'paint',
          'paintInterior',
          'panelOrTrim',
          'glass',
          'wheel',
          'interior',
        ],
        cosmeticInspectionsList: [
          'paintScratches_metric',
          'scratchesDents_metric',
          'dents_metric',
          'paintChips',
          'panelOrTrimScratches_metric',
          'glassChips_metric',
          'glassScratches',
          'wheelRash_metric',
          'interiorUsage_metric',
          'entryPoints',
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        market: ['CA'],
      },
      object: {
        genericOtherFeeDisclaimer: [
          DOC_FEE_DISCLAIMER,
          AC_FEE_DISCLAIMER,
          LUXURY_TAX_DISCLAIMER,
          REGIONAL_INCENTIVE,
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: true,
        market: [
          'CA',
          'AE',
          'AT',
          'BE',
          'CH',
          'CZ',
          'DE',
          'DK',
          'ES',
          'EU',
          'FI',
          'FR',
          'GB',
          'GR',
          'HR',
          'IE',
          'IL',
          'IS',
          'IT',
          'JO',
          'LU',
          'NL',
          'NO',
          'PL',
          'PR',
          'PT',
          'SE',
          'SI',
        ],
      },
      object: {
        showVehicleLocationDisclaimer: true,
      },
    },
    {
      selected_by: {
        isInventory: false,
        market: ['PR', 'HK'],
      },
      object: {
        showVehiclePricePlusFees: true,
      },
    },
    {
      selected_by: {
        model: 'ts',
        isReservation: true,
      },
      object: {
        purchasePriceLabel: 'Review.expected_base_price_semi',
      },
    },
    {
      selected_by: {
        model: ['ts'],
        market: ['US'],
      },
      object: {
        billingAddressDetailsPayX: PAYX_BILLING_ADDRESS_UNDEFINED,
      },
    },
    {
      selected_by: {
        market: ['AU', 'NZ', 'SG'],
      },
      object: {
        showVehicleTotalWithTax: true,
      },
    },
    // COIN-8883: Hide Rear Images for certain S/X trims on Inventory
    {
      selected_by: {
        isInventory: true,
        model: ['ms', 'mx'],
      },
      object: {
        hideGroupAssets: {
          trim: ['$MTS13', '$MTS14', '$MTX13', '$MTX14'],
          view: ['REAR34'],
        },
      },
    },
    {
      selected_by: {
        model: ['my'],
      },
      object: {
        hideGroupAssets: {
          skuVariantIncluded: ['LR_AWD_NV36', 'LR_RWD_NV36', 'LR_AWD_NV36_LAUNCH', 'RWD_NV36', 'RWD_NV36_LAUNCH'],
          view: ['INTERIOR_ROW2'],
        },
      },
    },
    {
      selected_by: {
        market: 'US',
        model: ['m3', 'my', 'mx', 'ct'],
      },
      object: {
        checkDynamicIncentive: true,
      },
    },
    {
      selected_by: {
        isEnterpriseOrder: true,
        model: ['m3', 'my', 'mx', 'ct'],
      },
      object: {
        checkDynamicIncentive: false,
      },
    },
    {
      selected_by: {
        isEnterpriseOrder: true,
        model: 'ms',
      },
      object: {
        hidePriceSummaryList: [GROUP_SUPER_CHARGER],
      },
    },
    {
      selected_by: {
        isEnterpriseOrder: true,
        model: 'ms',
        market: ['AE', 'AT', 'BE', 'CH', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE', 'IL', 'IS', 'IT', 'LU', 'NL', 'NO', 'PL', 'PT', 'QA', 'RO', 'SE', 'SI', 'SK'],
      },
      object: {
        hidePriceSummaryList: [],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: true,
      },
      object: {
        checkDynamicIncentive: false,
        isRefurbComplete: _get(windowTesla, 'product.data.CPORefurbishmentStatus')?.toLowerCase() === 'complete',
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: false,
        model: ['m3', 'my'],
        market: 'DE',
      },
      object: {
        useLexiconBaseGroupForInventory: true,
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: false,
        isPostOrderSwap: false,
        market: 'US',
      },
      object: {
        rangeRestrictedSalesMetro: true,
        galleryStates: ['DC', 'GA', 'IA', 'MI', 'NM', 'OK', 'TX', 'WI'],
      }
    },
    {
      selected_by: {
        isInventory: true,
        isPostOrderSwap: false,
        market: 'CA',
      },
      object: {
        rangeRestrictedSalesMetro: true,
        isSoldOutFlowEnabled: true,
      }
    },
    {
      selected_by: {
        isInventory: true,
        isPostOrderSwap: false,
        market: 'AU',
      },
      object: {
        rangeRestrictedSalesMetro: true,
        showAllDeliveryLocations: true,
        isSoldOutFlowEnabled: true,
      }
    },
    {
      selected_by: {
        isInventory: true,
        isPostOrderSwap: false,
        market: 'MY',
      },
      object: {
        showAllDeliveryLocations: true,
      }
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: false,
        isEnterpriseOrder: true,
      },
      object: {
        useLexiconBaseGroupForInventory: true,
      },
    },
    {
      selected_by: {
        isInventory: true,
        isEnterpriseOrderPickup: false,
        isEnterpriseOrder: true,
      },
      object: {
        ignoreFieldsForDeliveryLocation: [FIELD_ZIP_CODE, PROVINCE],
      }
    },
    {
      selected_by: {
        isEnterpriseOrder: true,
        market: ['NZ'],
      },
      object: {
        RegistrationDetail: {
          RegistrantType: registrantTypeSaved || registrantType || REGISTRATION_TYPE_BUSINESS,
        },
      }
    },
    {
      selected_by: {
        isInventory: true,
        market: ['AU', 'NZ'],
      },
      object: {
        isDeliverySelectionEnabledByRegion: true,
        ignoreFieldsForDeliveryLocation: [FIELD_ZIP_CODE, CUSTOMER_LAT_LON],
      }
    },
    {
      selected_by: {
        isInventory: true,
        market: ['US', 'MX'],
      },
      object: {
        isSoldOutFlowEnabled: true,
      }
    },
    {
      selected_by: {
        isReservationToOrderFlow: true,
        model: 'ct',
      },
      object: {
        showPurchasePrice: true,
        isSinglePage: false,
        orderPageDisclaimerCopySrc: '',
      },
    },
    {
      selected_by: {
        isEditDesign: true,
        model: 'ct',
      },
      object: {
        showPurchasePrice: true,
        isSinglePage: false,
        orderPageDisclaimerCopySrc: '',
      },
    },
    {
      selected_by: {
        model: 'ct',
      },
      object: {
        hasPublicInventory: false,
      },
    },
    {
      selected_by: {
        model: ['m3',],
        market: ['CN'],
      },
      object: {
        deliveryDueDays: _get(windowTesla, 'App.deliveryDueDays.m3', ''),
      },
    },
    {
      selected_by: {
        model: ['my',],
        market: ['CN'],
      },
      object: {
        deliveryDueDays: _get(windowTesla, 'App.deliveryDueDays.my', ''),
      },
    },
    {
      selected_by: {
        model: ['ms',],
        market: ['CN'],
      },
      object: {
        deliveryDueDays: _get(windowTesla, 'App.deliveryDueDays.ms', ''),
      },
    },
    {
      selected_by: {
        model: ['mx',],
        market: ['CN'],
      },
      object: {
        deliveryDueDays: _get(windowTesla, 'App.deliveryDueDays.mx', ''),
      },
    }
  ],
});
