import { formatCurrency, formatPercent } from '@tesla/coin-common-components';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import { array, bool, number, string, shape } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  getAllCurrentFees,
  getDepositAmount,
  getFinancialIncentives,
  getOrderFeeFromFinancialSvc,
  getTransportationFeeAmount,
  getReferralCredit,
  getRegionCode,
  getReservationCredit,
  getVATAmount,
} from 'selectors';
import { i18n, getVatRateForForwardCalculation } from 'utils';
import { ORDER_FEE_TYPE } from 'dictionary';

const VehiclePricePlusFeesAndDiscounts = ({
  currentFeesFiltered,
  orderFee,
  currentIncentivesFiltered,
  inventoryDiscount,
  showOrderFee,
  deliveryPostalCode,
  transportationFee,
  isTransportFeeCollectionEnabled,
  isTransportFeeEnabled,
  isDeliveryDetailsValid,
  referralCredit,
  regionCode,
  showDiscountFees,
  reservationCredit,
  isPostOrderSwap,
  swapWithDiscount,
  showRegionalTaxTypes,
  showDistinctTaxTotal,
  vehiclePriceInclFees,
  gstSalePrice,
  vehiclePriceWithoutDiscounts,
  showVehiclePrice,
  vatAmount,
  vehicleCondition,
  showVatDisclaimer,
  feesInVehicleWithOrderPrice,
}) => {
  const getOrderFee = () => {
    return (
      <If condition={showOrderFee}>
        <li className="tds-list-item tds-text_color--10" data-id={'order_fee-line-item'}>
          <span className="left">
            {isPostOrderSwap
              ? i18n('SummaryPanel.order_fee_paid')
              : i18n('SummaryPanel.PaymentSummary.order_fee')}
          </span>
          <span className="right">{formatCurrency(orderFee)}</span>
        </li>
      </If>
    );
  };
  return (
    <>
      <If condition={showVehiclePrice}>
        <div className="tds-o-margin-top">
          <ol className="tds-list">
            <li className="tds-list-item tds-flex">
              <span className="tds-flex-item">{i18n('Review.vehicle_price')}</span>
              <span className="value price-indicator">
                {formatCurrency(vehiclePriceWithoutDiscounts)}
              </span>
            </li>
          </ol>
        </div>
      </If>
      <div className={cx({'tds-o-margin-top': showVehiclePrice}, {'tds-o-margin-top--2x': !showVehiclePrice})}>
        <ol className="tds-list">
          <div className={cx("pricing--separater-after", { "tds-o-flex-direction--column": showDistinctTaxTotal })}>
            <If condition={inventoryDiscount || swapWithDiscount}>
              <li className="tds-list-item tds-text_color--10" data-id={`line-item`}>
                <span className="left">{i18n('Review.price_adjustment_label')}</span>
                <span className="right">
                  {formatCurrency(-(inventoryDiscount || swapWithDiscount))}
                </span>
              </li>
            </If>

            {_map(currentIncentivesFiltered, (incentive) => {
              return (
                <li
                  key={`fees-discount-incentives-${incentive?.incentiveType}`}
                  className="review-page--line-item tds-list-item"
                >
                  <div className="label left">
                    <p>
                      {i18n(`SummaryPanel.PaymentSummary.${incentive?.incentiveType}`, null, null, {
                        specificOverride: [incentive?.customerType].filter(Boolean),
                        specificOverrideOperator: 'OR',
                      })}
                    </p>
                  </div>
                  <span className="value">{formatCurrency(incentive?.amount)}</span>
                </li>
              );
            })}

            <If condition={referralCredit?.credit}>
              <li
                key="referral-bonus-discount"
                className="tds-list-item tds-text_color--10"
                data-id="referral-bonus-line-item"
              >
                <span className="left">{i18n('Referral.referrer_name')}</span>
                <span className="right">{formatCurrency(-referralCredit?.credit) || 0}</span>
              </li>
            </If>

            <If condition={showDistinctTaxTotal}>
              {getOrderFee()}
              {_map(feesInVehicleWithOrderPrice, (fee) => {
                return (
                  <li
                    key={`fees-discount-fees-${fee.feeType}`}
                    className="tds-list-item tds-text_color--10"
                    data-id={`${fee.feeType}-line-item`}
                  >
                    <span className="left tds-o-margin_right-16">
                      {i18n(
                        `SummaryPanel.PaymentSummary.${fee.feeType}`,
                        {
                          PERCENT: formatPercent(fee?.percent, 0),
                        },
                        null,
                        {
                          specificOverride: regionCode,
                          specificOverrideOperator: 'OR',
                        }
                      )}
                    </span>
                    <span className="right">
                      {formatCurrency(fee?.amount || 0, { useDynamicRounding: true })}
                    </span>
                  </li>
                );
              })}
              <li className="tds-list-item tds-flex tds-text--medium">
                <span className="tds-flex-item tds-o-flex-direction--column">
                  <span>{i18n('FinancingOptions.vehicle_subTotal')}</span>
                  <If condition={gstSalePrice || showVatDisclaimer}>
                    <span className="items price review-page--disclaimer tds-text--caption">
                      {i18n('SummaryPanel.disclaimers.vehicle_GST', {
                        GST: formatCurrency(gstSalePrice),
                        VAT_AMOUNT: formatCurrency(vatAmount),
                      },
                      null,
                      {
                        specificOverride: [vehicleCondition],
                        returnNullWhenEmpty: true,
                        specificOverrideOperator: 'OR',
                      })}
                    </span>
                  </If>
                </span>
                <span className="value price-indicator">{formatCurrency(vehiclePriceInclFees)}</span>
              </li>
            </If>

            <If condition={showDiscountFees}>
              {_map(currentFeesFiltered, (fee, key) => {
                return (
                  <Choose>
                    <When condition={fee?.showFeesBreakdownOnUI}>
                      {showRegionalTaxTypes?.map(feeType => {
                        return (
                          <If condition={_has(fee, feeType)}>
                            <li
                              key={`fees-discount-fees-${feeType}`}
                              className="tds-list-item tds-text_color--10"
                              data-id={`${feeType}-line-item`}
                            >
                              <span className="left tds-o-margin_right-16">
                                {i18n(`FinancingOptions.${feeType}`)}
                              </span>
                              <span className="right">
                                {formatCurrency(fee?.[feeType] || 0, { useDynamicRounding: true })}
                              </span>
                            </li>
                          </If>
                        );
                      })}
                    </When>
                    <Otherwise>
                      <li
                        key={`fees-discount-fees-${fee.feeType || key}`}
                        className="tds-list-item tds-text_color--10"
                        data-id={`${fee.feeType}-line-item`}
                      >
                        <span className="left tds-o-margin_right-16">
                          {i18n(
                            `SummaryPanel.PaymentSummary.${fee.feeType || key}`,
                            {
                              PERCENT: formatPercent(fee?.percent, 0),
                            },
                            null,
                            {
                              specificOverride: regionCode,
                              specificOverrideOperator: 'OR',
                            }
                          )}
                        </span>
                        <span className="right">
                          {formatCurrency(fee?.amount || 0, { useDynamicRounding: true })}
                        </span>
                      </li>
                    </Otherwise>
                  </Choose>
                );
              })}
            </If>
            {/* Order fee is calculated seperately if not in FinSvc */}
            <If condition={!showDistinctTaxTotal}>{getOrderFee()}</If>
            {/* Transportation fee is calculated independently of FinSvc */}
            <If
              condition={
                !isTransportFeeCollectionEnabled &&
                isTransportFeeEnabled &&
                isDeliveryDetailsValid &&
                transportationFee
              }
              key="transportationFeeCash"
            >
              <li className="tds-list-item">
                <span className="left tds-text--regular">
                  {i18n('DeliveryLocation.estimate_transport_fee_label_short', {
                    POSTAL_CODE: deliveryPostalCode,
                  })}
                </span>
                <span className="tds-text--end tds-text--regular">
                  {formatCurrency(transportationFee)}
                </span>
              </li>
            </If>
            <If condition={reservationCredit}>
              <li
                className="tds-list-item tds-text_color--10"
                data-id={'reservation-credit-line-item'}
              >
                <span className="left">{i18n('SummaryPanel.reservation_credit')}</span>
                <span className="right">{formatCurrency(reservationCredit)}</span>
              </li>
            </If>
          </div>
        </ol>
      </div>
    </>
  );
};

function mapStateToProps(state, ownProps) {
  const { hideVehiclePrice } = ownProps || {};
  const { App, FinancingOptions, ReviewDetails } = state;
  const { isInventoryPriceAdjustmentsEnabled = false, isPostOrderSwap } = App;
  const {
    showAfterSavingsPriceForTrims = false,
    includeOrderFeeInVehicleTotal = true,
    showDiscountFees = true,
    showVehiclePriceInSummary,
    vehicleDesign: { swapConfig: { Discount: swapWithDiscount = 0 } } = {},
    product = {},
    showVatDisclaimer = false,
  } = ReviewDetails || {};
  const { condition: vehicleCondition } = product || {};
  let feesInVehicleWithOrderPrice = {};
  const { incentives, incentiveInFees } = getFinancialIncentives(state);
  const currentFees = getAllCurrentFees(state);
  const currentFeesFiltered = _reduce(currentFees, (res, fee, key) => {
    const feeObj = fee[0] || {};
    if (feeObj?.includedInVehiclePriceWithOrderFee) {
      feesInVehicleWithOrderPrice = {
        ...feesInVehicleWithOrderPrice,
        [key]: feeObj,
      };

      return res;
    }
    if (feeObj?.isUIHidden || (!feeObj?.amount && !feeObj?.isUIShownZero)) {
      return res;
    }
    return {
      ...res,
      ...(feeObj?.showIncentivesBreakdownOnUI ? incentiveInFees : {}),
      [key]: feeObj,
    };
  }, {});
  const { orderPayment: orderFee = 0, paymentSourceSubType = '' } =
    getDepositAmount(state, true) || {};
  const { showIncentiveInFeesAndDiscounts = [], showRegionalTaxTypes = [], showDistinctTaxTotal } =
    FinancingOptions || {};
  const currentIncentivesFiltered = _reduce(incentives, (res, incentive, key) => {
    const incObj = incentive[0] || {};
    if (incObj?.isUIHidden || !showIncentiveInFeesAndDiscounts?.includes(incObj?.incentiveType)) {
      return res;
    }
    return {
      ...res,
      [key]: incObj,
    };
  }, {});
  const inventoryDiscount = _get(ReviewDetails, 'product.data.Discount', 0);
  const isOrderFee = paymentSourceSubType?.includes(ORDER_FEE_TYPE);
  const includeOrderFeeInVehicleTotalFlag = isOrderFee ? includeOrderFeeInVehicleTotal : false;
  const showOrderFee = includeOrderFeeInVehicleTotalFlag ? orderFee : 0;
  const deliveryPostalCode = _get(state, 'ReviewDetails.DeliveryDetails.PostalCode', '');
  const transportationFee = getTransportationFeeAmount(state);
  const isTransportFeeCollectionEnabled = (state, 'App.isTransportFeeCollectionEnabled', false);
  const isTransportFeeEnabled = _get(state, 'ReviewDetails.isTransportFeeEnabled');
  const isPickupOnlyEnabled = _get(state, 'App.isPickupOnlyEnabled', false);
  const isDeliveryDetailsValid = isPickupOnlyEnabled
    ? true
    : _get(state, 'ReviewDetails.isDeliveryDetailsValid', false);
  const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
  const gstSalePrice =
    state?.Pricing?.calculatorResult?.data?.apiResults?.fees?.current?.regional?.data?.[0]
      ?.variables?.gst_on_sale || 0;
  const vatAmount = getVATAmount(state);
  const referralCredit = getReferralCredit(state);
  const { coeBidAmount = 0 } = state?.FinancingOptions || {};
  const { vehiclePriceWithDeliveryFee, vehiclePriceWithoutDiscounts = 0 } = state?.Pricing?.cash || {};
  const noAdjustmentsListed = _isEmpty(currentFeesFiltered) && _isEmpty(currentIncentivesFiltered) && !referralCredit?.credit;
  return {
    feesInVehicleWithOrderPrice,
    currentFeesFiltered,
    orderFee,
    currentIncentivesFiltered,
    showAfterSavingsPriceForTrims,
    showIncentiveInFeesAndDiscounts,
    inventoryDiscount: isInventoryPriceAdjustmentsEnabled
      ? inventoryDiscount * vatRateForForwardCalculation
      : 0,
    showOrderFee,
    showDiscountFees,
    deliveryPostalCode,
    transportationFee,
    isTransportFeeCollectionEnabled,
    isTransportFeeEnabled,
    isDeliveryDetailsValid,
    referralCredit,
    regionCode: getRegionCode(state),
    reservationCredit: getReservationCredit(state),
    isPostOrderSwap,
    swapWithDiscount,
    showRegionalTaxTypes,
    showDistinctTaxTotal,
    gstSalePrice,
    vehiclePriceInclFees: vehiclePriceWithDeliveryFee - coeBidAmount,
    vehiclePriceWithoutDiscounts,
    showVehiclePrice: !hideVehiclePrice && !noAdjustmentsListed && !showVehiclePriceInSummary && !showDistinctTaxTotal,
    vatAmount,
    vehicleCondition,
    showVatDisclaimer,
  };
}

VehiclePricePlusFeesAndDiscounts.propTypes = {
  feesInVehicleWithOrderPrice: shape({}),
  currentFeesFiltered: shape({}),
  orderFee: number,
  currentIncentivesFiltered: shape({}),
  showAfterSavingsPriceForTrims: bool,
  showIncentiveInFeesAndDiscounts: array,
  inventoryDiscount: number,
  showOrderFee: bool,
  showDiscountFees: bool,
  deliveryPostalCode: string,
  transportationFee: number,
  isTransportFeeCollectionEnabled: bool,
  isTransportFeeEnabled: bool,
  isDeliveryDetailsValid: bool,
  referralCredit: shape({ credit: number }),
  regionCode: string,
  isPostOrderSwap: bool,
  swapWithDiscount: number,
  vehiclePriceWithoutDiscounts: number,
  showVehiclePrice: bool,
  vatAmount: number,
  vehicleCondition: string,
  showVatDisclaimer: bool,
};

VehiclePricePlusFeesAndDiscounts.defaultProps = {
  feesInVehicleWithOrderPrice: {},
  currentFeesFiltered: {},
  orderFee: 0,
  currentIncentivesFiltered: {},
  showAfterSavingsPriceForTrims: false,
  showIncentiveInFeesAndDiscounts: [],
  inventoryDiscount: 0,
  showOrderFee: true,
  showDiscountFees: true,
  deliveryPostalCode: '',
  transportationFee: 0,
  isTransportFeeCollectionEnabled: false,
  isTransportFeeEnabled: false,
  isDeliveryDetailsValid: false,
  referralCredit: {},
  regionCode: '',
  isPostOrderSwap: false,
  swapWithDiscount: 0,
  vehiclePriceWithoutDiscounts: 0,
  showVehiclePrice: false,
  vatAmount: 0,
  vehicleCondition: '',
  showVatDisclaimer: false,
};

export default connect(mapStateToProps)(VehiclePricePlusFeesAndDiscounts);
