import _get from 'lodash/get';
import _union from 'lodash/union';
import _without from 'lodash/without';

import {
  PRICE_CHANGED,
  BASE_CONFIGURATION_CHANGED,
  EMAIL_IS_AVAILABLE,
  EMAIL_IS_UNAVAILABLE,
  EMAIL_CHECK_LOADING,
  EMAIL_CHECK_ERRORED,
  ACCOUNT_DETAILS_VALID_FLAG,
  ACCOUNT_DETAILS_CHANGED,
  SAVE_DESIGN_FLAG,
  IDENTIFICATION_TYPE_DETAILS_CHANGED,
  HIDE_BASE_FIELD,
  SHOW_BASE_FIELD,
  REGISTRATION_TYPE_CHANGED,
  COMPLETE_CONFIGURATION_CHANGED,
  SUMMARY_PANEL_REGION_CHANGE,
  TOGGLE_TRIM_SAVINGS_PRICE,
  SHOW_PRICING_DETAILS,
  UPDATE_VEHICLE_DETAIL,
  DELIVERY_DETAILS_VALID_FLAG,
  DELIVERY_DETAILS_CHANGED,
  SET_FORM_ERRORS,
  CLEAR_FORM_ERRORS,
  LEGAL_CONSENT_VALID_FLAG,
  PARSE_VEHICLE_UPGRADES,
  SET_ACCOUNT_REGISTRATION_TYPE,
  SET_FINANCE_MODAL_DRIVER_TYPE,
  REGISTRATION_TYPE_BUSINESS,
  REGISTRATION_TYPE_PRIVATE,
  Models,
  VIEW_DISCLOSURE,
  UPDATE_CALLBACK_DETAIL,
  INVENTORY_DELIVERY_LOCATIONS_CHANGED,
  INVENTORY_DELIVERY_LOCATION_INVALID,
  UPDATE_REQ_CALLBACK_SELECTED_PROVINCE,
  UPDATE_REQ_CALLBACK_SELECTED_CITY,
  UPDATE_REQ_CALLBACK_CITY_LIST,
  UPDATE_REQ_CALLBACK_PROVINCE_LIST,
  ERROR_INVALID_LOCATION,
  HAS_AVAILABLE_INVENTORY_FLAG,
  UPDATE_PROVINCE_ADDRESS,
  UPDATE_DISTRICT_LIST,
  UPDATE_DISTRICT_ADDRESS,
  UPDATE_POSTAL_CODE_LIST,
  SET_VEHICLE_DESIGN,
  RESET_VEHICLE_DESIGN,
  TOGGLE_DYNAMIC_INCENTIVE,
  SET_CUSTOM_DESIGN,
  SET_VERIFIED_PHONE_NUMBER,
  SET_TIME_OF_CLICK_PLACE_ORDER_BUTTON,
  UPDATE_DELIVERY_CONTACT,
  SET_TIME_OF_ORDER_PLACED_SUCCESS,
  VERIFICATION_GROUP_MILITARY,
  UPDATE_VERIFICATION_STATUS,
  VERIFICATION_GROUP_UBER_DRIVER,
  UPDATE_SELECTED_INVENTORY_CONFIG,
  SET_HCAPTCHA_TOKEN,
} from 'dictionary';

import { parseSelectedBy, getDeliveryDisclaimer } from 'utils';
import { getConfigurationOptions, getBaseConfigurationOptions } from 'selectors';

function getRefundPolicySource(object, opts) {
  const selected_by = _get(object, 'selected_by');
  let source = '';
  if (selected_by) {
    if (parseSelectedBy(selected_by, opts)) {
      source = _get(object, 'target', '');
    }
  }
  return source;
}

function getPresaleSource(object, opts) {
  const selected_by = _get(object, 'selected_by');
  let source = '';
  if (selected_by) {
    if (parseSelectedBy(selected_by, opts)) {
      source = _get(object, 'target', '');
    }
  } else {
    source = _get(object, 'target', '');
  }

  return source;
}

function getSecondOrderDeposit(object, opts) {
  const selected_by = _get(object, 'selected_by');
  let source = true;

  if (selected_by) {
    if (parseSelectedBy(selected_by, opts)) {
      source = _get(object, 'target', '');
    }
  }
  return source;
}

function getNonRefundPolicy(object = {}, opts) {
  const selected_by = _get(object, 'selected_by');
  let { source } = object;

  if (selected_by) {
    source = true;
    if (parseSelectedBy(selected_by, opts)) {
      source = _get(object, 'target', '');
    }
  }
  return source;
}

function getDisplayOrderRefundLongCopy(object, opts) {
  const selected_by = _get(object, 'selected_by');
  let source = '';
  if (selected_by) {
    if (parseSelectedBy(selected_by, opts)) {
      source = _get(object, 'target', '');
    }
  }

  return source;
}

function generalConfigurationParser(object, opts) {
  const selected_by = _get(object, 'selected_by');
  let { source } = object || {};
  if (selected_by) {
    if (parseSelectedBy(selected_by, opts)) {
      source = _get(object, 'target', '');
    }
  }

  return source;
}

const getDomesticSource = generalConfigurationParser;
function getAssetView(source, opts, defaultView) {
  if (!source || !source.length) {
    return defaultView;
  }
  const data = source.find(i => {
    const selected_by = _get(i, 'selected_by');
    if (selected_by) {
      return parseSelectedBy(selected_by, opts);
    }
    return false;
  });
  return {
    view: _get(data, 'view', defaultView),
    zoom: _get(data, 'zoom', ''),
  };
}

function getInfoMessage(object, opts) {
  const selected_by = _get(object, 'selected_by');
  let { source } = object || {};
  if (selected_by) {
    if (parseSelectedBy(selected_by, opts)) {
      source = _get(object, 'target', '');
    }
  }

  return source || '';
}


function ReviewDetails(state = {}, action, { app_state }) {
  switch (action.type) {
    case PRICE_CHANGED:
    case BASE_CONFIGURATION_CHANGED:
      return {
        ...state,
        base_configuration_options: getBaseConfigurationOptions({
          ...app_state,
          ReviewDetails: state,
        }),
        configuration_options: getConfigurationOptions({ ...app_state, ReviewDetails: state }),
      };
    case EMAIL_IS_AVAILABLE:
      return {
        ...state,
        emailExists: {
          flag: false,
          email: action.emailAddress,
        },
        emailCheckLoading: false,
      };
    case EMAIL_IS_UNAVAILABLE:
      return {
        ...state,
        emailExists: {
          flag: true,
          email: action.emailAddress,
        },
        emailCheckLoading: false,
      };
    case EMAIL_CHECK_LOADING:
      return {
        ...state,
        emailCheckLoading: true,
      };
    case EMAIL_CHECK_ERRORED:
      return {
        ...state,
        emailCheckLoading: false,
      };
    case ACCOUNT_DETAILS_VALID_FLAG:
      return {
        ...state,
        accountDetailsValid: action.flag,
      };
    case ACCOUNT_DETAILS_CHANGED:
      return {
        ...state,
        AccountDetail: {
          ...state?.AccountDetail || {},
          ...action.payload,
        },
      };
    case SET_VERIFIED_PHONE_NUMBER: {
      const payload = action.payload || {};
      return {
        ...state,
        AccountDetail: {
          ..._get(state, 'AccountDetail'),
          PhoneNumber: payload.phoneNumber,
          VerifyPhoneCode: payload.verifyPhoneCode,
        },
      };
    }
    case UPDATE_VERIFICATION_STATUS:
      let verificationObj = {};
      (action?.data || []).forEach(({ statusGroup, status }) => {
        if (statusGroup === VERIFICATION_GROUP_MILITARY) {
          verificationObj.isMilitary = status;
        }
        if (statusGroup === VERIFICATION_GROUP_UBER_DRIVER) {
          verificationObj.isUberDriver = status;
        }
      });
      return {
        ...state,
        AccountDetail: {
          ...state?.AccountDetail || {},
          ...verificationObj,
        },
      }
    case SAVE_DESIGN_FLAG:
      return {
        ...state,
        isSaveDesignEnabled: action.flag,
      };
    case SHOW_PRICING_DETAILS:
      return {
        ...state,
        showPricesDetail: action.flag,
      };
    case SET_ACCOUNT_REGISTRATION_TYPE:
      return {
        ...state,
        AccountDetail: {
          ..._get(state, 'AccountDetail'),
          AccountType: action.regType,
        },
      };
    case IDENTIFICATION_TYPE_DETAILS_CHANGED:
      return {
        ...state,
        identificationLabel: action.IdentificationType,
        AccountDetail: {
          ..._get(state, 'AccountDetail'),
          IdentificationNumber: null,
        },
      };

    case HIDE_BASE_FIELD:
      return {
        ...state,
        hideBaseField: _union(state.hideBaseField, [action.field]),
      };

    case SHOW_BASE_FIELD:
      return {
        ...state,
        hideBaseField: _without(state.hideBaseField, action.field),
      };

    case REGISTRATION_TYPE_CHANGED:
      return {
        ...state,
        RegistrationDetail: {
          ..._get(state, 'RegistrationDetail', {}),
          RegistrantType: action.regType,
        },
      };

    case SUMMARY_PANEL_REGION_CHANGE:
      let inventoryLocationObj = {};
      if (
        state?.inventoryLocation?.regionCode &&
        action.regionCode !== state?.inventoryLocation?.regionCode
      ) {
        inventoryLocationObj = {
          hasAvailableInventory: false,
          hasSubsetAvailable: false,
          inventoryLocation: null,
        };
      }
      return {
        ...state,
        RegistrationDetail: {
          ..._get(state, 'RegistrationDetail', {}),
          RegistrationAddress: {
            ..._get(state, 'RegistrationDetail.RegistrationAddress', {}),
            StateProvince: action.regionCode,
          },
        },
        ...(app_state?.App?.isTerritorySelectionEnabled
          ? {
              DeliveryDetails: {
                ..._get(state, 'DeliveryDetails', {}),
                StateProvince: action.regionCode,
              },
            }
          : {}),
        ...inventoryLocationObj,
      };

    case COMPLETE_CONFIGURATION_CHANGED: {
      const assetViewProps = getAssetView(state.assetViewSource, action.options, state.assetView);
      const isDomestic = getDomesticSource(state.isDomestic, action.options);
      const isPreSale = getPresaleSource(state.isPreSale, action.options);
      const countryCode = _get(app_state, 'App.countryCode');
      const nonRefundPolicy =
        countryCode === 'CN'
          ? !isPreSale
          : getNonRefundPolicy(state.nonRefundPolicy, action.options);
      return {
        ...state,
        refundPolicySource: {
          ...state.refundPolicySource,
          source: getRefundPolicySource(state.refundPolicySource, action.options),
        },
        isPreSale: {
          ...state.isPreSale,
          source: isPreSale,
        },
        showSecondDepositDisclaimer: {
          ...state.showSecondDepositDisclaimer,
          source: getSecondOrderDeposit(state.showSecondDepositDisclaimer, action.options),
        },
        nonRefundPolicy: {
          ...state.nonRefundPolicy,
          source: nonRefundPolicy,
        },
        displayOrderRefundLongCopy: {
          ...state.displayOrderRefundLongCopy,
          source: getDisplayOrderRefundLongCopy(state.displayOrderRefundLongCopy, action.options),
        },
        isDomestic: {
          ...state.isDomestic,
          source: isDomestic,
        },
        showInfoMessage: {
          ...state.showInfoMessage,
          source: getInfoMessage(state.showInfoMessage, action.options),
        },
        assetView: assetViewProps ? assetViewProps.view : 'STUD_3QTR',
        assetZoom: assetViewProps ? assetViewProps.zoom : 1,
      };
    }
    case TOGGLE_TRIM_SAVINGS_PRICE:
      return {
        ...state,
        showAfterSavingsPriceForTrims: action.flag,
      };
    case UPDATE_VEHICLE_DETAIL:
      return {
        ...state,
        VehicleDetail: action.VehicleDetail,
      };
    case DELIVERY_DETAILS_VALID_FLAG:
      return {
        ...state,
        isDeliveryDetailsValid: action.flag,
        formErrors: {},
      };
    case HAS_AVAILABLE_INVENTORY_FLAG:
      return {
        ...state,
        hasAvailableInventory: action.available,
        hasSubsetAvailable: action.subset,
        inventoryLocation: {
          latitude: action.latitude || '',
          longitude: action.longitude || '',
          postalCode: action.postalCode || '',
          regionCode: action.regionCode || '',
        },
      };
    case DELIVERY_DETAILS_CHANGED:
      return {
        ...state,
        DeliveryDetails: action?.payload?.error ? {
          ...action.payload,
        } : {
          ...state.DeliveryDetails,
          ...action.payload,
          error: null,
        },
      };
    case SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          [action.field]: action.error,
        },
      };
    case CLEAR_FORM_ERRORS:
      return {
        ...state,
        formErrors: {},
      };
    case LEGAL_CONSENT_VALID_FLAG:
      return {
        ...state,
        legalConsentValid: action.flag,
      };
    case PARSE_VEHICLE_UPGRADES:
      return {
        ...state,
        ...action.payload,
      };
    case VIEW_DISCLOSURE:
      return {
        ...state,
        userViewedDisclosures: {
          ...state.userViewedDisclosures,
          ...action.payload,
        },
      };
    case SET_FINANCE_MODAL_DRIVER_TYPE:
      const isReservation = _get(state, 'product.isReservation', false);
      return {
        ...state,
        AccountDetail: {
          ..._get(state, 'AccountDetail'),
          ...(!isReservation
            ? {
                AccountType:
                  action.driverType === REGISTRATION_TYPE_BUSINESS
                    ? REGISTRATION_TYPE_BUSINESS
                    : REGISTRATION_TYPE_PRIVATE,
              }
            : {}),
        },
      };
    case UPDATE_CALLBACK_DETAIL:
      return { ...state, callbackStatus: action.status, data: action.data };
    case INVENTORY_DELIVERY_LOCATIONS_CHANGED: {
      const { isLoading = false, error = null, selected: { city = '' } = {} } =
        action.payload || {};
      return {
        ...state,
        DeliveryLocations: {
          ...state.DeliveryLocations,
          ...action.payload,
          isLoading,
          error,
          errorSalesMetro: null,
          deliveryDisclaimer: getDeliveryDisclaimer(city),
        },
      };
    }
    case INVENTORY_DELIVERY_LOCATION_INVALID:
      const { zipcode, addressLookupError } = action || {};
      return {
        ...state,
        DeliveryLocations: {
          available: null,
          isLoading: false,
          registrationZipCode: zipcode,
          error: !zipcode ? ERROR_INVALID_LOCATION : null,
          errorSalesMetro: true,
          addressLookupError,
        },
      };
    case UPDATE_REQ_CALLBACK_SELECTED_PROVINCE:
      return {
        ...state,
        callbackData: {
          ...state.callbackData,
          location: {
            ...state.callbackData.location,
            selectedProvince: action.payload,
          },
        },
      };
    case UPDATE_REQ_CALLBACK_SELECTED_CITY:
      return {
        ...state,
        callbackData: {
          ...state.callbackData,
          location: {
            ...state.callbackData.location,
            selectedCity: action.payload,
          },
        },
      };
    case UPDATE_REQ_CALLBACK_CITY_LIST:
      return {
        ...state,
        callbackData: {
          ...state.callbackData,
          location: {
            ...state.callbackData.location,
            cityList: action.payload,
          },
        },
      };
    case UPDATE_REQ_CALLBACK_PROVINCE_LIST:
      return {
        ...state,
        callbackData: {
          ...state.callbackData,
          location: {
            ...state.callbackData.location,
            provinceList: action.payload,
          },
        },
      };
    case UPDATE_PROVINCE_ADDRESS:
      return {
        ...state,
        AccountDetail: {
          ...state.AccountDetail,
          companyProvince: action.province,
        },
      };
    case UPDATE_DISTRICT_ADDRESS:
      return {
        ...state,
        AccountDetail: {
          ...state.AccountDetail,
          companyDistrict: action.district,
        },
      };
    case UPDATE_DISTRICT_LIST:
      return {
        ...state,
        availableDistrictsForAddressVerification: action.payload,
      };
    case UPDATE_POSTAL_CODE_LIST:
      return {
        ...state,
        availablePostalCodesForAddressVerification: action.payload,
      };
    case SET_VEHICLE_DESIGN:
      return {
        ...state,
        vehicleDesign: {
          ...state.vehicleDesign,
          ...action.payload,
        },
      };
    case RESET_VEHICLE_DESIGN:
      return {
        ...state,
        vehicleDesign: {
          ...state.vehicleDesign,
          selectedConfig: '',
          earlyVehicleDesigns: [],
          isEarlyDesignSelected: false,
          swapConfig: {},
          pickupLocations: null,
          error: action?.error || null,
          selectedDesign: {},
        },
      };
    case SET_CUSTOM_DESIGN:
      return {
        ...state,
        vehicleDesign: {
          ...state.vehicleDesign,
          isEarlyDesignSelected: false,
        },
      };
    case TOGGLE_DYNAMIC_INCENTIVE:
      return {
        ...state,
        showDynamicIncentive: action.asset || false,
      };
    case SET_TIME_OF_CLICK_PLACE_ORDER_BUTTON:
      return {
        ...state,
        timeOfClickPlaceOrderButton: action.timestamp,
      };
    case SET_TIME_OF_ORDER_PLACED_SUCCESS:
      return {
        ...state,
        timeOfOrderPlacedSuccess: action.timestamp,
      };
    case UPDATE_DELIVERY_CONTACT:
      return {
        ...state,
        DeliveryContact: {
          ...state.DeliveryContact,
          ...action.payload,
        },
      };
    case UPDATE_SELECTED_INVENTORY_CONFIG:
      const isSwap = action?.isSwap;
      let newObj = {};
      if (isSwap) {
        newObj = {
          vehicleDesign: {
            ...state?.vehicleDesign,
            ...(action?.enhancedData || {}),
            selectedDesign: {
              ...state?.vehicleDesign?.selectedDesign,
              configListFull: action?.data,
            },
          }
        };
      } else {
        newObj = {
          product: {
            ...state?.product,
            data: {
              ...state?.product?.data,
              ...(action?.enhancedData || {}),
              updatedConfig: action?.data,
            },
          }
        }
      }
      return {
        ...state,
        ...newObj,
      }
    case SET_HCAPTCHA_TOKEN:
      return {
        ...state,
        hcaptchaToken: action?.token,
      };
    default:
      return state;
  }
}

export default ReviewDetails;
