/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { memo, useEffect, useMemo } from 'react';
import { bool, shape, string, func } from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import cx from 'classnames';

import {
  NAVIGATION_VIEW_PAYMENT,
  COMPONENT_MAIN_GALLERY,
  NAVIGATION_VIEW_OVERVIEW,
  NAVIGATION_VIEW_CONFIRMATION,
  ORDER,
} from 'dictionary';

import GroupSection from '../../components/GroupComponents/GroupSection';
import AssetGallery from '../../components/AssetGallery/ConfiguratorGallery';
import ScrollIndicator from '../../components/ScrollIndicator';
import FederalTax from '../../components/FederalTax';
import {
  getIfHideLexiconGroup,
  getIsUsedPhotosEnabled,
  isEarlyDesignSelected,
} from '../../common/selectors';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { setActiveGroupId } from 'actions';

const ScrollItem = ({ onSelect, children, rootMargin }) => {
  // When container  intersects center 35%   5% of screen, trigger gallery view
  const [isVisible, ref] = useIntersectionObserver({
    rootMargin,
    threshold: 0.1,
  });
  useEffect(() => {
    if (isVisible) {
      onSelect();
    }
  }, [isVisible]);

  return (
    <div
      className={cx('aside-section side-scroll--item')}
      onClick={onSelect} // enhancement, ignore a11y
      ref={ref}
    >
      {children}
    </div>
  );
};

ScrollItem.propTypes = {
  onSelect: func,
  children: shape({}),
  rootMargin: string,
};

ScrollItem.defaultProps = {
  onSelect: () => {},
  children: null,
  rootMargin: null,
};

let activeIdInState = null;
let activeGroupId = null;
let defaultGroupId = null;

const SideScrollContainer = memo(
  ({
    currentNavigationKey,
    groupsMainStringified,
    groupsAsideStringified,
    isDesktop,
    defaultId = 'BATTERY_AND_DRIVE',
    showAssetGallery,
    hideSideScroll,
    isInventory,
    isUsedPhotosEnabled,
    isDm,
    isConfirmationScreen,
    linkTo,
    isCoinReloaded,
    showSinglePage,
    setActiveId,
    activeId,
    countryCode,
    useTopBanner,
    isBannerScrollInMobile,
    isSwap,
    t4bNewNavEnabled,
    t4bAccountId,
    teslaAuthAccessToken,
  }) => {
    const groupsMain = JSON.parse(groupsMainStringified);
    const groupsAside = JSON.parse(groupsAsideStringified);

    activeGroupId = activeId;
    defaultGroupId = defaultId;
    const showScrollDownIndicator = isDesktop && currentNavigationKey !== NAVIGATION_VIEW_PAYMENT;
    const showMobileAssetGallery = isUsedPhotosEnabled && !isDesktop && groupsAside?.length;
    const handleScroll = useMemo(
      () =>
        _debounce(() => {
          if (activeIdInState && activeIdInState !== activeGroupId) {
            return activeIdInState && setActiveId(activeIdInState);
          }
        }, 200),
      []
    );
    const onHashChange = () => {
      if (isCoinReloaded) {
        return;
      }
      if (activeGroupId !== defaultGroupId) {
        setActiveId(defaultGroupId);
      }
    };
    useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('hashchange', onHashChange);
      return () => {
        window.removeEventListener('scroll', handleScroll, { passive: true });
        window.removeEventListener('hashchange', onHashChange);
      };
    }, []);

    useEffect(() => {
      document.body.scrollTop = 0;
    }, [currentNavigationKey]);

    useEffect(() => {
      if (linkTo) {
        const element = document.getElementById(linkTo);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 250);
        }
      }
    }, [linkTo]);

    return (
      <main id="main-content" className={cx('scroll-container', { 'tds-scrim--black': isDm })}>
        <section
          role="none"
          className={cx(
            'cf-layout',
            {
              'cf-layout--desk': isDesktop,
            },
            {
              'option-widget': !isConfirmationScreen,
              'tds--no_horizontal_padding': !isDesktop,
              'tds--no_vertical_padding': showMobileAssetGallery,
              't4b-sidenav-enabled': t4bNewNavEnabled,
            }
          )}
        >
          {/* MOBILE ASSET GALLERY */}
          {showMobileAssetGallery
            ? groupsAside.map(({ component, source, props, key }) => (
                <If condition={component === COMPONENT_MAIN_GALLERY} key={key}>
                  <AssetGallery source={source} {...props} />
                </If>
              ))
            : null}

          <If condition={t4bNewNavEnabled && teslaAuthAccessToken}>
            <div className="t4b-sidenav-wrapper">
              <div data-calling-app="configurator-burst-app" data-public-account-id={t4bAccountId}
                   data-user-token={teslaAuthAccessToken} id="t4b-sidenav"/>
            </div>
          </If>
          <If
            condition={
              ((isCoinReloaded && !showMobileAssetGallery) || isDesktop) &&
              showAssetGallery &&
              groupsAside.length
            }
          >
            <div
              className={cx('cf-asset-wrapper', {
                'hide-side-scroll': hideSideScroll,
                'cf-asset-wrapper--fixed': isCoinReloaded && !isDesktop,
              })}
            >
              {groupsAside.map(groupData => {
                const hideAside = groupData?.excludeGroup && groupData.excludeGroup === activeId;
                const { props = {} } = groupData;
                const groupDataStringified = JSON.stringify(groupData);

                if (hideAside) return null;

                switch (groupData.component) {
                  case COMPONENT_MAIN_GALLERY:
                    return (
                      <AssetGallery
                        groupName={groupData.group}
                        activeGroupName={activeId}
                        gallery={groupData?.gallery}
                        source={groupData?.source}
                        key={groupData.key}
                        id={groupData.key}
                        {...props}
                      />
                    );
                  default:
                    // Enterprise uses this
                    return (
                      <GroupSection
                        group={groupData.group}
                        sectionDataStringified={groupDataStringified}
                        withAsset={!isDesktop}
                        key={groupData.key}
                      />
                    );
                }
              })}
            </div>
          </If>

          <div
            className={cx(
              'group-section',
              { 'option-widget--container': isDesktop && !isConfirmationScreen },
              { 'inventory-group-section': isInventory },
              { 'tds-theme--dk': isDm },
              { 'with-inner-banner': countryCode === 'CN' }
            )}
          >
            <div className="group-container">
              {/* BANNERS */}
              <If
                condition={
                  !useTopBanner &&
                  (isDesktop || (isBannerScrollInMobile && isCoinReloaded)) &&
                  currentNavigationKey === NAVIGATION_VIEW_OVERVIEW
                }
              >
                <FederalTax showTaxValue={false} withoutPadding />
              </If>

              {/* MAIN */}
              <If condition={groupsMain.length}>
                {groupsMain.map(groupData => {
                  const { id, group, sections, filterByContext = [], context = [] } = groupData;
                  let show = sections.includes(currentNavigationKey) || showSinglePage;
                  const newActiveId = group || id;
                  if (isSwap) {
                    show = filterByContext?.includes('swap') || context.includes('swap');
                  } else {
                    show = !context.includes('swap');
                  }

                  return (
                    <If condition={show} key={groupData.key}>
                      <ScrollItem
                        rootMargin={group === ORDER ? '0px 0px 0px 0px' : '-35% 0px -40% 0px'}
                        onSelect={() => {
                          if (activeId !== newActiveId) {
                            activeIdInState = newActiveId;
                          }
                        }}
                        key={id}
                      >
                        <GroupSection
                          group={group}
                          sectionDataStringified={JSON.stringify(groupData)}
                          withAsset={!isDesktop && !isCoinReloaded}
                          isStarting={newActiveId === defaultId}
                          className={`cf-layout ${
                            isDesktop ? 'cf-layout--desk' : 'tds--no_horizontal_padding'
                          }`}
                        />
                      </ScrollItem>
                    </If>
                  );
                })}
              </If>
            </div>

            <If condition={showScrollDownIndicator}>
              <ScrollIndicator />
            </If>
          </div>
        </section>
      </main>
    );
  }
);

SideScrollContainer.displayName = 'SideScrollContainer';

const getCombinedSectionData = (
  data = [],
  currentKey,
  CustomGroups = {},
  isAsset = false,
  showSinglePage = false,
  showPostOrderConfirmation = false,
  hideLexiconGroupAfterOrderGenerated = false,
  isInventory = false
) =>
  JSON.stringify(
    data.reduce((sectionData, item) => {
      const { key: itemKey, component, group, props = {} } = item || {};
      const show = showPostOrderConfirmation
        ? item?.sections?.includes(NAVIGATION_VIEW_CONFIRMATION)
        : item?.sections?.includes(currentKey) ||
          (showSinglePage && !item?.sections?.includes(NAVIGATION_VIEW_CONFIRMATION));
      const groupName = Array.isArray(group) ? group.find(x => CustomGroups[x]) : group;
      const groupData = CustomGroups[groupName];
      if (!show || _isEmpty(item)) {
        return sectionData;
      }
      // Validate Group Data
      if (groupData) {
        const {
          current,
          child_groups,
          options,
          asset,
          hidden: { isHidden = false } = {},
        } = groupData;
        const { isAsset: isAssetFlag = false } = props;
        const isAssetInvalid = (isAssetFlag || isAsset) && !asset;
        const isGroupDataInvalid =
          _isEmpty(current) && !_isEmpty(options) && _isEmpty(child_groups);

        if (isAssetInvalid || isGroupDataInvalid || isHidden) {
          return sectionData;
        }
      }

      if (hideLexiconGroupAfterOrderGenerated) {
        if (component === 'LexiconGroup') {
          return sectionData;
        }
        if (isInventory && component !== 'PaymentSummary') {
          return sectionData;
        }
      }

      return [
        ...sectionData,
        {
          ...item,
          component,
          id: itemKey,
          group: groupName,
        },
      ];
    }, [])
  );

const mapStateToProps = state => {
  const {
    App,
    Navigation,
    CustomGroups,
    FeatureListModal,
    Modal,
    Configuration,
    ReviewDetails,
  } = state;
  const {
    isLayoutMobile,
    isLayoutTablet,
    showAssetGallery,
    isEnterpriseOrder,
    enableCyberpunk,
    isDm,
    isCoinReloaded,
    showSinglePage,
    countryCode,
    useTopBanner,
    showPostOrderConfirmation,
  } = App;
  const isDesktop = !isLayoutMobile && !isLayoutTablet;
  const { layoutsData = {}, section: currentNavigationKey, linkTo, activeGroupId } = Navigation;
  const { rn } = Configuration;
  const { product } = ReviewDetails;
  const { isInventory } = product || {};
  const { main = [], gallery = [] } = layoutsData;
  const isAssetRequired = !enableCyberpunk && isDesktop;
  const isUsedPhotosEnabled = getIsUsedPhotosEnabled(state);
  const hideLexiconGroup = getIfHideLexiconGroup(state);
  const combinedMainData = getCombinedSectionData(
    main,
    currentNavigationKey,
    CustomGroups,
    isAssetRequired,
    showSinglePage,
    showPostOrderConfirmation,
    hideLexiconGroup,
    isInventory
  );
  const combinedAsideData = getCombinedSectionData(gallery, currentNavigationKey, CustomGroups);
  const defaultChild = combinedMainData.length ? combinedMainData[0] : {};
  const { group, id } = defaultChild;

  return {
    groupsMainStringified: combinedMainData,
    groupsAsideStringified: combinedAsideData,
    currentNavigationKey,
    isDesktop,
    isLayoutMobile,
    isLayoutTablet,
    defaultId: group || id,
    isModalOpen: FeatureListModal.isCarouselOpen || Modal.open,
    showAssetGallery: showAssetGallery && !showPostOrderConfirmation,
    hideSideScroll: isEnterpriseOrder && !!rn && currentNavigationKey === NAVIGATION_VIEW_PAYMENT,
    isInventory,
    isUsedPhotosEnabled,
    isDm,
    isConfirmationScreen:
      showPostOrderConfirmation || currentNavigationKey === NAVIGATION_VIEW_CONFIRMATION,
    linkTo,
    isCoinReloaded,
    showSinglePage,
    activeId: activeGroupId,
    countryCode,
    useTopBanner: useTopBanner,
    isBannerScrollInMobile: countryCode === 'CN',
    isSwap: isEarlyDesignSelected(state),
    t4bAccountId: state?.Enterprise?.t4bAccountId,
    t4bNewNavEnabled: state?.Enterprise?.t4bNewNavEnabled,
    teslaAuthAccessToken: state?.Enterprise?.teslaAuthAccessToken,
  };
};

const mapDispatchToProps = dispatch => ({
  setActiveId: groupId => dispatch(setActiveGroupId(groupId)),
});

SideScrollContainer.propTypes = {
  groupsMainStringified: string.isRequired,
  groupsAsideStringified: string.isRequired,
  currentNavigationKey: string.isRequired,
  isDesktop: bool.isRequired,
  isLayoutMobile: bool.isRequired,
  isLayoutTablet: bool.isRequired,
  isModalOpen: bool,
  defaultId: string,
  showAssetGallery: bool.isRequired,
  hideSideScroll: bool,
  isInventory: bool,
  isUsedPhotosEnabled: bool,
  isDm: bool,
  isConfirmationScreen: bool.isRequired,
  isCoinReloaded: bool,
  showSinglePage: bool,
  setActiveId: func.isRequired,
  countryCode: string,
  useTopBanner: bool,
  isBannerScrollInMobile: bool,
};

SideScrollContainer.defaultProps = {
  hideSideScroll: false,
  isInventory: false,
  isDm: false,
  isCoinReloaded: false,
  showSinglePage: false,
  useTopBanner: false,
  isBannerScrollInMobile: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideScrollContainer);
