import React, { useEffect, useState, useRef, useMemo } from 'react';
import { shape, string, bool, func, number, arrayOf, oneOfType } from 'prop-types';
import _reduce from 'lodash/reduce';
import cx from 'classnames';
import { useGesture } from 'react-use-gesture';
import { Icon } from '@tesla/design-system-react';
import { iconChevron90, iconChevron270, iconPopout } from '@tesla/design-system-icons';
import Analytics from 'analytics'

import AssetLoader from '../AssetLoader';
import Dots from './Dots';
import { captureException } from '@sentry/react';

const getBoundScale = scale => {
  const bottom = 1;
  const top = 6;

  if (scale < bottom) {
    return bottom;
  } else if (scale > top) {
    return top;
  }

  return scale;
};

const PreviousButton = ({ onClick, label }) =>
  onClick && (
    <button 
      type="button" 
      className={cx('gallery_control gallery_control__prev')} 
      onClick={onClick} 
      aria-label={label}
    >
      <Icon data={iconChevron270} className="tds-icon-btn tds-icon-btn--small tds-o-icon-btn" />
    </button>
  );

PreviousButton.propTypes = {
  onClick: func.isRequired,
  label: string,
};

PreviousButton.defaultProps = {
  label: '',
};

const NextButton = ({ onClick, label }) =>
  onClick && (
    <button
      type="button"
      className={cx('gallery_control gallery_control__next')}
      onClick={onClick}
      aria-label={label}
    >
      <Icon data={iconChevron90} className="tds-icon-btn tds-icon-btn--small tds-o-icon-btn" />
    </button>
  );

NextButton.propTypes = {
  onClick: func.isRequired,
  label: string,
};

NextButton.defaultProps = {
  label: '',
};

const ExpandButton = ({ onClick, label }) =>
  onClick && (
    <button
      type="button"
      className={cx('gallery_control gallery_control__expand')}
      onClick={onClick}
      aria-label={label}
    >
      <Icon data={iconPopout} className="tds-icon-btn tds-icon-btn--small tds-o-icon-btn" />
    </button>
  );

const AssetGalleryTemplate = ({
  id,
  onPrevious,
  onNext,
  onSelect,
  onRequestDetailedView,
  onCloseGallery,
  currentIndex,
  isActive,
  assets = [],
  isVideoPlaybackEnabeled,
  labels,
  dots = false,
  theme,
  isCyberpunk,
  currentFeatures,
  onFeatureToggle,
  isMobile,
  enableModalView,
  hasScene,
}) => {
  const gallery = useRef();
  const activeAsset = useRef();
  const prevAssetsLength = useRef(assets.length);
  const getVideo = gallery =>
    gallery.current?.querySelector(`.gallery_asset--section.is-active video`) || null;
  const isCropEnabeled = Boolean(onCloseGallery);
  const isRightToLeftLanguage = useMemo(
    () => document?.documentElement?.getAttribute('dir') === 'rtl',
    []
  );
  const defaultCrop = { x: 0, y: 0, scale: 1 };
  const [crop, setCrop] = useState(defaultCrop);
  const lastTapTime = useRef();

  const handleTap = state => {
    if (state.timeStamp - lastTapTime.current <= 350) {
      setCrop({ x: 0, y: 0, scale: 1 });
    }

    lastTapTime.current = state.timeStamp;

    return;
  };

  const snapToEdge = ({ tap }) => {
    if (tap || !isCropEnabeled) return;
    const assetBounds = activeAsset?.current?.getBoundingClientRect();
    const galleryBounds = gallery?.current?.getBoundingClientRect();
    const assetOriginalWidth = activeAsset?.current?.clientWidth;
    const assetOriginalHeight = activeAsset?.current?.clientHeight;
    const widthOverhang = (assetBounds.width - assetOriginalWidth) / 2;
    const heightOverhang = (assetBounds.height - assetOriginalHeight) / 2;
    const boundCrop = { ...crop };

    if (assetBounds.left > galleryBounds.left) {
      // Snap Left
      boundCrop.x = widthOverhang;
    } else if (assetBounds.right < galleryBounds.right) {
      // Snap Right
      boundCrop.x = -(assetBounds.width - galleryBounds.width) + widthOverhang;
    }

    if (assetBounds.top > galleryBounds.top) {
      // Snap top
      boundCrop.y = heightOverhang;
    } else if (assetBounds.bottom < galleryBounds.bottom) {
      // Snap bottom
      boundCrop.y = -(assetBounds.height - galleryBounds.height) + heightOverhang;
    }

    setCrop(boundCrop);
  };

  useGesture(
    {
      onDrag: state => {
        const {
          movement: [movementX, movementY],
        } = state;
        const isSwipe = Math.abs(movementX) > 20 && state.last;
        const isCloseGesture = Boolean(movementY > 40) && state.last;

        if (state.tap) return handleTap(state);

        // We are zoomed
        if (crop.scale !== 1) {
          return setCrop(state => ({
            ...state,
            x: Math.round(movementX),
            y: Math.round(movementY),
          }));
        }

        if (isSwipe) {
          if (movementX > 0) {
            if (isRightToLeftLanguage && onNext) {
              return onNext();
            }
            if (onPrevious) {
              return onPrevious();
            }
          }
          if (movementX < 0) {
            if (isRightToLeftLanguage && onPrevious) {
              return onPrevious();
            }
            if (onNext) {
              return onNext();
            }
          }
        }

        if (isCloseGesture && onCloseGallery) {
          onCloseGallery();
        }
      },
      onDragEnd: snapToEdge,
      onPinchEnd: snapToEdge,
      onPinch: ({ offset: [d] }) => {
        const scale = getBoundScale(1 + d / 80);

        if (isCropEnabeled) {
          setCrop(state => ({ ...state, scale }));
        }
      },
    },
    {
      drag: {
        initial: () => [crop.x, crop.y],
      },
      domTarget: gallery,
    }
  );

  useEffect(() => {
    // Fire analytics event when used photos are available, delay to ensure dataLayer is available
    if (theme === 'used-photos') {
      setTimeout(() => {
        Analytics.fireInteractionEvent('inventory-photos-shown');
      }, 1000)
    }
  }, []);

  // Reset when changing assets
  useEffect(() => {
    // Fire analytics event when each used photo is viewed, delay to ensure dataLayer is available
    if (theme === 'used-photos') {
      setTimeout(() => {
        Analytics.fireInteractionEvent('inventory-photos-view');
      }, 1000)
    }
    setCrop(defaultCrop);
  }, [currentIndex]);

  // When a new asset is selected, start any videos fresh inside the asset
  useEffect(() => {
    const video = getVideo(gallery);
    if (!video) return;

    const onTimeUpdate = () => {
      if (isActive && video.currentTime === video.duration) {
        onNext();
        video.removeEventListener('timeupdate', onTimeUpdate, false);
      }
    };

    if (isActive) {
      video.currentTime = 0;
      var playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise.catch(error => captureException(`MediaPlayException: ${error}`)); // Log failed video play with Sentry
      }
      video.addEventListener('timeupdate', onTimeUpdate, false);
    } else {
      video.removeEventListener('timeupdate', onTimeUpdate, false);
    }

    return () => {
      video.removeEventListener('timeupdate', onTimeUpdate, false);
    };
  }, [currentIndex, isActive]);

  // When asset length changes, revert to beginning
  useEffect(() => {
    if (prevAssetsLength?.current > assets?.length && currentIndex >= assets?.length) {
      onPrevious();
    }
    prevAssetsLength.current = assets.length;
  }, [assets.length]);

  const extraProps = isCyberpunk || hasScene ? {} : { bkba: 2 };
  const assetsMap = _reduce(assets, (res, currentAsset, i) => {
    const asset = (
      <div
        role="presentation"
        className={cx('gallery_asset--section', {
          'is-active': currentIndex === i,
        })}
        key={`${id}__asset__${currentAsset?.view || currentAsset.url}`}
        onClick={enableModalView ? onRequestDetailedView : undefined}
        ref={currentIndex === i ? activeAsset : undefined} // gross
        style={
          currentIndex === i
            ? {
                position: 'relative',
                left: crop.x,
                top: crop.y,
                transform: `scale(${crop.scale})`,
                touchAction: onCloseGallery ? 'none' : 'pan-y',
              }
            : {}
        }
      >
        {/* Preload next assets, or all if interacted with */}
        <If condition={i <= 3 || currentIndex > 0}>
          <>
            <AssetLoader
              className={`group--main-content--asset`}
              asset={{ ...currentAsset, ...extraProps }}
              key={`${id}__asset__${currentAsset?.title}`}
              title={currentAsset?.title}
              playVideo={isVideoPlaybackEnabeled}
            />
            <If condition={currentAsset?.title && !isMobile}>
              <div className="group--main-content--title tds-text--medium tds-text_color--10">
                {currentAsset.title}
              </div>
            </If>
          </>
        </If>
      </div>
    );
    return {
      assets: [...res?.assets, asset],
      map: {
        ...res?.map,
        [i]: 
          currentAsset?.toggle_feature ? (
            <div className="group--main-content--feature-action">
              <button class="tds-btn--custom-feature" onClick={() => onFeatureToggle(currentAsset?.toggle_feature)}>{currentAsset?.toggle_label || 'Toggle'}</button>
            </div>
          ) : null,
      },
    };
  }, { assets: [], map: {} });

  return (
    <div
      className={cx(`gallery ${id}`, {
        'is-active': isActive,
        'gallery__used-photos': theme === 'used-photos',
      })}
      key={id}
      ref={gallery}
    >
      {assetsMap?.assets}

      <ExpandButton onClick={onRequestDetailedView} label={labels?.expand} />
      <If condition={assets?.length > 1}>
        <PreviousButton onClick={onPrevious} label={labels?.previous} />
        <NextButton onClick={onNext} label={labels?.next} />
      </If>

      <If condition={assetsMap?.map?.[currentIndex]}>
        {assetsMap.map[currentIndex]}
      </If>

      <If condition={dots}>
        <Dots list={assets} index={currentIndex} onSelect={onSelect} />
      </If>
    </div>
  );
};

AssetGalleryTemplate.propTypes = {
  id: string,
  onNext: func,
  onPrevious: func,
  currentIndex: number.isRequired,
  isActive: oneOfType([bool, arrayOf(shape({}))]).isRequired,
  assets: arrayOf(shape({})).isRequired,
  isVideoPlaybackEnabeled: bool,
  dots: bool,
  labels: shape({
    next: string,
    previous: string,
    expand: string,
  }),
  onSelect: func,
  onRequestDetailedView: func,
  onCloseGallery: func,
  theme: string,
  isCyberpunk: bool,
  onFeatureToggle: func.isRequired,
  isMobile: bool,
  enableModalView: bool,
  hasScene: bool,
};

AssetGalleryTemplate.defaultProps = {
  isActive: true,
  isVideoPlaybackEnabeled: true,
  key: '',
  onNext: () => {},
  onPrevious: () => {},
  labels: {
    next: 'next',
    previous: 'previous',
    previous: 'expand',
  },
  onSelect: () => {},
  onRequestDetailedView: () => {},
  onCloseGallery: () => {},
  theme: '',
  isCyberpunk: false,
  isMobile: false,
  enableModalView: false,
  hasScene: false,
};

export default AssetGalleryTemplate;
