import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { triggerGenericAction } from 'actions';
import { lowercaseFirstChar } from 'utils';

class ActionTrigger extends Component {
  componentDidMount() {
    const target = this.getTargetElem();
    if (!target.getAttribute('data-action-click')) {
      target.setAttribute('data-action-click', 1);
      target.addEventListener('click', this.eventHandler, false);
      target.addEventListener(
        'keydown',
        e => {
          if (e.key === 'Space' || e.key === 'Enter') {
            this.eventHandler();
          }
        },
        false
      );
    }
  }

  componentWillUnmount() {
    const target = this.getTargetElem();
    if (target.getAttribute('data-action-click')) {
      target.removeAttribute('data-action-click');
      target.removeEventListener('click', this.eventHandler);
      target.removeEventListener('keydown', this.eventHandler);
    }
  }

  eventHandler = e => {
    const { dispatchAction } = this.props;
    const target = e?.target ?? {};
    const action = get(target, 'dataset.actionTrigger');

    // Dynamically extract the "actionProps" attributes
    const actionProps = Object.entries(e.target?.dataset)
      .filter(([key]) => key.startsWith('actionProps'))
      .reduce((props, [key, value]) => {
        const propName = lowercaseFirstChar(key.replace('actionProps', ''));
        props[propName] = value;
        return props;
      }, {});

    if (action) {
      dispatchAction(action, actionProps);
    }
  };

  getTargetElem = () => {
    return document.body;
  };

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

ActionTrigger.propTypes = {
  children: PropTypes.shape({}),
  dispatchAction: PropTypes.func.isRequired,
};

ActionTrigger.defaultProps = {
  children: null,
};

const mapDispatchToProps = {
  dispatchAction: triggerGenericAction
}

export default connect(null, mapDispatchToProps)(ActionTrigger);
