import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _has from 'lodash/has';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _intersection from 'lodash/intersection';
import { formatCurrency, formatNumber } from '@tesla/coin-common-components';
import { Incentives as IncentivesAPI} from '@web/tesla-rest-ds-services';

import {
    PRICE_CHANGED,
    COMPLETE_CONFIGURATION_CHANGED,
    LOCATION_CHANGED,
    OMS_RECEIVED_DEPENDENCIES,
    SCREEN_LAYOUT_CHANGE,
    TOGGLE_FEATURE_STATE,
    UPDATE_BATTERY_GROUP_SPECS,
    COMPONENT_CONTEXT_ENTERPRISE,
    COMPONENT_CONTEXT_CONFIGURATOR,
    COMPONENT_CONTEXT_INVENTORY,
    COMPONENT_STATUS_SHOW,
    COMPONENT_STATUS_HIDE,
    COMPONENT_TYPE_TITLE,
    COMPONENT_TYPE_SAVINGS_TOGGLE,
    PARSE_VEHICLE_UPGRADES,
    COMPONENT_CONTEXT_SEEKRET,
    COMPONENT_CONTEXT_EDITDESIGN_OLD,
    COMPONENT_CONTEXT_RESERVATION_TO_ORDER,
 } from 'dictionary';

import {
    includesCodes, includesAnyCode, AmountCalculator, FormatAmount, getAvailableContextTypes,
} from 'utils';

const contextTypes = [COMPONENT_CONTEXT_ENTERPRISE, COMPONENT_CONTEXT_CONFIGURATOR, COMPONENT_CONTEXT_INVENTORY, COMPONENT_CONTEXT_SEEKRET, COMPONENT_CONTEXT_EDITDESIGN_OLD, COMPONENT_CONTEXT_RESERVATION_TO_ORDER];

export const expandOMSOptionToUtilities = ({ option, consider_no_ui=true, Pricebook={} }) => {
    if (consider_no_ui &&  _get(Pricebook, 'options.' + option + '.pricing.no_ui')) {
        return;
    }
    return {
        selected_by: {and:[option]},
        pricebook_price: `${option}`,
        state_data: {
            asset: `Assets.options.${option}`,
            name:  `OMS.pricebook.options.${option}.pricing.name`,
            disclaimer:  `OMS.pricebook.options.${option}.pricing.disclaimer`,
            price_indicator:  `OMS.pricebook.options.${option}.pricing.price_indicator`,
            long_name:  `OMS.pricebook.options.${option}.pricing.long_name`,
            sub_name:  `OMS.pricebook.options.${option}.pricing.sub_name`,
            description:  `OMS.pricebook.options.${option}.pricing.description`,
            short_description:  `OMS.pricebook.options.${option}.pricing.short_description`
        },
        set_toggle_pair: {
            option: `${option}`,
            pair: null
        },
        code: option,
    }
}

export const expandLexiconOptionToUtilities = ({ option, Lexicon={} }) => {
    return Object.assign({}, _get(Lexicon, `options.${option}`, []), {
        selected_by: {and:[option]},
        cash_price:_get(Lexicon, `options.${option}.price`, []),
        base_price:_get(Lexicon, `options.${option}.price`, []),
        lexicon_price: `${option}`,
        set: [option],
        code: option,
    })
}

export const concatLexiconGroupOptions = ({ group, Lexicon, context = 'default' }) => {
    return _get(Lexicon, `groups_dictionary.${context}.${group}.options`, []);
}

export const partialExpandOMSOptionToUtilites = delta => {
    let utilities = expandOMSOptionToUtilities({ option:delta.option, consider_no_ui: false });
    if (delta.set) {
        utilities = _omit(utilities, 'set_toggle_pair');
    }
    return Object.assign({}, utilities, delta, {
        state_data: Object.assign({}, utilities.state_data, delta.state_data),
    })
}

const checkAgainstContextType = ({ currentContext, type, value }) => {
    if (_isEmpty(value) || !type) {
        return true;
    }
    if (_isArray(value) ? _isEmpty(_intersection(contextTypes, value)) : !contextTypes.includes(value)) {
        return true;
    }
    const normalizedType = type.toLowerCase();
    if (normalizedType === COMPONENT_STATUS_HIDE) {
        return _isArray(value) ? !value.includes(currentContext) :  value !== currentContext;
    } else if (normalizedType === COMPONENT_STATUS_SHOW) {
        return _isArray(value) ? value.includes(currentContext) :  value === currentContext;
    }
    return true;
}

const filterByContext = ({ app_state, item }) => {
    const { App } = app_state;
    const { lexiconComponentContext: currentContext = COMPONENT_CONTEXT_CONFIGURATOR } = App;
    const { hide, show } = item;
    if (!checkAgainstContextType({ currentContext, type: COMPONENT_STATUS_HIDE, value: hide })) {
        return false;
    }
    if (!checkAgainstContextType({ currentContext, type: COMPONENT_STATUS_SHOW, value: show })) {
        return false;
    }
    return true;
};

const getModifiedComponentItem = ({ app_state, item }) => {
    let modifiedItem = {...item};
    const { App } = app_state;
    const { lexiconComponentContext: currentContext = COMPONENT_CONTEXT_CONFIGURATOR } = App;
    if (currentContext === COMPONENT_CONTEXT_ENTERPRISE) {
        const { type: itemType, props: itemProps = {} } = item;
        switch (itemType) {
            case COMPONENT_TYPE_TITLE:
                const { component: itemComponent = 'h2', field = '', classes = '' } = itemProps;
                if (field) {
                    return modifiedItem;
                }
                const titleMap = {
                    h2: 'tds-text--h4',
                    h3: 'tds-text--h5',
                };
                const tagClass = titleMap[itemComponent] || '';
                modifiedItem = {
                    ...modifiedItem,
                    props: {
                        ...itemProps,
                        classes: `${classes} ${tagClass} tds-text--center`,
                    }
                }
                break;
            case COMPONENT_TYPE_SAVINGS_TOGGLE:
                modifiedItem = null;
                break;
            default:
        }
    }
    return modifiedItem;
}

const parseGroupSelectedBy = (group_selected_by = {}, app_state, state) => {
    const { Configuration: { option_codes = []} = {} } = app_state;
    const arr = Object.entries(group_selected_by);
    if (!arr.length) {
      return false;
    }
    for (const [gate, parameter] of arr) {
      switch (gate) {
        case 'and': {
          return parameter.reduce((result, group) => {
              return result && includesCodes(state[group].options, option_codes);
          }, true);
        }
        case 'or': {
          return parameter.reduce((result, group) => {
            return result || includesCodes(state[group].options, option_codes);
          }, false);
        }
        case 'not': {
          return !parameter.reduce((result, group) => {

            return result || includesCodes(state[group].options, option_codes);
          }, false);
        }
        default:
          return true;
      }
    }
}

function onConfiguration({ selected_by, options, location, savedConfigurationOptions, App }) {
    const geoRegion = {
        regionCode: _get(location, 'regionCode', ''),
        countryCode: _get(location, 'countryCode', ''),
    };
    let availableLayouts = [];

    App.isLayoutTablet && availableLayouts.push('tablet')
    App.isLayoutMobile && availableLayouts.push('mobile')
    !App.isLayoutTablet && !App.isLayoutMobile && availableLayouts.push('desktop')

    function inLocation(parameter) {
        const assert = _get(parameter, 'regionCode', []).includes(geoRegion.regionCode) || _get(parameter, 'countryCode', []).includes(geoRegion.countryCode);
        return assert;
    }

    function onDevice(parameter) {
        return !!parameter.some(e=>availableLayouts.includes(e));
    }

    function parseContext(parameter) {
        const contextTypes = getAvailableContextTypes();
        return !!parameter?.some(e => contextTypes.includes(e));
    }
    function onFeature(parameter) {
        return !!parameter.some(e => App?.currentFeatures?.[e] || false);
    }

    const result = _filter(selected_by, (parameters, gate) => {
        switch (gate) {
            case 'not':
                return !_reduce(parameters, (result, parameter) => {
                    if (_isObject(parameter)) {
                        if (parameter.and) {
                            return result || includesCodes(parameter.and, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.or) {
                            return result || includesAnyCode(parameter.or, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.not) {
                            return result || !includesAnyCode(parameter.not, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.on) {
                            return result || onDevice(parameter.on);
                        }
                        if (parameter.state) {
                            return result || onFeature(parameter.state);
                        }
                        if (parameter.in) {
                            return result || inLocation(parameter.in);
                        }
                        if (parameter.notin) {
                            return result || !inLocation(parameter.notin);
                        }
                        if (parameter.context) {
                            return result || parseContext(parameter.context);
                        }
                    }

                    return result || includesCodes([parameter], parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                }, false);
            case 'and':
                return _reduce(parameters, (result, parameter) => {
                    if (_isObject(parameter)) {
                        if (parameter.and) {
                            return result && includesCodes(parameter.and, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.or) {
                            return result && includesAnyCode(parameter.or, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.not) {
                            return result && !includesAnyCode(parameter.not, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.on) {
                            return result && onDevice(parameter.on);
                        }
                        if (parameter.state) {
                            return result && onFeature(parameter.state);
                        }
                        if (parameter.in) {
                            return result && inLocation(parameter.in);
                        }
                        if (parameter.notin) {
                            return result && !inLocation(parameter.notin);
                        }
                        if (parameter.context) {
                            return result && parseContext(parameter.context);
                        }
                    }
                    return result && includesCodes([parameter], parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                }, true);
            case 'or':
                return _reduce(parameters, (result, parameter) => {
                    if (_isObject(parameter)) {
                        if (parameter.and) {
                            return result || includesCodes(parameter.and, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.or) {
                            return result || includesAnyCode(parameter.or, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.not) {
                            return result || !includesAnyCode(parameter.not, parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                        }
                        if (parameter.on) {
                            return result || onDevice(parameter.on);
                        }
                        if (parameter.state) {
                            return result || onFeature(parameter.state);
                        }
                        if (parameter.in) {
                            return result || inLocation(parameter.in);
                        }
                        if (parameter.notin) {
                            return result || !inLocation(parameter.notin);
                        }
                        if (parameter.context) {
                            return result || parseContext(parameter.context);
                        }
                    }
                    return result || includesAnyCode([parameter], parameter.onSavedConfiguration ? savedConfigurationOptions : options);
                }, false);
            default:
                return true;
        }
    })
    return !!result.length;
}

export const applySpecsOverrides = ({ data, configuration, App, location, savedConfigurationOptions }) => {
    const rawData = _get(data, 'rawData', {});
    const overrides = _get(rawData, 'overrides', []);
    if (!overrides.length) {
        // Remap override for range units
        return {
            ...data,
            range_units_override: _get(rawData, _get(rawData, 'range_units_override', ''), _get(data, 'range_units_override', '')),
            accelerationWithoutUnits: rawData?.acceleration ? formatNumber(rawData.acceleration, { precision: 1 }) : '',
        };
    }

    const optRanges = _get(data, 'range');
    const rangeConcat = `${optRanges}`.concat(' ', `${rawData.range_units_label}`);

    if(overrides.length >= 1) {
        const getRanges = overrides.map(i => parseInt(i.content.range));
        var filteredRanges = getRanges.filter(function(x) {
            return x !== undefined && !isNaN(x);
        });
        var minRange = filteredRanges.length === 0 ? rawData.range : Math.min( ...filteredRanges);
        var maxRange = filteredRanges.length > 1 && minRange === rangeConcat ? Math.max( ...filteredRanges) : rawData.range;
        var minMaxValues = `${minRange + ' - ' + maxRange}`.concat(' ', `${rawData.range_units_label}`);
        var rangeValues = minRange != maxRange ? minMaxValues : rangeConcat;
    }

    const overridesData = _find(overrides, override => {
        const selectedBy = _get(override, 'selected_by', {});
        if (_isEmpty(selectedBy)) {
            return false;
        }
        return onConfiguration({
            App,
            selected_by: selectedBy,
            options: configuration,
            location,
            savedConfigurationOptions
        })
    });

    if (_isEmpty(overridesData)) {
        return {
            ...data,
            range_units_override: _get(rawData, _get(rawData, 'range_units_override', ''), _get(data, 'range_units_override', '')),
            minMaxFormatted: rangeValues,
        };
    }
    const specsOverrides = _get(overridesData, 'content', {});
    // Apply units to formatted specs
    const optRange = _get(specsOverrides, 'range', _get(data, 'range'), '');
    const optAcceleration = _get(specsOverrides, 'acceleration', _get(data, 'acceleration'), '');
    const optTopspeed = _get(specsOverrides, 'topspeed', _get(data, 'topspeed'), '');
    const optTowing = data.towingCapacity ? _get(specsOverrides, 'towing_capacity', _get(data, 'towing_capacity'), '') : '';
    const rangeUnitsTarget= _get(specsOverrides, 'range_units_override', _get(rawData, 'range_units_override', ''));
    const rangeUnitsOverride = _get(rawData, rangeUnitsTarget, _get(data, 'range_units_override', ''));

    return {
        ...data,
        range: optRange,
        rangeFormatted: `${optRange}`.concat(' ', `${rawData.range_units_label}`),
        rangeFormattedShort: `${optRange}`.concat(' ', `${rawData.range_units_short}`),
        acceleration: optAcceleration,
        accelerationFormatted: `${optAcceleration}`.concat(' ', `${rawData.speed_units_short}`),
        topspeed: optTopspeed,
        topspeedFormatted: `${optTopspeed}`.concat(' ', `${rawData.top_speed_units_short}`),
        towingCapacity: optTowing,
        towingCapacityFormatted: optTowing ? `${optTowing}`.concat(' ', `${rawData.towing_capacity_short}`) : '',
        range_units_override: rangeUnitsOverride,
        minMaxFormatted: rangeValues
    }
};

const parseSelectedBy = (selected_by, state) => {
    return onConfiguration({
        App: state.App,
        selected_by: selected_by,
        options: state.Configuration.option_codes,
        location: state.Location.components.summaryPanel,
        savedConfigurationOptions: _get(state, 'OMS.saved_config_delta.option_codes', [])
    })
}

const calculateIncentiveValue = (targetOptions, vehiclePrice, fees, incentives, customerType = 'private', financeType = 'cash') => {
    const paramsForIncentive = {
        Fees: fees,
        Incentives: incentives,
        Configuration: {
            options: [...targetOptions],
        },
        PriceTotal: {
            vehiclePrice,
        },
    };
    const CalculationResult = IncentivesAPI.total(paramsForIncentive, {
        customerType,
        financeType,
    });
    return vehiclePrice + (CalculationResult?.total?.includedInPurchasePrice || 0);
};

function utilityConsumer({ utility, app_state, amountCalculator, state }) {
    const { Financial } = app_state;
    const { fms_fees: fees, fms_incentives: incentives } = Financial;
    const pricingChangeConfirmedInfo = app_state?.Configuration?.pricingChangeConfirmed || [];
    const [confirmedRn, targetTrim] = pricingChangeConfirmedInfo;
    const currentRn = app_state?.Configuration?.rn;
    const pricingChangeConfirmed = currentRn === confirmedRn;
    const preservedPricingGroups = app_state?.Configuration?.preservedPricingGroups || [];
    const savedConfig = app_state?.Configuration?.savedConfiguration?.config?.mktOptionCodes || {};
    const optionConfigPrice = pricingChangeConfirmed ? _reduce(savedConfig, (res, opt) => {
        if (!preservedPricingGroups.includes(opt.group)) {
            return res;
        }
        return {
            ...res,
            [opt.code]: opt.price
        };
    }, {}) : {};

    switch(utility.type){
        case 'lexicon_groups':
            return {
                group: utility.data.reduce((result, lexicon_group) => {
                    const Lexicon    = _get(app_state, 'OMS.lexicon');
                    const groupData  = _find(Lexicon.groups, {code: lexicon_group});
                    return [].concat(result,
                        _reduce(_get(groupData, 'options'), (result, option) => {
                            result = [].concat(result,
                                expandLexiconOptionToUtilities({option, Lexicon: app_state.OMS.lexicon})
                                || []);
                            return result;
                        }, [])
                    )
                }, [])
            }

        case 'lexicon_reference':
            const Lexicon    = _get(app_state, 'OMS.lexicon');
            const groupData  = _get(Lexicon, `groups_dictionary.${ utility.data.context|| "default" }.${utility.data.code}`);
            if (!groupData) {
                console.warn('CustomGroups:lexicon_reference:missing', utility.data);
                return {};
            }
            return Object.assign({}, groupData, {
                available: [{
                    group_selected_by: "default",
                    group:  _reduce((() => {
                        const groups = groupData.groups || [];
                        if(groups.length) {
                            return groups.reduce((result,code)=>result = [].concat(result, _get(Lexicon, `groups_dictionary.${ utility.data.context }.${code}.options`, _get(Lexicon, `groups_dictionary.default.${code}.options`))), []);
                        }

                        return groupData.options
                    })(), (result, option) => {
                        result = [].concat(result,
                            expandLexiconOptionToUtilities({option, Lexicon: app_state.OMS.lexicon})
                            || []);
                        return result;
                    }, [])
                }],
            })


        case 'lexicon_options':
            return {
                group: _reduce(utility.data,(result, option) => {
                    result = [].concat(result,
                        expandLexiconOptionToUtilities({option, Lexicon: app_state.OMS.lexicon})
                        || []);
                    return result;
                }, []),
            }

        case 'lexicon_group_source':

            function reduceByGroupName({ context, group_selected_by, code, Lexicon, Utility = {} }) {
                const groupData  = _get(Lexicon, `groups_dictionary.${ context }.${code}`, _get(Lexicon, `groups_dictionary.default.${code}`));
                if (!groupData) {
                    return {};
                }
                let extraGroups = {};
                if (groupData?.groups?.length) {
                    extraGroups = groupData.groups.reduce((result, groupCode) => Object.assign(result, reduceByGroupName({context, code:groupCode, group_selected_by: Utility.find(i => i?.code === groupCode)?.group_selected_by || group_selected_by, Lexicon, Utility })), {});
                }

                return Object.assign({}, extraGroups, {
                        [code]: Object.assign({}, groupData, {
                            available: groupData?.options?.length ? [{
                                group_selected_by: group_selected_by,
                                group: _reduce(groupData.options, (result, option) => {
                                    result = [].concat(result,
                                        expandLexiconOptionToUtilities({option, Lexicon})
                                        || []);
                                    return result;
                                }, [])
                            }] : [],
                            current:[],
                            current_index:-1,
                            child_groups: [].concat(groupData.groups || []),
                            child_options: _reduce(groupData.groups || [], (result, group) => {
                                result = [].concat(result,
                                    concatLexiconGroupOptions({group, Lexicon})
                                    || []);
                                return result;
                            }, []),
                        })
                    }
                )
            }
            return utility.data.reduce((result, groupSpec) => {
                const { context, code } = groupSpec;
                return Object.assign(result, reduceByGroupName({context, code, group_selected_by: _get(groupSpec, 'group_selected_by', 'default'), Lexicon: _get(app_state, 'OMS.lexicon'), Utility: utility.data }));
            },{})

        case 'group_selected_by':
            const groupSelected = utility.data === 'default' ? utility.data : onConfiguration({
                App: app_state.App,
                options:app_state.Configuration.option_codes,
                location: app_state.Location.components.summaryPanel,
                selected_by: utility.data,
                savedConfigurationOptions: _get(app_state, 'OMS.saved_config_delta.option_codes', [])
            })

            return {
                selected: groupSelected,
            }

        case 'selected_by':
            const selected = onConfiguration({
                App: app_state.App,
                selected_by: utility.data,
                options: app_state.Configuration.option_codes,
                location: app_state.Location.components.summaryPanel,
                savedConfigurationOptions: _get(app_state, 'OMS.saved_config_delta.option_codes', [])
            })
            const formattedSelected = _get((() => app_state.Locale['common.ui'] || {})(), `strings.selectOptions.${selected.toString()}`, selected ? 'Option Selected' : 'Select Option');
            return {
                selected,
                formattedSelected,
            }

        case 'lexicon_price':
            if (amountCalculator.err) {
                return {}
            }
            return (() => {
                let displayPrice = '';
                let extraFields = {};
                if (pricingChangeConfirmed && _has(optionConfigPrice, `${utility.data}`)) {
                    let price = Object.values(optionConfigPrice).reduce((last, next) => last + next, 0);
                    price = calculateIncentiveValue(Object.keys(optionConfigPrice), price, fees, incentives);
                    displayPrice = formatCurrency(price);
                    extraFields = {
                        disable_override: {
                            isDisabled: {
                                content: true,
                            },
                        },
                    };
                }
                const calculatedPrice = amountCalculator(utility.data);
                const { price, extraPrice, formattedPrice, formattedCashPrice, formattedBasePrice } = calculatedPrice;
                const translatedPriceIndicator = _get(app_state, `OMS.lexicon.options.${utility.data}.price_indicator`);
                const formattedPriceStr = price ? formattedPrice : translatedPriceIndicator;
                const formattedCashPriceStr = price ? formattedCashPrice : '';
                return {
                    price,
                    extraPrice,
                    formattedPrice: formattedPriceStr,
                    formattedCashPrice: formattedCashPriceStr,
                    formattedBasePrice,
                    displayPrice,
                    ...extraFields,
                }
            })()

        case 'lexicon_specs':
            const specsWithOverrides = applySpecsOverrides({
                data: utility.data || {},
                configuration: app_state.Configuration.option_codes,
                App: app_state.App,
                location: app_state.Location.components.summaryPanel,
                savedConfigurationOptions: _get(app_state, 'OMS.saved_config_delta.option_codes', [])
            })
            return {
                formattedSpecs: specsWithOverrides,
            }

        case 'extra_content':
            const contentTypeCheckForSelectedBy = ['hidden', 'disabled', 'disable_override', 'conditional_pricing_indicator', 'display_pricing_indicator'];
            const componentList = ['components', 'subComponents'];
            return utility.data.reduce((result, schema) => Object.assign(result, schema.type ? {
                        [schema.type]: schema.content.length > 1 || [...componentList, ...contentTypeCheckForSelectedBy].includes(schema.type) ? (() => {
                            const contentData = [];
                            const isComponent = componentList.includes(schema.type);
                            for (const item of schema.content) {
                                if(contentTypeCheckForSelectedBy.includes(schema.type)) {
                                    const { selected_by,  condition_state, configuration, group_selected_by } = item;
                                    const selectedBy = condition_state ? { [condition_state]: configuration } : selected_by;
                                    const result = selectedBy ? parseSelectedBy(selectedBy, app_state) : parseGroupSelectedBy(group_selected_by, app_state, state);
                                    const keyMap = {
                                        hidden: 'isHidden',
                                        conditional_pricing_indicator: 'isModified',
                                        display_pricing_indicator: 'isEnabled',
                                    };
                                    const key = keyMap[schema?.type] || 'isDisabled';
                                    return {
                                        ...item,
                                        [key]: result,
                                    }
                                }
                                else {
                                    const { selected_by } = item;
                                    const checkSelected = selected_by ? parseSelectedBy(selected_by, app_state) : false;
                                    if(!selected_by || checkSelected) {
                                        if (isComponent) {
                                            if (filterByContext({ app_state, item })) {
                                                const modItem = getModifiedComponentItem({ app_state, item});
                                                if (modItem) {
                                                    contentData.push(modItem);
                                                }
                                            }
                                        }
                                        else {
                                            return item;
                                        }
                                    }
                                }
                            }
                            return contentData;
                        })() : schema.content[0]
                    } : {})
                , {});

        case 'custom_car_price':
            const calculatorResult = AmountCalculator(app_state, Object.assign({applyDeltas:true}, utility.data));
            return calculatorResult.err ? (() => {
                const price = utility.data.options.reduce((result, option) => result += amountCalculator(option).price, 0);
                const financeType = app_state.OMS.oms_params.market !== 'US' ? 'cash' : _get(app_state, 'SummaryPanel.selected_tab');
                return {
                    price,
                    formattedPrice: FormatAmount(app_state, option, price, financeType),
                }
            })(): calculatorResult();

        case 'state_data':
            return _reduce(utility.data, (result, target, key)=>{
                const data = _get(app_state, target)
                return Object.assign(result, {
                    [key]: data,
                })
            }, {})

        case 'set_toggle_pair':
            const togglePair = utility.data.pair ? [utility.data.pair] : _get(app_state, `OMS.pricebook.options.${utility.data.option}.rules.toggle_pair`);
            if(togglePair){
                return {
                    set: app_state.Configuration.option_codes.includes(utility.data.option) ? togglePair : [utility.data.option],
                }
            }
            return {
                set: [utility.data.option],
            }
    }
    return {};
}

function updateCustomGroups({ action, app_state, maps, state, amountCalculator }) {
    const { utility_specs = [] } = state;
    utility_specs.forEach(utilitySpec => {
        const { actions = [], type } = utilitySpec;
        if (!actions.includes(action.type)) {
            return;
        }
        maps[type] && maps[type].forEach(utility => {
            const utilityResult = utilityConsumer({ utility, app_state, amountCalculator, state });
            Object.keys(utilityResult).map(utilityResultKey=> _set(state, `${utility.target.slice(1) && utility.target.slice(1) + '.'}${utilityResultKey}`, utilityResult[utilityResultKey]));
        })
    },[]);
    return state;
}

function getMaps(obj) {
    const { utility_specs = [] } = obj;
    const targets = utility_specs.map(spec => spec.type);
    let maps = {};
    const walked = new Set();
    function iterate(obj, stack = '') {
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (targets.includes(property)) {
                    maps[property] = [].concat(maps[property] || [], [{ data: obj[property], target: stack, type: property }]);
                }
                if (typeof obj[property] === 'object' && !walked.has(obj[property])) {
                    walked.add(obj[property]);
                    iterate(obj[property], `${stack}${!isNaN(property)? `[${property}]` : `.${property}`}`);
                }
            }
        }
    }
    iterate(obj);
    return maps;
}

function CustomGroups(state = {}, action, { app_state }) {
    switch (action.type) {
        case OMS_RECEIVED_DEPENDENCIES:
             if (_isEmpty(state?.maps)) {
                const firstPassState = updateCustomGroups({ action, app_state, maps: getMaps(state), state: _cloneDeep(state) });
                return Object.assign({}, firstPassState, { maps: getMaps(firstPassState) })
            }
        case PARSE_VEHICLE_UPGRADES:
            const upgrades = _reduce(action?.payload?.vehicleUpgrades || [], (res, upgrade) => {
                const upgradesOpts = _reduce(upgrade?.current || [], (data, opt) => {
                    return {
                        ...data,
                        [opt.code]: opt,
                    }
                }, res);
                return {
                    ...res,
                    ...upgradesOpts,
                };
            }, {});
            return { ...state, upgrades };
        case COMPLETE_CONFIGURATION_CHANGED:
        case SCREEN_LAYOUT_CHANGE:
        case PRICE_CHANGED:
        case LOCATION_CHANGED:
        case TOGGLE_FEATURE_STATE:
            const amountCalculator = AmountCalculator(app_state)
            return _reduce(updateCustomGroups({action, app_state, maps: state.maps, state: _cloneDeep(state), amountCalculator}), (result, groupData, key) => {
                    const { available = [], selected = true, code: groupCode } = groupData;
                    if (['groups_to_render','utility_specs', 'maps', 'upgrades'].includes(key)) {
                        return Object.assign(result, {[key]: groupData})
                    }

                    let currentIndex = _findIndex(available, model => {
                        return model.selected
                    })

                    currentIndex = currentIndex === -1 ? _findIndex(available, model => {
                        return model.group_selected_by === 'default'
                    }) : currentIndex;

                    let newGroupData;
                    if (currentIndex > -1) {
                        let selected = false;
                        const groupAmount = available[currentIndex].group.reduce((result,option) => {
                            if(option.cash_price && !isNaN(option.cash_price) && option.selected){
                                result+=option.cash_price
                            }
                            selected = option.selected || selected
                            return result
                        }, 0);
                        const currentSelected = available[currentIndex].group.reduce((res, option) => {
                            if (option.selected) {
                                const upgradeSource = result.upgrades[option.code];
                                const optSource = upgradeSource || option;
                                res.push(optSource);
                            }
                            return res;
                        }, []);
                        const totalAvailable = available[currentIndex].group.filter(option => !_get(option, 'disable_override.isDisabled'));
                        newGroupData = Object.assign({}, groupData, {
                            current_index: currentIndex,
                            current: available[currentIndex].group,
                            currentSelected,
                            totalAvailable,
                            selected,
                            // TODO: move to lexicon as a group type identifier
                            type: _get(currentSelected, '[0].shape.type', 'block'),
                        }, groupAmount ? amountCalculator('null', groupAmount) : { formattedPrice: available[currentIndex].group.price_indicator })
                    } else {
                        newGroupData = Object.assign({}, groupData, {
                            current_index: -1,
                            current: [],
                            formattedPrice: '',
                            currentSelected: [],
                            totalAvailable: [],
                            selected: false,
                        })
                    }
                    return Object.assign(result, {[key]: newGroupData});
                }, { upgrades: state?.upgrades || {} });
            // Retain for backwards compatibility
            case UPDATE_BATTERY_GROUP_SPECS:
                const batterySpecs = _get(app_state, 'OMS.lexicon.battery_group_specs', {});
                return {
                    ...state,
                    battery_group_specs: {
                        ...state.battery_group_specs,
                        options: batterySpecs,
                    },
                }
        default:
        return state;
    }
}

export default CustomGroups;
