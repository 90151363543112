import React from 'react';
import { string, shape, bool } from 'prop-types';
import classnames from 'classnames';
import TextLoader from '../TextLoader';
import { connect } from 'react-redux';
import { parseConditionField } from '../../common/utils';

/* Group disclaimer component */
const Disclaimer = ({ group, field, filteredField, component, classes, isCN }) => {
  const targetField = isCN ? filteredField : field;
  if (!group || !group?.[targetField]) {
    return null;
  }
  const { code } = group;
  return (
    <TextLoader
      data={group}
      tag={{ component }}
      field={targetField}
      key={`group-disclaimer-${code}-${targetField}`}
      className={classnames(
        `tds-text--caption tds-o--maxinline--none text-loader--${targetField}`,
        {
          [classes]: classes,
        }
      )}
    />
  );
};

function mapStateToProps(state, ownProps) {
  const { group, field } = ownProps;
  const {
    App: { countryCode = '' },
  } = state;
  const filteredField = parseConditionField(state, group, field, { fieldReturn: true });
  return {
    filteredField,
    isCN: countryCode === 'CN',
  };
}

Disclaimer.propTypes = {
  group: shape({}).isRequired,
  field: string,
  filteredField: string,
  isCN: bool,
  component: string,
  classes: string,
};

Disclaimer.defaultProps = {
  field: 'disclaimer',
  filteredField: 'disclaimer',
  isCN: false,
  component: 'p',
  classes: '',
};

export default connect(mapStateToProps)(Disclaimer);
