import React, { useEffect } from 'react';
import { Icon } from '@tesla/design-system-react';
import { iconCheck, iconCompany, iconInfo } from '@tesla/design-system-icons';
import { i18n, htmlToReact } from 'utils';
import Analytics from 'analytics';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import { formatCurrency } from '@tesla/coin-common-components';
import {
  ANALYTICS_ORDER_PAGE,
  ANALYTICS_PAYMENT_CTA,
  ANALYTICS_SHOW_IRA_QUALIFICATION_COPY,
  NAVIGATION_VIEW_OVERVIEW,
  FINANCE_CAROUSEL,
  FINANCE_MODAL_TAB_INCENTIVES,
  SHOW_BANNER_MODAL,
  MODAL_OPEN,
  MODAL_MEDIUM,
  MODAL_SMALL,
  FINANCIAL_MODAL_STANDALONE,
} from 'dictionary';
import {
  getTaxCredit,
  getIncentiveEligibilityDisclaimerForDesign,
  getUsedTaxCreditEligible,
} from 'selectors';
import ModalTrigger from '../ModalTrigger';

const IRAIncentiveCopy = ({
  currentNavigationKey,
  showDesignEligibility,
  showUsedIraEligibility,
  usedTaxCreditEligible,
  eligibilityDisclaimer,
  locale,
  taxCredit,
  showIRABusinessEligibility,
  hideIcon,
  className = '',
  linkText = '',
  isLayoutMobile,
  isLayoutTablet,
  showSavingsAfterTaxCredit,
  openBannerModal,
  isCoinReloaded,
}) => {
  useEffect(() => {
    if (showDesignEligibility && currentNavigationKey === NAVIGATION_VIEW_OVERVIEW) {
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: ANALYTICS_SHOW_IRA_QUALIFICATION_COPY,
        'cfg-type': Analytics.cfgType,
      });
    }
  }, [currentNavigationKey]);

  if (!showDesignEligibility && !showIRABusinessEligibility && !showUsedIraEligibility) {
    return null;
  }

  if (taxCredit && showSavingsAfterTaxCredit) {
    return (
      <p className="tds-text--caption ira-incentive--aftersavings tds--padding_top-8">
        <sup>*</sup>
        <a className="tds-link" onClick={() => openBannerModal({ contentSource: 'taxCredit' })}>
          {i18n('Incentives.federalIncentive.learnMore')}
        </a>
        &nbsp;
        <span>
          {i18n('Incentives.federalIncentive.disclaimer', {
            AMOUNT: taxCredit,
          })}
        </span>
      </p>
    );
  }

  const handleClick = () =>{
    openBannerModal({ contentSource: 'taxCredit' });
    Analytics.fireInteractionEvent('see-eligibility-requirements')
  }

  return (
    <div className={cx('ira-incentive', `${className}`)}>
      <Choose>
        <When condition={showIRABusinessEligibility}>
          {!hideIcon && <Icon data={iconCompany} />}
          <div className={cx({ 'tds--horizontal_padding--small': !hideIcon })}>
            <span>{i18n('Incentives.ira.business.disclaimer')}</span>
            <ModalTrigger
              type={isCoinReloaded ? MODAL_OPEN : FINANCE_CAROUSEL}
              selectedView={isCoinReloaded ? '' : FINANCE_MODAL_TAB_INCENTIVES}
              options={{
                props: {
                  componentName: isCoinReloaded ? FINANCIAL_MODAL_STANDALONE : '',
                  props: {
                    genericWrapper: true,
                    size: isCoinReloaded ? MODAL_MEDIUM : MODAL_SMALL,
                    isMega: isCoinReloaded,
                    containerCss: cx('tds-display--inline tds-link', {
                      'tds-o-margin_left--1x': isLayoutMobile || isLayoutTablet,
                    }),
                    selectedToggle: 'business',
                  },
                },
              }}
            >
              {linkText || i18n('common.seeDetails')}
            </ModalTrigger>
          </div>
        </When>
        <When condition={eligibilityDisclaimer}>
          <Icon data={iconCheck} className="ira-incentive-success-icon" />
          <div>
            <p>{htmlToReact(eligibilityDisclaimer)}</p>
            <a
              className="tds-link"
              target="_blank"
              id={'learnMore'}
              tabIndex={0}
              href={`/${locale}/support/incentives?redirect=no`}
              rel="noopener noreferrer"
            >
              {i18n('common.learn_more')}
            </a>
          </div>
        </When>
        <When condition={showUsedIraEligibility && !usedTaxCreditEligible}>
          <Icon data={iconInfo} className="ira-incentive-info-icon" />
          <div>
            <span>{i18n('Incentives.iraIncentiveNoLongerQualifies')}</span>
            &nbsp;
            <a
              className="tds-link"
              onClick={handleClick}
            >
              {i18n('common.viewDetails')}
            </a>
          </div>
        </When>
        <Otherwise>
          <Icon data={iconCheck} className="ira-incentive-success-icon" />
          <div>
            <p>{i18n(showUsedIraEligibility ? 'Incentives.iraIncentive__used' : 'Incentives.iraIncentive', { FEDERAL_TAX_CREDIT_AMOUNT: taxCredit })}</p>
            <ModalTrigger
              type={isCoinReloaded ? MODAL_OPEN : FINANCE_CAROUSEL}
              selectedView={isCoinReloaded ? '' : FINANCE_MODAL_TAB_INCENTIVES}
              options={{
                props: {
                  componentName: isCoinReloaded ? FINANCIAL_MODAL_STANDALONE : '',
                  props: {
                    genericWrapper: true,
                    size: isCoinReloaded ? MODAL_MEDIUM : MODAL_SMALL,
                    isMega: isCoinReloaded,
                    containerCss: 'tds-display--inline tds-link',
                    bannerType: 'ira_modal',
                    source:
                      currentNavigationKey === NAVIGATION_VIEW_OVERVIEW
                        ? ANALYTICS_PAYMENT_CTA
                        : ANALYTICS_ORDER_PAGE,
                  },
                },
              }}
            >
              {i18n(showUsedIraEligibility ? 'common.viewDetails' : 'common.seeDetails')}
            </ModalTrigger>
          </div>
        </Otherwise>
      </Choose>
    </div>
  );
};

IRAIncentiveCopy.propTypes = {
  currentNavigationKey: string.isRequired,
  showDesignEligibility: bool.isRequired,
  usedTaxCreditEligible: bool.isRequired,
  eligibilityDisclaimer: string,
  locale: string,
  taxCredit: string,
  hideIcon: bool,
  className: string,
  linkText: string,
  isLayoutMobile: bool,
  isLayoutTablet: bool,
  isCoinReloaded: bool,
};

IRAIncentiveCopy.defaultProps = {
  eligibilityDisclaimer: '',
  locale: '',
  taxCredit: '',
  hideIcon: false,
  className: '',
  linkText: '',
  isLayoutMobile: false,
  isLayoutTablet: false,
  isCoinReloaded: false,
};

const mapStateToProps = state => {
  const { Navigation, App } = state;
  const { section: currentNavigationKey } = Navigation;
  const { locale, isLayoutMobile, isLayoutTablet, isCoinReloaded } = App;
  const eligibilityDisclaimer = getIncentiveEligibilityDisclaimerForDesign(state);
  const taxCredit = getTaxCredit(state);
  const usedTaxCreditEligible = getUsedTaxCreditEligible(state);

  return {
    currentNavigationKey,
    showDesignEligibility: !!taxCredit || !!eligibilityDisclaimer,
    taxCredit: taxCredit ? formatCurrency(Math.abs(taxCredit)) : '',
    usedTaxCreditEligible,
    eligibilityDisclaimer,
    locale,
    isLayoutMobile,
    isLayoutTablet,
    isCoinReloaded,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openBannerModal: props => {
      dispatch({
        type: SHOW_BANNER_MODAL,
        props,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IRAIncentiveCopy);
