/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MODAL_SMALL, FINANCE_MODAL_TAB_OPTIONS, FINANCE_CAROUSEL, PRICING_SAVINGS_CALCULATIONS } from 'dictionary';
import _get from 'lodash/get';
import { htmlToReact, i18n, isPriceAcceptance } from 'utils';
import { connect } from 'react-redux';
import ModalTrigger from '../../components/ModalTrigger';
import Incentives from './Incentives';
import { incentiveTypeProps } from './propTypes';
import { formatCurrency, formatPercent } from '@tesla/coin-common-components';
import { WEB_CONFIGURATOR_PAYMENT_GAS_SAVING_CALCULATION } from "../../common/gioStatistics";

const PricingDetails = ({
  incentives,
  incentivesTotal,
  incentiveTotalInPurchasePrice,
  hidePurchasePriceIncentive,
  model,
  fuelYears,
  destinationAndDocFee,
  modelCode,
  hideAfterSavingsPrice,
  cashPrice,
  cashPriceExVAT,
  registrationTax,
  governmentIncentivesTotal,
  governmentIncentivesPeriod,
  hideAfterSavingsModal,
  showPrpLink,
  showRegionalTaxTypes,
  vehiclePriceInclFees,
  showDistinctTaxTotal,
  regionalTaxValues,
  showDriveAwayPrice,
  showSavingsDisclaimer,
  OMVICFee,
  deliveryFee,
  gstSalePrice,
  onRoadfeesTotal,
  showIncentivesSectionTitle,
  showOtherFeesinSummary,
  tabIndex,
  showOrderPageSummaryOtherFees,
  cashTabIncentives,
  showVATBreakdown,
  vatAmount,
  showGrossPriceTotal,
  grossPrice,
  netPrice,
  triggerIncentiveSavingsModal,
  showVatDisclaimer,
  showGovernmentSubsidy,
  totalSavings,
  fuelReward,
  hideSavingsAfterPurchaseDiscl,
  vesRebate,
  additionalRegistrationFee,
  eeai,
  netArfPayable,
  roadTax,
  sctTax,
  showProvincialIncentives,
  registrationType,
  evCredit,
  hideIncentivesOrderPage,
  showVehiclePricePlusFees,
  regionalFeeRebate,
  feeLineItems,
  showVehicleSubtotal,
  showTaxAndFeesDisclaimer,
  extraPricingContextAmounts,
  displayDoubleCurrency,
  vehicleCondition,
  isNewInventory,
  discount,
  isInventoryPriceAdjustmentsEnabled,
  priceExcludingCoE,
  vehiclePrice,
  showVehicleConfigurationPrice,
  locale,
  showVehiclePriceWithoutSavings,
  vehiclePriceWithoutDiscounts,
  regionalDisclaimer,
  hasRegionalBannerModalCopy,
  showIRABusinessEligibility,
  trimCode,
  enableCyberpunk,
  region,
}) => {
  const getPurchasePriceLabel = ({
    showDriveAwayPrice,
    showVehiclePricePlusFees,
    showVATBreakdown,
  }) => {
    if (showDriveAwayPrice) {
      return 'FinancingOptions.drive_away_price';
    }
    if (showVehiclePricePlusFees) {
      if (isInventoryPriceAdjustmentsEnabled) {
        return 'Enterprise.orderSummary.subtotal'
      } else {
        return 'Review.vehicle_price';
      }
    }
    if (showVATBreakdown) {
      return 'Review.purchase_price_ex_vat';
    }

    if (showVehicleSubtotal) {
      return 'Review.subtotal_vehicle';
    }

    return 'Review.purchase_price';
  };

  const purchasePriceLabel = getPurchasePriceLabel({
    showDriveAwayPrice,
    showVehiclePricePlusFees,
    showVATBreakdown,
  });

  let { beforeSavingsPrice } = cashPrice;
  if (showVATBreakdown) {
    beforeSavingsPrice = _get(cashPriceExVAT, 'beforeSavingsPrice');
  }

  if (showGrossPriceTotal) {
    beforeSavingsPrice = formatCurrency(grossPrice);
    cashPrice.afterSavingsPrice = formatCurrency(netPrice);
  }

  return (
    <>
      <If condition={showVehicleConfigurationPrice && !!incentiveTotalInPurchasePrice}>
        <div className="review-page--block review-page--summary-regional-fees">
          <ul className="review-page--totals-list tds-list">
            <li className="tds-list-item configuration-price">
              <span className="left tds-text--medium tds-text_color--10 coin-vehicle--price">
                {i18n('Review.vehicle_configuration_price')}
              </span>
              <span className="tds-text--end tds-text--medium tds-text_color--10">
                {vehiclePrice}
              </span>
            </li>
            <li className="tds-list-item ev-subsidy">
              <span className="left tds-text--regular tds-text_color--10">
                {i18n('SummaryPanel.disclaimers.governmentIncentivesTotal')}
              </span>
              <span className="tds-text--end tds-text--regular tds-text_color--10">
                {formatCurrency(-1 * Math.abs(incentiveTotalInPurchasePrice))}
              </span>
            </li>
          </ul>
        </div>
      </If>
      {showDistinctTaxTotal && (
        <div className="review-page--total-section review-page--line-items border review-page--sub-total">
          <ul className="review-page--totals-list tds-list">
            <li className="tds-text--medium tds-text_color--10 review-page--line-item tds-list-item">
              <span className="label left">{i18n('FinancingOptions.vehicle_subTotal')}</span>
              <span className="value">{formatCurrency(vehiclePriceInclFees)}</span>
            </li>
            {(gstSalePrice || showVatDisclaimer) && (
              <span className="items price review-page--disclaimer tds-text--caption">
                {i18n(
                  'SummaryPanel.disclaimers.vehicle_GST',
                  {
                    GST: formatCurrency(gstSalePrice),
                    VAT_AMOUNT: formatCurrency(vatAmount),
                  },
                  null,
                  {
                    specificOverride: [vehicleCondition],
                    returnNullWhenEmpty: true,
                    specificOverrideOperator: 'OR',
                  }
                )}
              </span>
            )}
          </ul>
        </div>
      )}
      <If condition={showRegionalTaxTypes}>
        <div className="review-page--block">
          <div className="review-page--line-items">
            <ul className="review-page--line-items-list tds-list">
              <If condition={showRegionalTaxTypes?.includes('luxury_cost_tax')}>
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('FinancingOptions.luxury_cost_tax')}</span>
                  <span className="value">
                    {formatCurrency(regionalTaxValues?.luxury_cost_tax)}
                  </span>
                </li>
              </If>
              <If condition={showRegionalTaxTypes?.includes('local_stamp_duty')}>
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('FinancingOptions.local_stamp_duty')}</span>
                  <span className="value">
                    {formatCurrency(regionalTaxValues?.local_stamp_duty)}
                  </span>
                </li>
              </If>
              <If
                condition={
                  showRegionalTaxTypes?.includes('local_reg_costs') &&
                  regionalTaxValues?.local_reg_costs
                }
              >
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('FinancingOptions.local_reg_costs')}</span>
                  <span className="value">{formatCurrency(regionalTaxValues.local_reg_costs)}</span>
                </li>
              </If>
              <If
                condition={
                  showRegionalTaxTypes?.includes('local_ctp') && regionalTaxValues?.local_ctp
                }
              >
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('FinancingOptions.local_ctp')}</span>
                  <span className="value">{formatCurrency(regionalTaxValues.local_ctp)}</span>
                </li>
              </If>
              <If
                condition={
                  showRegionalTaxTypes?.includes('plate_fee') && regionalTaxValues?.plate_fee
                }
              >
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('FinancingOptions.plate_fee')}</span>
                  <span className="value">{formatCurrency(regionalTaxValues.plate_fee)}</span>
                </li>
              </If>
              <If condition={showRegionalTaxTypes?.includes('delivery_fee') && deliveryFee > 0}>
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('FinancingOptions.delivery_fee')}</span>
                  <span className="value">{formatCurrency(deliveryFee)}</span>
                </li>
              </If>
              {/* Show summary page line items for SG */}
              <If
                condition={
                  showRegionalTaxTypes?.includes('additional_registration_fee') &&
                  additionalRegistrationFee > 0
                }
              >
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">
                    {i18n('SummaryPanel.disclaimers.additionalRegistrationFee')}
                  </span>
                  <span className="value">{formatCurrency(additionalRegistrationFee)}</span>
                </li>
              </If>
              <If condition={showRegionalTaxTypes?.includes('ves_rebate') && vesRebate < 0}>
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">
                    {i18n('SummaryPanel.disclaimers.vesRebate', null, null, {
                      specificOverride: [trimCode],
                      returnNullWhenEmpty: true,
                      specificOverrideOperator: 'OR',
                    })}
                  </span>
                  <span className="value">{formatCurrency(vesRebate)}</span>
                </li>
              </If>
              <If condition={showRegionalTaxTypes?.includes('eeai') && eeai < 0}>
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('SummaryPanel.disclaimers.eeai')}</span>
                  <span className="value">{formatCurrency(eeai)}</span>
                </li>
              </If>
              <If
                condition={showRegionalTaxTypes?.includes('net_arf_payable') && netArfPayable > 0}
              >
                <li className="review-page--line-item tds-list-item tds-list-item--border-bottom">
                  <span className="label left">
                    {i18n('SummaryPanel.disclaimers.netArfPayable')}
                  </span>
                  <span className="value">{formatCurrency(netArfPayable)}</span>
                </li>
              </If>
              <If
                condition={
                  showRegionalTaxTypes?.includes('registration_fee') && registrationTax > 0
                }
              >
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">
                    {i18n('SummaryPanel.disclaimers.registrationTax')}
                  </span>
                  <span className="value">{formatCurrency(registrationTax)}</span>
                </li>
              </If>
              <If condition={showRegionalTaxTypes?.includes('road_tax') && roadTax > 0}>
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('SummaryPanel.disclaimers.roadTax')}</span>
                  <span className="value">{formatCurrency(roadTax)}</span>
                </li>
              </If>
              <If
                condition={
                  showRegionalTaxTypes?.includes('governmentIncentive') &&
                  governmentIncentivesPeriod !== 'included' &&
                  Math.abs(governmentIncentivesTotal) > 0
                }
              >
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">
                    {i18n('FinancingOptions.governmentIncentive', null, null, {
                      specificOverride: [registrationType, modelCode],
                      specificOverrideOperator: 'OR',
                    })}
                  </span>
                  <span className="value">
                    {formatCurrency(-1 * Math.abs(governmentIncentivesTotal))}
                  </span>
                </li>
              </If>
              <If
                condition={incentives.sellerDiscount && incentives.sellerDiscount[0].amount !== 0}
              >
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">{i18n('FinancingOptions.sellerDiscount')}</span>
                  <span className="value">
                    {formatCurrency(incentives.sellerDiscount[0].amount)}
                  </span>
                </li>
              </If>
              <If condition={showOtherFeesinSummary}>
                <If condition={destinationAndDocFee > 0 && !showDistinctTaxTotal}>
                  <li className="review-page--line-item tds-list-item">
                    <span className="label left">{i18n('FinancingOptions.destinationDocfee')}</span>
                    <span className="value">{formatCurrency(destinationAndDocFee)}</span>
                  </li>
                </If>
                <If condition={showRegionalTaxTypes?.includes('omvic_fee') && OMVICFee > 0}>
                  <li className="review-page--line-item tds-list-item">
                    <span className="label left">
                      {i18n('SummaryPanel.disclaimers.specialFee')}
                    </span>
                    <span className="value">{formatCurrency(OMVICFee)}</span>
                  </li>
                </If>
              </If>
              {showDistinctTaxTotal && onRoadfeesTotal && (
                <div className="tds-text--medium tds-text_color--10 review-page--total-section review-page--line-items">
                  <ul className="review-page--totals-list tds-list">
                    <li className="review-page--line-item tds-list-item">
                      <span className="label left">
                        {i18n('FinancingOptions.vehicle_subTotalInclFees')}
                      </span>
                      <span className="value">{formatCurrency(onRoadfeesTotal)}</span>
                    </li>
                  </ul>
                </div>
              )}
            </ul>
          </div>
        </div>
      </If>

      <If condition={isInventoryPriceAdjustmentsEnabled && discount}>
        <div
          className={classnames('review-page--block review-page--summary-regional-fees', {
            'vertical_margin-top--12': locale === 'zh_CN',
          })}
        >
          <ul className="review-page--totals-list tds-list">
            <li className="review-page--line-item tds-list-item">
              <span className="label left">{i18n('Review.price_adjustment_label')}</span>
              <span className="value">{formatCurrency(-discount)}</span>
            </li>
          </ul>
        </div>
      </If>
      <div
        className={classnames('review-page--total-section review-page--line-items border', {
          'tds-o-margin_bottom-16': locale !== 'zh_CN',
          'vertical_margin-top--12': !(
            showVehicleConfigurationPrice && !!incentiveTotalInPurchasePrice
          ),
        })}
      >
        <ul className="review-page--totals-list tds-list">
          <If condition={cashTabIncentives?.veteranCredit}>
            <li className="review-page--line-item tds-list-item">
              <span className="label left">{i18n('Review.veteranCredit')}</span>
              <span className="value">{formatCurrency(cashTabIncentives?.veteranCredit)}</span>
            </li>
          </If>
          <li className="review-page--line-item tds-list-item">
            <span
              className={classnames(
                { 'tds-text--medium tds-o-text_color--20': !enableCyberpunk },
                'label left'
              )}
            >
              <span className="tds-display--block">
                {i18n(purchasePriceLabel, null, null, {
                  specificOverride: ['subtotal', vehicleCondition],
                  returnNullWhenEmpty: true,
                  specificOverrideOperator: 'OR',
                })}
              </span>
              <span className="tds-text--regular tds-text--caption">
                {i18n('SummaryPanel.disclaimers.licenseDisclaimer', null, null, {
                  returnNullWhenEmpty: true,
                })}
              </span>
            </span>
            <span
              className={classnames(
                { 'tds-text--medium tds-o-text_color--20': !enableCyberpunk },
                'value'
              )}
            >
              <Choose>
                <When condition={showVehiclePriceWithoutSavings}>
                  {formatCurrency(vehiclePriceWithoutDiscounts)}
                </When>
                <Otherwise>{priceExcludingCoE || beforeSavingsPrice}</Otherwise>
              </Choose>
              <If condition={displayDoubleCurrency}>
                {extraPricingContextAmounts.map(extraPricingContextAmount => (
                  <span
                    className="tds-text--end double-currency"
                    key={`extraContextPriceBeforeSavings_${extraPricingContextAmount.currency}`}
                  >
                    {formatCurrency(extraPricingContextAmount.subTotal, {
                      currency: extraPricingContextAmount.currency,
                      useDynamicRounding: true,
                    })}
                  </span>
                ))}
              </If>
            </span>
          </li>
        </ul>
      </div>

      <If condition={showVATBreakdown}>
        <div className="review-page--block">
          <div className="review-page--line-items">
            <ul className="review-page--line-items-list tds-list">
              <If condition={sctTax.total}>
                <li className="review-page--line-item tds-list-item">
                  <span className="label left">
                    {i18n('SummaryPanel.disclaimers.sctTax', {
                      PERCENT: formatPercent(sctTax.data?.[0]?.percent ?? 0, 0),
                    })}
                  </span>
                  <span className="value">{formatCurrency(sctTax.total)}</span>
                </li>
              </If>
              <li className="review-page--line-item tds-list-item">
                <span className="label left">{i18n('Review.vat_amount')}</span>
                <span className="value">{formatCurrency(vatAmount)}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="review-page--total-section review-page--line-items border">
          <ul className="review-page--totals-list tds-list">
            <li className="review-page--line-item tds-list-item">
              <span className="label left">{i18n('Review.purchase_price_incl_vat')}</span>
              <span className="value">{cashPrice.beforeSavingsPrice}</span>
            </li>
          </ul>
        </div>
      </If>

      <If condition={!hideIncentivesOrderPage && !isPriceAcceptance()}>
        <Incentives
          incentives={incentives}
          incentivesTotal={incentivesTotal}
          fuelYears={fuelYears}
          showIncentivesSectionTitle={showIncentivesSectionTitle}
          showOrderPageSummaryOtherFees={showOrderPageSummaryOtherFees}
          cashTabIncentives={cashTabIncentives}
          showGovernmentSubsidy={showGovernmentSubsidy}
          governmentIncentivesTotal={governmentIncentivesTotal}
          totalSavings={totalSavings}
          fuelReward={fuelReward}
          showProvincialIncentives={showProvincialIncentives}
          evCredit={evCredit}
          showVehiclePricePlusFees={showVehiclePricePlusFees}
          regionalFeeRebate={regionalFeeRebate}
          locale={locale}
          regionalDisclaimer={regionalDisclaimer}
          hasRegionalBannerModalCopy={hasRegionalBannerModalCopy}
          showIRABusinessEligibility={showIRABusinessEligibility}
          modelCode={modelCode}
        />
      </If>
      {showSavingsDisclaimer && (
        <div
          className={classnames('review-page--total-section review-page--total-section-last', {
            border: hideAfterSavingsModal,
          })}
        >
          <If condition={showOrderPageSummaryOtherFees || hideAfterSavingsPrice === false}>
            <ul className="review-page--totals-list tds-list">
              {/*
                  showDistinctTaxTotal are markets where we bold Price after Est. Savings, in other markets we do not bold it
               */}
              <li
                className={classnames(' review-page--line-item tds-list-item tds-flex', {
                  'tds-text--medium tds-text_color--10': showDistinctTaxTotal,
                })}
              >
                <span className="label left">
                  {i18n('Review.est_savings_total')
                    ? htmlToReact(i18n('Review.est_savings_total'))
                    : ''}
                </span>
                <span className="value"> {cashPrice.afterSavingsPrice} </span>
              </li>
            </ul>
          </If>

          <Choose>
            <When condition={fuelReward?.amount}>
              <div className="disclaimer--savings tds-text--caption">
                {i18n('SummaryPanel.disclaimers.savingsAfterPurchaseExcludeIncentive', {
                  NAME_OF_INCENTIVE: fuelReward?.title,
                })}
              </div>
            </When>
            <When condition={showTaxAndFeesDisclaimer}>
              <p className="disclaimer--savings tds-text--caption">
                {i18n('SummaryPanel.disclaimers.taxAndFeesInPurchasePrice', null, null, {
                  specificOverride: [modelCode, region],
                  returnNullWhenEmpty: true,
                  specificOverrideOperator: 'OR',
                })}
              </p>
            </When>
            <Otherwise>
              <If condition={!hideSavingsAfterPurchaseDiscl && !isPriceAcceptance()}>
                <div className="disclaimer--savings tds-text--caption">
                  {i18n(
                    'SummaryPanel.disclaimers.savingsAfterPurchase',
                    {
                      NUM_FUEL_YEAR: fuelYears,
                    },
                    null,
                    {
                      specificOverride: modelCode,
                    }
                  )}
                </div>
              </If>
            </Otherwise>
          </Choose>

          <Choose>
            <When condition={triggerIncentiveSavingsModal}>
              <ModalTrigger
                tabIndex={tabIndex}
                type={FINANCE_CAROUSEL}
                selectedView={FINANCE_MODAL_TAB_OPTIONS}
                options={{
                  props: {
                    props: {
                      classes: 'tds-o-text_color--20 tds-link tds-display--inline',
                      containerCss: 'gas-saving',
                    },
                  },
                }}
                data-gio-eventname={WEB_CONFIGURATOR_PAYMENT_GAS_SAVING_CALCULATION}
              >
                <span className="gas-saving--link tds-text--caption">
                  {i18n('Review.savings_link_label')}
                </span>
              </ModalTrigger>
            </When>
            <When condition={!hideAfterSavingsModal && !isPriceAcceptance()}>
              <ModalTrigger
                tabIndex={tabIndex}
                options={{
                  props: {
                    componentName: 'GasSaving',
                    props: {
                      genericWrapper: true,
                      size: MODAL_SMALL,
                      modelName: model.name,
                      modelCode,
                      classes: 'tds-o-text_color--20 tds-o-text-align',
                      containerCss: 'gas-saving',
                    },
                  },
                }}
                data-gio-eventname={WEB_CONFIGURATOR_PAYMENT_GAS_SAVING_CALCULATION}
                analyticsInteraction={PRICING_SAVINGS_CALCULATIONS}
              >
                <span className="gas-saving--link tds-link tds-text--caption">
                  {i18n('Review.savings_link_label')}
                </span>
              </ModalTrigger>
            </When>
          </Choose>

          <If condition={showPrpLink}>
            <div className="disclaimer--savings tds-text--caption">
              {htmlToReact(i18n('SummaryPanel.disclaimers.prpLink'))}
            </div>
          </If>
        </div>
      )}
      {!showVehicleSubtotal &&
        feeLineItems.map(fee => (
          <If condition={fee.show} key={`FeeCondition:${fee.label.replace(/\s/g, ' ')}`}>
            <div className="review-page--fees items tds-flex" key={fee.label.replace(/\s/g, ' ')}>
              <span className="left tds-flex-item">{fee.label}</span>
              <span className="right">{fee.value}</span>
            </div>
          </If>
        ))}
    </>
  );
};

function mapStateToProps(state) {
  const { isInventoryPriceAdjustmentsEnabled } = state.App || {};
  const { showTaxAndFeesDisclaimer, product } = state.ReviewDetails || {};
  const { region_code: region } = state?.SummaryPanel || {};
  const { condition, isNewInventory } = product || {};
  return {
    triggerIncentiveSavingsModal: _get(state, 'ReviewDetails.triggerIncentiveSavingsModal', false),
    hideSavingsAfterPurchaseDiscl: _get(
      state,
      'ReviewDetails.hideSavingsAfterPurchaseDiscl',
      false
    ),
    vesRebate: _get(
      state,
      'Pricing.calculatorResult.data.variables.incentives.ves_rebate.total',
      0
    ),
    additionalRegistrationFee: _get(
      state,
      'Pricing.calculatorResult.data.variables.fees.additional_registration_fee.total',
      0
    ),
    eeai: _get(state, 'Pricing.calculatorResult.data.variables.incentives.eeai.total', 0),
    netArfPayable: _get(
      state,
      'Pricing.calculatorResult.data.variables.fees.net_arf_payable.total',
      0
    ),
    roadTax: _get(state, 'Pricing.calculatorResult.data.variables.fees.road_tax.total', 0),
    showFeesSummary: _get(state, 'FinancingOptions.showFeesSummary', false),
    showTaxAndFeesDisclaimer,
    vehicleCondition: condition,
    isNewInventory,
    isInventoryPriceAdjustmentsEnabled,
    region,
  };
}

const { string, number, bool, oneOfType, shape, arrayOf } = PropTypes;

PricingDetails.propTypes = {
  cashPrice: shape({
    afterSavingsPrice: string,
    beforeSavingsPrice: string,
  }),
  cashPriceExVAT: shape({
    afterSavingsPrice: string,
    beforeSavingsPrice: string,
  }),
  destinationAndDocFee: number,
  incentives: shape({
    sellerDiscount: incentiveTypeProps,
  }),
  hideAfterSavingsPrice: bool,
  hideAfterSavingsModal: bool,
  showPrpLink: bool,
  showSavingsDisclaimer: bool,
  governmentIncentivesTotal: number,
  grossPrice: oneOfType([number, bool]),
  vatAmount: number,
  recyclingFee: oneOfType([number, bool]),
  registrationTax: number,
  regionalTax: number,
  showDriveAwayPrice: bool,
  specialTax: number,
  governmentGrant: number,
  showdestinationAndDocFee: bool,
  incentivesTotal: oneOfType([number, string]),
  incentiveTotalInPurchasePrice: number,
  fuelYears: number,
  modelCode: string,
  model: shape({
    name: string,
  }),
  tireFee: oneOfType([number, bool]),
  winterTireFee: oneOfType([number, bool]),
  hideDestinationDocFee: bool,
  showRegistrationTax: bool,
  governmentIncentivesPeriod: string,
  showRegionalTaxTypes: arrayOf(string),
  showDistinctTaxTotal: bool,
  vehiclePriceInclFees: number,
  regionalTaxValues: shape(),
  deliveryFee: number,
  OMVICFee: number,
  gstSalePrice: number,
  onRoadfeesTotal: number,
  showRegionalTax: bool,
  showIncentivesSectionTitle: bool,
  showOtherFeesinSummary: bool,
  showOrderPageSummaryOtherFees: bool,
  cashTabIncentives: shape({
    driveAwayPricewithIncentives: number,
  }),
  showVATBreakdown: bool,
  showGrossPriceTotal: bool,
  netPrice: oneOfType([bool, number]),
  tabIndex: number,
  triggerIncentiveSavingsModal: bool,
  showVatDisclaimer: bool,
  showGovernmentSubsidy: bool,
  fuelReward: shape({
    amount: number,
    title: string,
  }),
  hideSavingsAfterPurchaseDiscl: bool,
  additionalRegistrationFee: number,
  vesRebate: number,
  eeai: number,
  netArfPayable: number,
  roadTax: number,
  sctTax: shape({}),
  showProvincialIncentives: bool,
  evCredit: number,
  showVehiclePricePlusFees: bool,
  registrationType: string,
  hideIncentivesOrderPage: bool,
  regionalFeeRebate: number,
  totalSavings: number,
  showFeesSummary: bool,
  hidePurchasePriceIncentive: bool,
  feeLineItems: arrayOf(shape({})),
  showVehicleSubtotal: bool,
  showTaxAndFeesDisclaimer: bool,
  extraPricingContextAmounts: arrayOf(
    shape({
      currency: string,
      grossPrice: number,
      vatAmount: number,
    })
  ),
  displayDoubleCurrency: bool,
  vehicleCondition: string,
  isNewInventory: bool,
  discount: number,
  isInventoryPriceAdjustmentsEnabled: bool,
  vehiclePrice: string,
  showVehicleConfigurationPrice: bool,
  locale: string,
  regionalDisclaimer: string,
  hasRegionalBannerModalCopy: bool,
  priceExcludingCoE: string,
  showVehiclePriceWithoutSavings: bool,
  vehiclePriceWithoutDiscounts: number,
};

PricingDetails.defaultProps = {
  cashPrice: {
    afterSavingsPrice: '',
    beforeSavingsPrice: '',
  },
  cashPriceExVAT: {
    afterSavingsPrice: '',
    beforeSavingsPrice: '',
  },
  destinationAndDocFee: null,
  incentives: {},
  hideAfterSavingsPrice: false,
  showPrpLink: false,
  hideAfterSavingsModal: false,
  showSavingsDisclaimer: true,
  governmentIncentivesTotal: 0,
  grossPrice: 0,
  vatAmount: 0,
  recyclingFee: 0,
  registrationTax: 0,
  regionalTax: 0,
  showDriveAwayPrice: false,
  specialTax: 0,
  governmentGrant: 0,
  showdestinationAndDocFee: false,
  incentivesTotal: 0,
  incentiveTotalInPurchasePrice: 0,
  fuelYears: 0,
  modelCode: '',
  model: {
    name: '',
  },
  tireFee: 0,
  winterTireFee: 0,
  showRegistrationTax: false,
  governmentIncentivesPeriod: '',
  hideDestinationDocFee: false,
  showRegionalTaxTypes: '',
  showDistinctTaxTotal: false,
  vehiclePriceInclFees: 0,
  regionalTaxValues: [],
  deliveryFee: 0,
  OMVICFee: 0,
  gstSalePrice: 0,
  onRoadfeesTotal: 0,
  showRegionalTax: false,
  showIncentivesSectionTitle: false,
  showOtherFeesinSummary: false,
  showOrderPageSummaryOtherFees: false,
  cashTabIncentives: {},
  showVATBreakdown: false,
  showGrossPriceTotal: false,
  netPrice: 0,
  tabIndex: -1,
  triggerIncentiveSavingsModal: false,
  showVatDisclaimer: false,
  showGovernmentSubsidy: false,
  fuelReward: {
    amount: 0,
    title: '',
  },
  hideSavingsAfterPurchaseDiscl: false,
  additionalRegistrationFee: 0,
  vesRebate: 0,
  eeai: 0,
  netArfPayable: 0,
  roadTax: 0,
  sctTax: {},
  showProvincialIncentives: false,
  evCredit: 0,
  showVehiclePricePlusFees: false,
  regionalFeeRebate: 0,
  totalSavings: 0,
  showFeesSummary: false,
  hidePurchasePriceIncentive: false,
  feeLineItems: [],
  showVehicleSubtotal: false,
  showTaxAndFeesDisclaimer: false,
  vehicleCondition: '',
  isNewInventory: false,
  discount: 0,
  isInventoryPriceAdjustmentsEnabled: false,
  vehiclePrice: '',
  showVehicleConfigurationPrice: false,
  locale: '',
  regionalDisclaimer: '',
  hasRegionalBannerModalCopy: false,
  priceExcludingCoE: '',
  showVehiclePriceWithoutSavings: false,
  vehiclePriceWithoutDiscounts: 0,
};

export default connect(mapStateToProps)(PricingDetails);
