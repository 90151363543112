/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import classnames from 'classnames';
import {
  FormFeedback,
  FormInputText,
  FormInputDropdown,
  FormItem,
  FormLabel,
  Icon,
  FormFieldset,
  FormInputRadio,
} from '@tesla/design-system-react';
import { iconStatusInfo, iconStatusWarning } from '@tesla/design-system-icons';
import Loader from '../../../components/common/loader';
import { request } from 'utils/requestAgent';
import { validatePostalCode } from '@tesla/intl-address';

import {
  getAddressFromGeocoding,
  i18n,
  googleAutocomplete,
  googleGeocoder,
  containsPOBoxAddress,
  getAccessoriesLocationStates,
} from 'utils';
import {
  updateAccessoryShipToAddress,
  updateAccessoryAddressText,
  updateAccessoryAddressLine2,
  updateAccessoriesTax,
  setGoogleAddressNotFound,
  updateCustomAddressValidationComplete,
  setIsCustomAddressValid,
  setAddressesAfterValidation,
  setIsSuggestedAddressSelected,
  setIsCustomAddressSelected,
  setPartialGoogleAddress,
} from 'actions';
import { getPayorName, getLocale } from 'selectors';
import Analytics from 'analytics';
import {
  ANALYTICS_ACCESSORIES_GOOGLE_SUCCESS,
  ANALYTICS_ACCESSORIES_GOOGLE_FAIL,
  ANALYTICS_ACCESSORIES_SUGGESTED,
  ANALYTICS_ACCESSORIES_CUSTOM,
  ANALYTICS_ACCESSORIES_CUSTOM_EDIT,
} from 'dictionary';

import useStateWithGetter from '../../../hooks/useStateWithGetter';

import './accessories-shipping.css';

const fireAnalytics = interaction => {
  Analytics.fireTagEvent({
    event: Analytics.event,
    interaction,
    'cfg-type': Analytics.cfgType,
  });
};

const AccessoriesShipping = ({
  updateShipToAddress,
  updateAccessoryAddressLine2,
  updateAccessoryAddressText,
  shippingAddressText,
  updateAccessoriesTax,
  countryCode,
  shipToAddress,
  payorName,
  stateList,
  addressValidationApi,
  locale,
  googleAddressNotFound,
  setGoogleAddressNotFound,
  updateCustomAddressValidationComplete,
  customAddressValidationComplete,
  isCustomAddressValid,
  setIsCustomAddressValid,
  customAddress,
  suggestedAddress,
  setAddressesAfterValidation,
  setIsCustomAddressSelected,
  setIsSuggestedAddressSelected,
  isSuggestedAddressSelected,
  isCustomAddressSelected,
  isAccessoriesCustomAddressEnabled,
  googleAutocompleteRules,
  partialGoogleAddress,
  setPartialGoogleAddress,
  isNoPostalCodeMarket,
}) => {
  const [predictions, setPredictions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(shippingAddressText);
  const [addressError, setAddressError] = useState(null);

  const [isIncompleteAddress, setIsIncompleteAddress] = useState(false);
  const [street2, setStreet2] = useState(null);
  const [postalCodeError, setPostalCodeError] = useState(null);
  const [addressOneError, setAddressOneError] = useState(null);
  const [addressTwoError, setAddressTwoError] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [, setCityValid, getCityValid] = useStateWithGetter(false);
  const [, setPostalCodeValid, getPostalCodeValid] = useStateWithGetter(false);
  const [, setLineOneValid, getLineOneValid] = useStateWithGetter(false);
  const [, setLineTwoValid, getLineTwoValid] = useStateWithGetter(true);
  const [isLoading, setIsLoading] = useStateWithGetter(false);

  const inputName = 'shipping-address';

  const inputRef = useRef();
  const stateListRef = useRef();
  const customStateRef = useRef(
    countryCode === 'PR' ? { value: 'PR', label: 'Puerto Rico' } : null
  );
  stateListRef.current = stateList;

  const getPredictions = async text => {
    if (!googleAddressNotFound) {
      const results = await googleAutocomplete(text, countryCode);
      const addressesWithStreetResults = results; // results.filter(result => !result.types.includes('route'));
      if (!isAccessoriesCustomAddressEnabled) {
        if (addressesWithStreetResults.length === 0) {
          setAddressError(i18n('Accessories.error_address'));
        } else {
          setAddressError(null);
        }
      }
      setPredictions(addressesWithStreetResults);
    }
  };

  const getPlacesDetails = async place_id => {
    const placeFullAddress = await googleGeocoder(place_id);
    const address = getAddressFromGeocoding(placeFullAddress, googleAutocompleteRules);
    return [address, placeFullAddress];
  };

  const handleInputClick = () => {
    if (!googleAddressNotFound && !partialGoogleAddress) {
      setSelectedAddress(null);
      setIsIncompleteAddress(false);
      updateAccessoryAddressText(null);
      updateAccessoriesTax();
      if (inputRef.current) {
        inputRef.current.value = null;
      }
    } else {
      setLineOneValid(false);
    }
  };

  const handleInputChange = e => {
    if (!googleAddressNotFound) {
      if (selectedAddress || partialGoogleAddress) {
        return false;
      }
      getPredictions(e.target.value);
    }
  };

  const handleDropdownClick = async (e, place_id) => {
    let addressText = e.currentTarget.innerText; // get address text first because it will not be available after
    const [address, placeResult] = await getPlacesDetails(place_id);

    if ( stateList?.length && address?.state) {
      const hasEligibleState = stateList?.some(item => item?.value === address?.state);
      if (!hasEligibleState) {
        return setGoogleAddressNotFound();
      }
    }

    if (!placeResult.types.includes('route')) {
      updateShipToAddress(address);
      if (address.street2?.length > 0) {
        setStreet2(address.street2);
        updateAccessoryAddressLine2(address.street2);
      }
    } else {
      setPartialGoogleAddress(address);
      setStreet2(address.street2);
      setPostalCode(address.postalCode);
      setCity(address.city.length > 30 ? address.city.slice(0, 30) : address.city);
      customStateRef.current = Array.isArray(stateListRef.current)
        ? stateListRef.current?.find(elem => elem.value === address.state)
        : null;

      if (validatePostalCode(address.postalCode, { countryCode })) {
        setPostalCodeValid(true);
      }

      if (address.city?.length > 0) {
        setCityValid(true);
      }

      addressText = address?.street1;
    }

    setSelectedAddress(addressText);
    updateAccessoryAddressText(addressText);
    if (inputRef.current) {
      inputRef.current.value = addressText;
    }
    setPredictions([]);

    fireAnalytics(ANALYTICS_ACCESSORIES_GOOGLE_SUCCESS);
  };

  const handleDocumentClick = e => {
    if (e.target.name !== inputName) {
      setIsIncompleteAddress(true);
      setPredictions([]);
    }
  };

  useEffect(() => {
    if (
      !googleAddressNotFound &&
      isIncompleteAddress &&
      selectedAddress === null &&
      inputRef.current?.value.length > 0
    ) {
      if (isAccessoriesCustomAddressEnabled) {
        inputRef.current.value = null;
      } else {
        setAddressError(i18n('Accessories.error_address'));
      }
    }
  }, [isIncompleteAddress, selectedAddress]);

  const validateCustomAddress = async () => {
    try {
      const response = await request
        .post(addressValidationApi)
        .type('form')
        .set('Accept', 'application/json')
        .send({
          street1: inputRef?.current.value,
          street2,
          city,
          postalCode,
          state: customStateRef?.current?.value,
          countryCode: countryCode === 'PR' ? 'US' : countryCode,
          locale,
        });
      const { isValid, address, formattedAddress } = response?.body?.data;
      updateCustomAddressValidationComplete(true);
      setAddressesAfterValidation({
        customAddress: address,
        suggestedAddress: formattedAddress,
      });
      if (isValid) {
        setIsCustomAddressValid(true);
        setIsSuggestedAddressSelected(true);
        fireAnalytics(ANALYTICS_ACCESSORIES_SUGGESTED);
        setIsCustomAddressSelected(false);
        updateAccessoryAddressText(formattedAddress?.street1);
        updateAccessoryAddressLine2(formattedAddress?.street2);
        updateShipToAddress({
          city: formattedAddress?.city,
          country: formattedAddress?.countryCode,
          postalCode: formattedAddress?.postalCode,
          postalCodeExt: '',
          street1: formattedAddress?.street1,
          street2: formattedAddress?.street2,
          state: formattedAddress?.stateCode,
        });
      } else {
        setIsCustomAddressValid(false);
        setIsCustomAddressSelected(true);
        fireAnalytics(ANALYTICS_ACCESSORIES_CUSTOM);
        updateAccessoryAddressText(address?.street1);
        updateAccessoryAddressLine2(address?.street2);
        updateShipToAddress({
          city: address?.city,
          country: address?.country,
          postalCode: address?.postalCode,
          postalCodeExt: '',
          street1: address?.street1,
          street2: address?.street2,
          state: formattedAddress?.stateCode,
        });
      }
    } catch (err) {
      updateCustomAddressValidationComplete(true);
    }
    setIsLoading(false);
  };

  const onUnableToFindGoogleAddress = () => {
    updateAccessoryAddressText(inputRef?.current.value);
    setGoogleAddressNotFound();
    if (containsPOBoxAddress(inputRef?.current.value)) {
      setAddressOneError(i18n('common.errors__poBoxesNotAllowed'));
      setLineOneValid(false);
    } else {
      setLineOneValid(true);
    }
    fireAnalytics(ANALYTICS_ACCESSORIES_GOOGLE_FAIL);
  };

  const doValidateCustomAddress = e => {
    if (/^accessories/.test(e?.relatedTarget?.id)) return;
    if (
      getLineOneValid() &&
      getLineTwoValid() &&
      (isNoPostalCodeMarket || getPostalCodeValid()) &&
      getCityValid() &&
      !(stateListRef?.current?.length && customStateRef?.current === null)
    ) {
      setIsLoading(true);
      validateCustomAddress();
    }
  };

  const postalCodeOnBlur = e => {
    if (!validatePostalCode(e.target.value, { countryCode })) {
      setPostalCodeError(i18n('common.errors__invalidZipCode'));
    } else {
      setPostalCodeError(null);
      setPostalCodeValid(true);
      doValidateCustomAddress(e);
    }
  };

  const addressLineOneOnBlur = e => {
    if (googleAddressNotFound || partialGoogleAddress) {
      if (!e.target.value?.length) {
        setAddressOneError(i18n('common.errors__required'));
      } else if (containsPOBoxAddress(e.target.value)) {
        setAddressOneError(i18n('common.errors__poBoxesNotAllowed'));
        doValidateCustomAddress(e);
      } else {
        setAddressOneError(null);
        setLineOneValid(true);
        doValidateCustomAddress(e);
      }
    }
  };

  const addressLineTwoOnBlur = e => {
    if (containsPOBoxAddress(e.target.value)) {
      setAddressTwoError(i18n('common.errors__poBoxesNotAllowed'));
      doValidateCustomAddress(e);
      updateAccessoryAddressLine2(null);
      setStreet2(null);
    } else {
      setAddressTwoError(null);
      setLineTwoValid(true);
      doValidateCustomAddress(e);
    }
  };

  const cityOnBlur = e => {
    if (city && city.length > 0) {
      setCityValid(true);
      setCityError(null);
    } else {
      setCityValid(false);
      setCityError(i18n('common.errors__required'));
    }
    doValidateCustomAddress(e);
  };

  useEffect(() => {
    // When user click outside of the search input. Reset predictions.
    document.addEventListener('click', handleDocumentClick);
    if (shippingAddressText && inputRef.current) {
      inputRef.current.value = shippingAddressText;
    }

    return () => document.removeEventListener('click', handleDocumentClick);
  }, []);

  useEffect(() => {
    if (!customAddressValidationComplete && !postalCodeError) {
      inputRef.current.value = shippingAddressText;
    }
  }, [customAddressValidationComplete]);

  useEffect(() => {
    if (partialGoogleAddress) {
      if (partialGoogleAddress.street1.charAt(0) === ' ') {
        setTimeout(() => {
          inputRef.current.setSelectionRange(0, 0);
        }, 0);
      }
      inputRef.current?.focus();
    }
  }, [partialGoogleAddress]);

  return (
    <div className="tds--vertical_padding-bottom">
      <h4 className="text-loader--subtitle">{i18n('Accessories.label_section_shipping')}</h4>
      <p className="tds-text--caption tds--vertical_padding-bottom--1x">
        {i18n('Accessories.caption')}
      </p>
      <If condition={!customAddressValidationComplete}>
        <div className="accessories-shipping-form-container">
          <div className={classnames('accessories-shipping-form', { validation: isLoading })}>
            <FormFieldset>
              <If condition={!isAccessoriesCustomAddressEnabled}>
                <FormItem error={addressError}>
                  <FormLabel htmlFor="accessories-shipping">
                    {i18n('Accessories.label_address')}
                  </FormLabel>
                  <FormInputText
                    inputRef={inputRef}
                    autoComplete="off"
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    id="accessories-shipping"
                    placeholder={i18n('Accessories.placeholder_address')}
                    name={inputName}
                  />
                  <FormFeedback feedbackText={addressError} />
                </FormItem>
              </If>
              <If condition={isAccessoriesCustomAddressEnabled}>
                <FormItem error={addressOneError}>
                  <FormLabel htmlFor="accessories-shipping">
                    {i18n('Accessories.label_address')}
                  </FormLabel>
                  <FormInputText
                    inputRef={inputRef}
                    autoComplete="off"
                    onChange={e => {
                      handleInputChange(e);
                    }}
                    onClick={handleInputClick}
                    onBlur={e => addressLineOneOnBlur(e)}
                    id="accessories-shipping"
                    placeholder={i18n('Accessories.placeholder_address')}
                    name={inputName}
                  />
                  <FormFeedback feedbackText={addressOneError} />
                </FormItem>
              </If>

              <If
                condition={
                  !selectedAddress && !googleAddressNotFound && inputRef?.current?.value.length > 0
                }
              >
                <ul className="autocomplete-dropdown">
                  {predictions.map(({ place_id, description }) => (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <li onClick={e => handleDropdownClick(e, place_id)} key={place_id}>
                      {description}
                    </li>
                  ))}
                  <If condition={isAccessoriesCustomAddressEnabled}>
                    <li>
                      <button
                        onClick={onUnableToFindGoogleAddress}
                        className="tds-o-cannot-find-address"
                      >
                        {i18n('Accessories.address_autocomplete_cannot_find')}
                      </button>
                    </li>
                  </If>
                </ul>
              </If>
              <FormItem error={addressTwoError}>
                <FormLabel htmlFor="accessories-line-2">
                  {i18n('Accessories.label_address_line_2')}
                </FormLabel>
                <FormInputText
                  id="accessories-line-2"
                  value={street2 || ''}
                  onChange={e => {
                    updateAccessoryAddressLine2(e.target.value);
                    setStreet2(e.target.value);
                  }}
                  onClick={() => setLineTwoValid(false)}
                  onBlur={e => addressLineTwoOnBlur(e)}
                />
                <FormFeedback feedbackText={addressTwoError} />
              </FormItem>
              <If condition={googleAddressNotFound || partialGoogleAddress}>
                <If condition={!isNoPostalCodeMarket}>
                  <FormItem error={postalCodeError}>
                    <FormLabel htmlFor="accessories-postalcode">
                      {i18n('Accessories.custom_address_postal_code')}
                    </FormLabel>
                    <FormInputText
                      id="accessories-postalcode"
                      onClick={() => setPostalCodeValid(false)}
                      onChange={e => setPostalCode(e.target.value)}
                      onBlur={e => postalCodeOnBlur(e)}
                      value={postalCode || ''}
                    />
                    <FormFeedback feedbackText={postalCodeError} />
                  </FormItem>
                </If>
                <FormItem error={cityError}>
                  <FormLabel htmlFor="accessories-city">
                    {i18n('Accessories.custom_address_city')}
                  </FormLabel>
                  <FormInputText
                    value={city || ''}
                    onClick={() => setCityValid(false)}
                    onBlur={e => cityOnBlur(e)}
                    onChange={e => {
                      if (e.target.value.length < 31) {
                        setCity(e.target.value);
                      }
                    }}
                    id="accessories-city"
                  />
                  <FormFeedback feedbackText={cityError} />
                </FormItem>
                {/* TODO: integrate with intl-address to handle this better */}
                <If condition={countryCode !== 'PR' && countryCode !== 'NZ' && stateList?.length}>
                  <FormItem>
                    <FormLabel htmlFor="accessories-state">
                      {i18n('Accessories.custom_address_state')}
                    </FormLabel>
                    {/* TODO: validation */}
                    <FormInputDropdown
                      id="accessories-state"
                      options={stateList}
                      placeholder={i18n('Accessories.select_state')}
                      onOptionSelect={option => {
                        customStateRef.current = option;
                        doValidateCustomAddress();
                      }}
                      selected={customStateRef.current}
                    />
                  </FormItem>
                </If>
              </If>
              <If condition={selectedAddress}>
                <div className="tds--vertical_padding coin-address-block">
                  <div>{payorName}</div>
                  <div>{shipToAddress?.street1}</div>
                  <div>{shipToAddress?.street2}</div>
                  <div>
                    <span>{shipToAddress?.suburb} </span>
                    <span>{shipToAddress?.city} </span>
                    <span>{shipToAddress?.state} </span>
                    <If condition={!isNoPostalCodeMarket}>
                      <span>{shipToAddress?.postalCode}</span>
                    </If>
                  </div>
                </div>
              </If>
            </FormFieldset>
          </div>
          <div className={classnames('loader-container', { 'is-loading': isLoading })}>
            <Loader />
          </div>
        </div>
      </If>
      <If condition={customAddressValidationComplete}>
        <If condition={isCustomAddressValid}>
          <div className="custom-address-caption-container">
            <Icon data={iconStatusInfo} />
            <div className="custom-address-caption">
              {i18n('Accessories.suggested_address_caption_text')}
            </div>
          </div>
          <FormInputRadio
            id="suggested-address"
            label={i18n('Accessories.suggested_address_radio_text')}
            checked={isSuggestedAddressSelected}
            onChange={() => {
              setIsSuggestedAddressSelected(true);
              setIsCustomAddressSelected(false);
              fireAnalytics(ANALYTICS_ACCESSORIES_SUGGESTED);
              updateAccessoryAddressText(suggestedAddress?.street1);
              updateAccessoryAddressLine2(suggestedAddress?.street2);
              updateShipToAddress({
                city: suggestedAddress?.city,
                country: suggestedAddress?.countryCode,
                postalCode: suggestedAddress?.postalCode,
                postalCodeExt: '',
                street1: suggestedAddress?.street1,
                street2: suggestedAddress?.street2,
                state: suggestedAddress?.stateCode,
              });
            }}
          />

          <div className="tds-o-custom-address">
            <div>{payorName}</div>
            <div>{suggestedAddress?.street1}</div>
            <div>{suggestedAddress?.street2}</div>
            <div>
              <span>{suggestedAddress?.city} </span>
              <span>{suggestedAddress?.stateProvince} </span>
              <span>{suggestedAddress?.postalCode}</span>
            </div>
          </div>
        </If>
        <If condition={!isCustomAddressValid}>
          <div className="custom-address-caption-container">
            <Icon data={iconStatusWarning} />
            <div className="custom-address-caption">
              {i18n('Accessories.entered_address_caption_text')}
            </div>
          </div>
        </If>
        <FormInputRadio
          id="custom-address"
          label={i18n('Accessories.entered_address_radio_text')}
          checked={isCustomAddressSelected}
          onChange={() => {
            setIsCustomAddressSelected(true);
            setIsSuggestedAddressSelected(false);
            fireAnalytics(ANALYTICS_ACCESSORIES_CUSTOM);
            updateAccessoryAddressText(customAddress?.street1);
            updateAccessoryAddressLine2(customAddress?.street2);
            updateShipToAddress({
              city: customAddress?.city,
              country: customAddress?.country,
              postalCode: customAddress?.postalCode,
              postalCodeExt: '',
              street1: customAddress?.street1,
              street2: customAddress?.street2,
              state: countryCode === 'PR' ? 'PR' : customAddress?.state,
            });
          }}
        />
        <div className="tds-o-custom-address">
          <div>{payorName}</div>
          <div>{customAddress?.street1}</div>
          <div>{customAddress?.street2}</div>
          <div>
            <span>{customAddress?.city} </span>
            <span>{customAddress?.state} </span>
            <span>{customAddress?.postalCode}</span>
          </div>
        </div>
        <div className="edit-custom-address">
          <button
            className="tds-link"
            onClick={() => {
              setLineOneValid(false);
              setLineTwoValid(true);
              setPostalCodeValid(false);
              setCityValid(false);
              updateAccessoryAddressText(null);
              updateAccessoryAddressLine2(null);
              setIsCustomAddressValid(false);
              updateShipToAddress({});
              setStreet2(null);
              setPostalCode(null);
              setCity(null);
              customStateRef.current = null;
              updateCustomAddressValidationComplete(false);
              fireAnalytics(ANALYTICS_ACCESSORIES_CUSTOM_EDIT);
            }}
          >
            {i18n('Accessories.edit_custom_address')}
          </button>
        </div>
      </If>
    </div>
  );
};

function mapStateToProps(state) {
  const { Accessories, App } = state;
  return {
    shippingAddressText: Accessories.shippingAddressText || null,
    countryCode: App?.countryCode,
    // TODO: Migrate away from tesla/region as NZ has no concept of states
    // but tesla/region returns regions as states (also not needed for address validation)
    stateList: App?.countryCode !== 'NZ' ? getAccessoriesLocationStates(state) : null,
    shipToAddress: Accessories.shipToAddress,
    payorName: getPayorName(state),
    locale: getLocale(state),
    addressValidationApi: Accessories?.addressValidationApi,
    googleAddressNotFound: Accessories?.googleAddressNotFound,
    customAddressValidationComplete: Accessories?.customAddressValidationComplete,
    isCustomAddressValid: Accessories?.isCustomAddressValid,
    customAddress: Accessories?.customAddress,
    suggestedAddress: Accessories?.suggestedAddress,
    isSuggestedAddressSelected: Accessories?.isSuggestedAddressSelected,
    isCustomAddressSelected: Accessories?.isCustomAddressSelected,
    isAccessoriesCustomAddressEnabled: Accessories?.isAccessoriesCustomAddressEnabled,
    googleAutocompleteRules: Accessories?.googleAutocompleteRules,
    partialGoogleAddress: Accessories?.partialGoogleAddress,
    isNoPostalCodeMarket: Accessories?.isNoPostalCodeMarket || false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateShipToAddress: payload => {
      dispatch(updateAccessoryShipToAddress(payload));
    },
    updateAccessoryAddressLine2: payload => dispatch(updateAccessoryAddressLine2(payload)),
    updateAccessoryAddressText: payload => dispatch(updateAccessoryAddressText(payload)),
    updateAccessoriesTax: () => dispatch(updateAccessoriesTax(undefined)),
    setGoogleAddressNotFound: () => dispatch(setGoogleAddressNotFound()),
    setAddressesAfterValidation: payload => dispatch(setAddressesAfterValidation(payload)),
    setPartialGoogleAddress: payload => dispatch(setPartialGoogleAddress(payload)),
    setIsCustomAddressValid: payload => dispatch(setIsCustomAddressValid(payload)),
    setIsCustomAddressSelected: payload => dispatch(setIsCustomAddressSelected(payload)),
    setIsSuggestedAddressSelected: payload => dispatch(setIsSuggestedAddressSelected(payload)),
    updateCustomAddressValidationComplete: payload =>
      dispatch(updateCustomAddressValidationComplete(payload)),
  };
}

AccessoriesShipping.propTypes = {
  hasUserSelectedAccesories: bool,
  updateShipToAddress: func.isRequired,
  updateAccessoryAddressLine2: func.isRequired,
  updateAccessoryAddressText: func.isRequired,
  shippingAddressText: string,
  updateAccessoriesTax: func.isRequired,
  countryCode: string.isRequired,
  shipToAddress: shape({}),
  customAddress: shape({}),
  suggestedAddress: shape({}),
  payorName: string,
  stateList: arrayOf(shape({})).isRequired,
  addressValidationApi: string.isRequired,
  locale: string.isRequired,
  googleAddressNotFound: bool.isRequired,
  setGoogleAddressNotFound: func.isRequired,
  customAddressValidationComplete: bool.isRequired,
  updateCustomAddressValidationComplete: func.isRequired,
  isCustomAddressValid: bool.isRequired,
  isCustomAddressSelected: bool.isRequired,
  isSuggestedAddressSelected: bool.isRequired,
  setIsCustomAddressValid: func.isRequired,
  setAddressesAfterValidation: func.isRequired,
  setIsCustomAddressSelected: func.isRequired,
  setIsSuggestedAddressSelected: func.isRequired,
  isAccessoriesCustomAddressEnabled: bool,
  googleAutocompleteRules: shape({}).isRequired,
  setPartialGoogleAddress: func.isRequired,
  partialGoogleAddress: shape({}),
  isNoPostalCodeMarket: bool,
};

AccessoriesShipping.defaultProps = {
  hasUserSelectedAccesories: false,
  shippingAddressText: null,
  shipToAddress: null,
  customAddress: null,
  suggestedAddress: null,
  payorName: '',
  isAccessoriesCustomAddressEnabled: false,
  partialGoogleAddress: null,
  isNoPostalCodeMarket: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesShipping);
