import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n } from 'utils';
import { MODAL_LARGE, SAVE_DESIGN_MODAL } from 'dictionary';
import ModalTrigger from '../../../components/ModalTrigger';
import { GIO_TAG_ENABLE } from 'gioStatistics';
import { WEB_SAVE_DESIGN_OPEN_MODAL_CLICK } from '../../../common/gioStatistics';
const SaveDesignLink = ({ showLink }) => {
  if (!showLink) {
    return null;
  }
  return (
    <div className="tds-o-vertical_padding--1x save-design--link">
      <ModalTrigger
        options={{
          props: {
            componentName: SAVE_DESIGN_MODAL,
            props: {
              selectedForm: SAVE_DESIGN_MODAL,
              genericWrapper: true,
              size: MODAL_LARGE,
              classes: 'tds-link tds--align_center',
              containerCss: 'tds-display--inline ',
            },
          },
        }}
      >
        <span data-gio-track={GIO_TAG_ENABLE} data-gio-eventname={WEB_SAVE_DESIGN_OPEN_MODAL_CLICK}>
          {i18n('share.shareDesign')}
        </span>
      </ModalTrigger>
    </div>
  );
};

SaveDesignLink.propTypes = {
  showLink: bool,
};

SaveDesignLink.defaultProps = {
  showLink: false,
};

function mapStateToProps(state) {
  const { ApplicationFlow, App } = state;
  const { isSaveDesignLiteEnabled } = App;
  const { canModifyOrder } = ApplicationFlow;
  return {
    showLink: !canModifyOrder && isSaveDesignLiteEnabled,
  };
}

export default connect(mapStateToProps)(SaveDesignLink);
