import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import TextLoader from '../TextLoader';

const InventoryTrimDisclaimer = ({ disclaimer }) => {
  if (!disclaimer) {
    return null;
  }

  return <TextLoader data={disclaimer} />;
};

const mapStateToProps = state => {
  const { TRIM } = state?.CustomGroups || {};
  const disclaimerKeys = ['promotion'];
  const disclaimer = TRIM?.[Object.keys(TRIM).find(x => disclaimerKeys.includes(x))] || null;

  return {
    disclaimer,
  };
};

InventoryTrimDisclaimer.propTypes = {
  disclaimer: string,
};

InventoryTrimDisclaimer.defaultProps = {
  disclaimer: null,
};

export default connect(mapStateToProps)(InventoryTrimDisclaimer);
