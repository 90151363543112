import React from 'react';
import { connect } from 'react-redux';
import { i18n, htmlToReact } from 'utils';
import _get from 'lodash/get';
import { bool, number, string } from 'prop-types';
import { getGovernmentIncentives, getFinanceType, getFinanceProductType } from 'selectors';
import { 
  SHOW_FINANCE_MODAL,
  NEW,
  USED,
 } from 'dictionary';
import ActionTrigger from '../../../components/ActionTrigger';

const TaxesAndFeesDisclaimer = ({
  isTransportFeeEnabled,
  isDeliveryDetailsValid,
  transportationFee,
  taxesAndFeesExcludedIfApplicable,
  showExcludesTaxesAndOtherFeesLabel,
  showTaxAndFeesDisclaimer,
  financeType,
  hideTaxAndFeesLink,
  region,
  condition,
  isTradeInEnabled,
  tradeInAmount,
  countryCode,
  modelCode,
  productType,
}) => {
  const tradeInOperator = isTradeInEnabled && tradeInAmount > 0 ? 'tradein' : '';
  const taxesAndFeesOther = isTransportFeeEnabled && isDeliveryDetailsValid && transportationFee;
  const excludingTaxAndOtherFeesDisclaimer = hideTaxAndFeesLink ? 
  i18n(`SummaryPanel.disclaimers.excludingTaxAndOtherFees`, null, null, {
      specificOverride: [tradeInOperator],
  })
  : i18n(
      `SummaryPanel.disclaimers.excludingTaxAndOtherFees`,
      {
        TRIGGER: SHOW_FINANCE_MODAL,
      },
      null,
      {
        specificOverride: [financeType, tradeInOperator],
        returnNullWhenEmpty: true,
        specificOverrideOperator: 'OR',
      }
    )
  const taxAndFeesInPurchasePriceString = i18n('SummaryPanel.disclaimers.taxAndFeesInPurchasePrice', null, null, {
    specificOverride: [modelCode, region, financeType, condition],
    returnNullWhenEmpty: true,
    specificOverrideOperator: 'BOTH',
  }) || '';
  return (
    <>
      <Choose>
        <When condition={showTaxAndFeesDisclaimer}>
          <p className="tds-text--caption eee">
            {htmlToReact(taxAndFeesInPurchasePriceString)}
          </p>
        </When>
        <When condition={showExcludesTaxesAndOtherFeesLabel}>
          <If condition={countryCode !== 'US'}>
            <ActionTrigger>
              <p className="tds-text--caption">
              {htmlToReact(excludingTaxAndOtherFeesDisclaimer)}
              </p>
            </ActionTrigger>
          </If>
        </When>
        <Otherwise>
          <If condition={taxesAndFeesOther}>
            <p className="tds-text--caption">
              {i18n(`SummaryPanel.disclaimers.taxesAndFeesOther`)}
            </p>
          </If>
          <If
            condition={
              !showExcludesTaxesAndOtherFeesLabel &&
              !taxesAndFeesOther &&
              !taxesAndFeesExcludedIfApplicable
            }
          >
            <p className="tds-text--caption">
              {i18n(`SummaryPanel.disclaimers.taxesAndFeesExcluded`, null, null, {
                specificOverride: [tradeInOperator, productType],
                specificOverrideOperator: 'OR',
                returnNullWhenEmpty: true,
              })}
            </p>
          </If>
          <If condition={taxesAndFeesExcludedIfApplicable}>
            <p className="tds-text--caption">
              {i18n(`SummaryPanel.disclaimers.taxesAndFeesExcludedIfApplicable.content`)}
            </p>
          </If>
        </Otherwise>
      </Choose>
    </>
  );
};

TaxesAndFeesDisclaimer.propTypes = {
  isDeliveryDetailsValid: bool,
  isTransportFeeEnabled: bool,
  transportationFee: number,
  taxesAndFeesExcludedIfApplicable: bool,
  showExcludesTaxesAndOtherFeesLabel: bool,
  showTaxAndFeesDisclaimer: bool,
  region: string,
  countryCode: string.isRequired,
};

TaxesAndFeesDisclaimer.defaultProps = {
  isDeliveryDetailsValid: false,
  isTransportFeeEnabled: false,
  transportationFee: null,
  taxesAndFeesExcludedIfApplicable: false,
  showExcludesTaxesAndOtherFeesLabel: false,
  showTaxAndFeesDisclaimer: false,
  region: '',
};

function mapStateToProps(state) {
  const { ReviewDetails, FinancingOptions, SummaryPanel, App, TradeIn, Configuration } = state || {};
  const { taxesAndFeesExcludedIfApplicable } = FinancingOptions || {};
  const { showTaxAndFeesDisclaimer, isDeliveryDetailsValid, isTransportFeeEnabled } =
    ReviewDetails || {};
  const { region_code: region } = SummaryPanel || {};
  const transportationFee = _get(ReviewDetails, 'DeliveryDetails.TransportationFee', 0);
  const hideTaxAndFeesLink = _get(state, 'App.hideTaxAndFeesLink', false);
  const condition = USED === _get(state, 'ReviewDetails.product.condition', null) ? USED : NEW;
  const { isTradeInEnabled, countryCode } = App || {};
  const { tradeInAmount } = TradeIn || {};
  const { model_code: modelCode = '' } = Configuration || {};

  return {
    isTransportFeeEnabled,
    isDeliveryDetailsValid,
    transportationFee,
    taxesAndFeesExcludedIfApplicable,
    showExcludesTaxesAndOtherFeesLabel: state?.ReviewDetails?.showVehiclePricePlusFees || false,
    showTaxAndFeesDisclaimer,
    hideTaxAndFeesLink,
    region,
    condition,
    govtCredit: getGovernmentIncentives(state),
    financeType: getFinanceType(state),
    isTradeInEnabled,
    tradeInAmount,
    countryCode,
    modelCode,
    productType: getFinanceProductType(state),
  };
}

export default connect(mapStateToProps)(TaxesAndFeesDisclaimer);
