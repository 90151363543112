import React from 'react';
import { connect } from 'react-redux';
import { string, array, object, bool } from 'prop-types';
import cx from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import TextLoader from '../TextLoader';
import AssetLoader from '../AssetLoader';

const CompareFeatures = ({ classes, items, group, type, isMobile }) => {
  const renderAssets = value => {
    const assets = Array.isArray(value) ? value : [value];
    return (
      <div className="compare-features--asset tds-o-flex-direction--row">
        {assets.map((asset, index) => {
          const { classes = '' } = asset || {};
          return <AssetLoader key={index} asset={asset} className={classes} />;
        })}
      </div>
    );
  };
  const isExpanded = (type === 'expanded');

  return (
    <div className={cx({ [classes]: classes })}>
      {items.map((i, index) => {
        const { field, items: element = [], classes, noBorder = false, noBottomPadding = false, noTopPadding = false } = i || {};
        const copy = group?.[field] || {};
        const isLastIndex = !!(items?.length - 1 === index);
        const heading = copy.heading || '';
        const title = copy.title || copy;

        return (
          <div
            className={cx({
              'tds-o-padding_bottom-24': (!isExpanded || isMobile) && !noBottomPadding,
              'tds-o-padding_bottom-32': isExpanded && !isMobile && !noBottomPadding,
              'tds--padding_top-16': isExpanded && !isMobile && !noTopPadding,
              'tds--padding_top-8': isExpanded && isMobile && !noTopPadding,
              'tds--border-bottom_grey50': !noBorder,
              [classes]: classes,
            })}
          >
            <If condition={heading?.length}>
              <TextLoader data={heading} className="tds-text--h3 tds--vertical_padding" />
            </If>
            <If condition={title?.length}>
              <TextLoader data={title} className="tds-text--h4 tds--vertical_padding" />
            </If>
            <div className={cx('compare-features--item', {'compare-features--item--three': element.length === 3}, {'compare-features--item--one': element.length === 1})}>
              {element.map((item, index) => {
                const { asset, field } = item || {};
                const fieldValue = group?.[field];
                return (
                  <div className="tds-layout-item">
                    <If condition={fieldValue?.heading}>
                      <TextLoader
                        data={fieldValue}
                        field="heading"
                        className="tds-text--h3 tds-o-vertical_padding-top" />
                    </If>
                    <If condition={fieldValue?.subtitle}>
                      <TextLoader
                        data={fieldValue}
                        field="subtitle"
                        className={cx('tds-text--h4',
                          {'tds--o-vertical_padding-top': !fieldValue?.heading && !noTopPadding},
                          {'tds--no_vertical_padding': fieldValue.heading || noTopPadding})
                        }
                      />
                    </If>
                    <If condition={!_isEmpty(asset)}>{renderAssets(asset)}</If>
                    <div
                      className={cx('', {
                        'tds--padding_top-8': !_isEmpty(asset),
                        'tds-text--contrast-low': !isExpanded,
                      })}
                    >
                      <Choose>
                        <When condition={typeof fieldValue === 'object' && fieldValue?.description}>
                          <TextLoader
                            key={`description:${index}`}
                            data={fieldValue}
                            field="description"
                            className={cx('',{'tds-text--body-large': isExpanded})}
                          />
                        </When>
                        <When condition={Array.isArray(fieldValue)}>
                          <TextLoader key={`description:${index}`} data={fieldValue} />
                        </When>
                        <Otherwise>
                          <TextLoader key={`description:${index}`} data={fieldValue} className={cx('',{'tds-text--body-large': isExpanded})} />
                        </Otherwise>
                      </Choose>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

CompareFeatures.propTypes = {
  classes: string,
  type: string,
  items: array,
  group: object,
  isMobile: bool,
};

CompareFeatures.defaultProps = {
  classes: '',
  type: 'default',
  items: [],
  group: {},
  isMobile: false,
};

const mapStateToProps = state => {
  return {
    isMobile: state?.App?.isLayoutMobileAdjusted,
  };
};

export default connect(mapStateToProps)(CompareFeatures);
