import React from 'react';
import { string, func, bool, arrayOf, shape } from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import cx from 'classnames';
import { FormInputCheckbox } from '@tesla/design-system-react';
import {
  getFinanceProductType,
  getFuelYears,
  getMonthlyFuelSavings,
  getCurrentAvailableTotal,
  getCurrentAvailableMonthlyFuel,
  getRegionName,
  getPricePostfix,
  getFinanceProductId,
} from 'selectors';
import Analytics from 'analytics';

import { formatMonthlyPrice, formatCurrency } from '@tesla/coin-common-components';
import { toggleTrimSavingsPrice, updateIncentives, updateSelectedIncentives } from 'actions';
import { i18n, htmlToReact } from 'utils';
import { 
  FINANCIAL_MODAL_STANDALONE,
  MODAL_MEDIUM,
  PREQUAL_MODAL_VIEW,
} from 'dictionary';
import { 
  GIO_TAG_ENABLE,
  WEB_CONFIGURATOR_FINANCING_CALCULATOR,
} from '../../common/gioStatistics';
import IncentivesSavings from './IncentivesSavings';
import FinanceDisclaimer from './FinanceDisclaimer';
import ModalTrigger from '../ModalTrigger';
import FinanceCheckboxes from '../FinancialModal/FinanceView/FinanceCheckboxes';

const IncentivesToggle = ({
  showProbableSavings,
  financeTab,
  toggleSavings,
  disclaimerOverrides,
  disclaimerVars = {},
  eligibleIncentives,
  updateIncentives,
  classes,
  linkClasses,
  modalCopy,
  isMobile,
  pricePostfix,
  showProbableSavingsToggle,
  hideToggle,
  showLegacyFooterDisclaimers,
  showFinanceModalTrigger = false,
  hideFinanceDisclaimer = false,
  availableFuelSavings,
  decoupleFuelIncentives,
  dispatch,
  hasFuelIncentives,
  hasCurrentIncentives,
  otherIncentives,
  checkboxLabelOverrides,
  isPreQualifyEnabled,
  isCentered,
  region,
}) => {
  if (hideToggle) {
    return null;
  }

  let i18nFinanceType = financeTab ? 'finance' : 'default';

  if (hideFinanceDisclaimer) {
    i18nFinanceType = 'default';
    disclaimerOverrides = ['savings'];
  }

  const FinanceModalEntry = () => {
    return (
      <div className="coin-finance-entry coin-finance-general">
        <ModalTrigger
          analyticsInteraction={'edit-terms-savings'}
          data-gio-eventname={WEB_CONFIGURATOR_FINANCING_CALCULATOR}
          data-gio-track={GIO_TAG_ENABLE}
          options={{
            props: {
              componentName: FINANCIAL_MODAL_STANDALONE,
              props: {
                genericWrapper: true,
                size: MODAL_MEDIUM,
                classes: isCentered && 'tds-text--center',
                isMega: true,
              },
            },
          }}
        >
          <span className={linkClasses || 'tds-link'}>
            {i18n(modalCopy || 'common.editTermsAndSavings', null, null, {
              specificOverride: i18nFinanceType,
              returnNullWhenEmpty: true,
              specificOverrideOperator: 'OR',
            })}
          </span>
        </ModalTrigger>
      </div>
    );
  };

  const PrequalModalEntry = () => {
    return (
      <div className="coin-finance-entry coin-finance-prequalified">
        <ModalTrigger
          analyticsInteraction="get-prequalified-configurator"
          options={{
            props: {
              componentName: FINANCIAL_MODAL_STANDALONE,
              props: {
                genericWrapper: true,
                size: MODAL_MEDIUM,
                classes: isCentered && 'tds-text--center',
                selectedView: PREQUAL_MODAL_VIEW,
                isMega: true,
              },
            },
          }}
        >
          <span className="tds-link">
            {i18n('FinancingOptions.PreQualify.get_prequalified', null, null, {
              returnNullWhenEmpty: true,
            })}
          </span>
        </ModalTrigger>
      </div>
    );
  };

  if (showFinanceModalTrigger) {
    return <FinanceModalEntry />;
  }

  return (
    <>
      <div
        className={cx(
          'tds-o-flex-direction--column tds-flex--justify-center tds-flex-gap--16 tds--padding_top-16 incentives-toggle--container',
          {
            [classes]: classes,
          },
          {
            'tds--vertical_padding-bottom': isMobile,
          }
        )}
      >
        <If condition={!hideFinanceDisclaimer}>
          <FinanceDisclaimer
            className={cx("tds-o-no-margin", { 'tds-text--center': isCentered })}
            showLegacyFooterDisclaimers={showLegacyFooterDisclaimers}
          />
        </If>
        <div className={cx("tds-text--contrast-low", { 'tds-text--center': isCentered })}>
          <Choose>
            <When condition={decoupleFuelIncentives}>
              <FinanceCheckboxes
                dispatch={dispatch}
                triggerAnalytics={(key, checked) => {
                  Analytics.fireInteractionEvent(`${key}_savings_${checked ? 'check' : 'uncheck'}`);
                }}
                checkboxes={[
                  (otherIncentives?.length && {
                    key: 'OTHER_INCENTIVES',
                    text: i18n(
                      `FinancingOptions.incentives.${i18nFinanceType}.checkbox_other`,
                      disclaimerVars,
                      null,
                      {
                        specificOverride: checkboxLabelOverrides,
                        returnNullWhenEmpty: true,
                        specificOverrideOperator: 'OR',
                      }
                    ),
                    checked: hasCurrentIncentives,
                    action: updateSelectedIncentives(otherIncentives),
                  }),
                  {
                    key: 'FUEL',
                    text: i18n(
                      `FinancingOptions.incentives.${i18nFinanceType}.checkbox_fuel`,
                      disclaimerVars,
                      null,
                      {
                        specificOverride: [region && 'region'],
                        returnNullWhenEmpty: true,
                        specificOverrideOperator: 'OR',
                      }
                    ),
                    checked: hasFuelIncentives,
                    action: updateSelectedIncentives(['fuel']),
                  },
                ].filter(x => x)}
              />
            </When>
            <When condition={showProbableSavingsToggle}>
              <FormInputCheckbox
                className="incentives-toggle--section"
                name="showProbableSavings"
                onChange={() => {
                  const flag = !showProbableSavings;
                  toggleSavings(flag);
                  updateIncentives(flag ? eligibleIncentives : []);
                  Analytics.fireInteractionEvent(
                    `potential_savings_${showProbableSavings ? 'uncheck' : 'check'}`
                  );
                }}
                checked={showProbableSavings}
                label={
                  <span className="tds-display--block">
                    {showProbableSavings && pricePostfix && <span>{pricePostfix}</span>}
                    {i18n(
                      `FinancingOptions.incentives.${i18nFinanceType}.caption`,
                      disclaimerVars,
                      null,
                      {
                        specificOverride: disclaimerOverrides,
                        returnNullWhenEmpty: true,
                        specificOverrideOperator: 'OR',
                      }
                    )}
                  </span>
                }
              />
            </When>
            <Otherwise>
              {htmlToReact(i18n(
                `FinancingOptions.excludesSavings.${i18nFinanceType}.caption`,
                { ...disclaimerVars, FUEL_AMOUNT: formatCurrency(availableFuelSavings) },
                null,
                {
                  specificOverride: disclaimerOverrides,
                  returnNullWhenEmpty: true,
                  specificOverrideOperator: 'OR',
                }
              ))}
            </Otherwise>
          </Choose>
        </div>
        <div className={cx("coin-finance-entry-wrapper", { 'tds-flex--justify-center': isCentered })}>
          <If condition={isPreQualifyEnabled}>
            <PrequalModalEntry />
          </If>
          <FinanceModalEntry />
        </div>
      </div>
      <IncentivesSavings />
    </>
  );
};

function mapStateToProps(state) {
  const { userSelectedIncentives } = state?.Financial || {};
  const { showProbableSavingsToggle = false, decoupleFuelIncentives = false } =
    state?.SummaryPanel || {};
  const { current, currentAvailable = {} } = state?.Financial?.incentives || {};
  const { taxCredit = 0 } =
    state?.Pricing?.calculatorResult?.data?.apiResults?.incentives?.total || {};
  const incentivesTotal = state?.Financial?.incentives?.total;
  const { once = 0, fuel = 0 } = incentivesTotal || {};
  const { termLength: term } = state?.Pricing?.finplat?.output?.inputs || {};
  const { showAfterSavingsPriceForTrims: showProbableSavings } = state?.ReviewDetails || {};
  const eligibleIncentives = getCurrentAvailableTotal(state) || {};
  const { fuel: fuelIncentive } = current || {};
  let currentFilt = Object.keys(current)?.filter(
    x => !_get(current, `${x}[0].isPermanent`, false)
  );
  let currentAvailableFilt = Object.keys(currentAvailable)?.filter(
    x => !_get(currentAvailable, `${x}[0].isPermanent`, false)
  );
  if (decoupleFuelIncentives) {
    currentFilt = currentFilt?.filter(x => x !== 'fuel');
    currentAvailableFilt = currentAvailableFilt?.filter(x => x !== 'fuel');
  }

  const potentialSavings = _isEmpty(currentFilt)
    ? Math.abs(eligibleIncentives?.once + eligibleIncentives?.taxCredit)
    : Math.abs(once + taxCredit);
  let fuelSavings = _isEmpty(currentFilt) ? Math.abs(eligibleIncentives?.fuel) : Math.abs(fuel);
  const region = getRegionName(state) || '';
  let disclaimerOverrides = [];
  if (potentialSavings && !fuelSavings) {
    disclaimerOverrides.push('savings');
  }
  if (fuelSavings && !potentialSavings) {
    disclaimerOverrides.push('fuel');
  }
  if (potentialSavings && region) {
    disclaimerOverrides.push('region');
  }
  let checkboxLabelOverrides = [];
  if (decoupleFuelIncentives && _isEmpty(currentFilt)) {
    checkboxLabelOverrides = [eligibleIncentives?.taxCredit && 'taxCredit', eligibleIncentives?.once && 'savings']
  } else {
    checkboxLabelOverrides = [taxCredit && 'taxCredit', once && 'savings']
  }
  const incentivesMonthly = term ? Math.round(potentialSavings / term) || 0 : 0;
  const fuelMonthly = _isEmpty(fuelIncentive)
    ? getCurrentAvailableMonthlyFuel(state)
    : getMonthlyFuelSavings(state);
  const monthlySavings = Math.abs(fuelMonthly) + Math.abs(incentivesMonthly);
  const productId = getFinanceProductId(state);
  const isPreQualifyEnabled = state?.App?.isPreQualifyEnabled && productId || false;

  return {
    financeTab: getFinanceProductType(state),
    showProbableSavings,
    potentialSavings,
    disclaimerOverrides,
    eligibleIncentives: userSelectedIncentives?.length
      ? Object.values(currentAvailable).flat()
      : undefined,
    disclaimerVars: {
      MONTHLY_SAVINGS: formatMonthlyPrice(monthlySavings),
      TAX_CREDIT: formatCurrency(Math.abs(eligibleIncentives?.taxCredit || 0)),
      FUEL_AMOUNT: formatCurrency(Math.abs(currentAvailable?.fuel?.[0]?.amount || 0)),
      FUEL_YEAR: getFuelYears(state),
      POTENTIAL_SAVINGS: formatCurrency(potentialSavings),
      MONTHLY_INCENTIVES: formatMonthlyPrice(Math.abs(incentivesMonthly)),
      MONTHLY_FUEL: formatMonthlyPrice(Math.abs(fuelMonthly)),
      REGION_NAME: region,
      OTHER_INCENTIVES: !_isEmpty(currentFilt) ? formatCurrency(Math.abs(once)) : formatCurrency(Math.abs(eligibleIncentives?.once)),
    },
    isMobile: state?.App?.isLayoutMobileAdjusted,
    pricePostfix: getPricePostfix(state),
    showProbableSavingsToggle,
    hideToggle: _isEmpty(currentAvailable),
    availableFuelSavings: Math.abs(eligibleIncentives?.fuel),
    decoupleFuelIncentives,
    hasFuelIncentives: !_isEmpty(fuelIncentive),
    hasCurrentIncentives: !_isEmpty(currentFilt),
    otherIncentives: !_isEmpty(currentFilt) ? currentFilt : currentAvailableFilt,
    checkboxLabelOverrides,
    isPreQualifyEnabled,
    isCentered: !decoupleFuelIncentives,
    region,
  };
}

const mapDispatchToProps = dispatch => ({
  toggleSavings: flag => dispatch(toggleTrimSavingsPrice(flag)),
  updateIncentives: incentives => dispatch(updateIncentives(incentives)),
  dispatch: action => dispatch(action),
});

IncentivesToggle.propTypes = {
  financeTab: string,
  toggleSavings: func.isRequired,
  showProbableSavings: bool.isRequired,
  disclaimerOverrides: arrayOf(string),
  disclaimerVars: shape({}),
  updateIncentives: func.isRequired,
  classes: string,
  isMobile: bool.isRequired,
  pricePostfix: string,
  showProbableSavingsToggle: bool.isRequired,
  hideToggle: bool.isRequired,
  showLegacyFooterDisclaimers: bool,
  showFinanceModalTrigger: bool,
  hideFinanceDisclaimer: bool,
  isPreQualifyEnabled: bool.isRequired,
  dispatch: func.isRequired,
  hasFuelIncentives: bool.isRequired,
  hasCurrentIncentives: bool.isRequired,
  checkboxLabelOverrides: arrayOf(string),
  otherIncentives: arrayOf(string),
  decoupleFuelIncentives: bool.isRequired,
};

IncentivesToggle.defaultProps = {
  financeTab: '',
  pricePostfix: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(IncentivesToggle);
