import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import { localizeUrl } from '@tesla/parse-locale';
import DOMPurify from 'dompurify';
import { normalizeGrayLocale, normalizeT4BLocale } from './locale';
import { isPreOrder } from './index';
import { getSelectedOptions } from 'selectors';
import { getInventorySwapPaymentErrorZip } from 'utils';
import {
  PRESALE,
  SALE,
  NAVIGATION_VIEW_EARLYDELIVERY,
  NAVIGATION_VIEW_OVERVIEW,
  NAVIGATION_VIEW_PAYMENT,
  Models,
} from 'dictionary';
import { isIOS } from './device';
import { getFullModelName, checkIfNV35Enabled } from '../selectors';

/**
 * Construct URI (with or without baseUrl & locale)
 * @param  {String} uri     current uri path
 * @param  {String} baseUrl base url
 * @param  {string}  locale  current locale
 * @param  withBaseUrl      force add base url
 * @return {String} url     updated url
 */
export function constructUrl(uri, baseUrl = '', locale = 'en_US', withBaseUrl = false) {
  // Check if uri has a leading slash (API endpoints)
  if (uri?.charAt(0) === '/') {
    if (!withBaseUrl) {
      return uri;
    }
    uri = uri.slice(1);
  }
  let localeStr = '/';
  if (locale !== 'en_US' && locale !== 'zh_CN') {
    let normalizedLocale = normalizeGrayLocale(locale);
    if (normalizedLocale === 'ja_JP') {
      normalizedLocale = 'jp';
    }
    localeStr = `/${normalizedLocale}/`;
  }
  return `${baseUrl}${localeStr}${uri}`;
}

/**
 * A function to get a json object of query parameters from location either passed or fetched
 * from window location
 *
 * @param {Boolean} loc - Location string | false
 * @param {Boolean} hashBased - Whether the location url is hash based or not
 * @return {object} Object containing query parameters
 */
export function getQueryParameters(query = '', loc = false, hashBased = false) {
  let search_query;
  let pos;
  const results = new Map();
  let locVal = loc;
  if (query) {
    search_query = query.replace('?', '');
  } else if (hashBased) {
    if (!locVal) {
      locVal = window.location.href;
    }
    pos = locVal.indexOf('?');
    if (pos === -1) {
      return Object.fromEntries(results.entries());
    }
    search_query = locVal.substr(pos + 1);
  } else {
    if (!locVal) {
      locVal = window.location.search;
    }
    search_query = locVal.substr(1);
  }
  // eslint-disable-next-line consistent-return
  search_query.split('&').forEach(part => {
    let partVal = part;
    if (!partVal) {
      return Object.fromEntries(results.entries());
    }
    partVal = partVal.split('+').join(' ');
    const vp = partVal.indexOf('=');
    let key = vp > -1 ? partVal.substr(0, vp) : partVal;
    const valP = vp > -1 ? decodeURIComponent(partVal.substr(vp + 1)) : '';
    let val = '';
    try {
        val = vp > -1 ? decodeURIComponent(valP) : '';
        if (val) {
          val = DOMPurify.sanitize(val);
        }
    } catch (err) {
    }
    const from = key.indexOf('[');
    if (from === -1) {
      results.set(decodeURIComponent(key), valP);
    } else {
      const to = key.indexOf(']');
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!results.get(key)) {
        results.set(key, []);
      }
      if (!index) {
        results.set(key, val);
      } else {
        let nestedObj = results.get(key);
        if (nestedObj) {
            nestedObj[index] = val;
            results.set(key, nestedObj);
        }
      }
    }
  });
  return Object.fromEntries(results.entries());
}

/**
 * Redirects the user to the current page, but on a different locale.
 *
 * @param locale
 */
export function redirectoToLocale(locale, addRedirect = false, modelCode, _redirectPath) {
  // Get all the query params from the current URL excluding the redirect=no param
  const urlParams = _omit(getQueryParameters(), 'redirect');

  let redirectPath = _redirectPath;

  if (redirectPath == null) {
    if (modelCode === 'm3') {
      redirectPath = '/model3/design';
    } else if (modelCode === 'ms') {
      redirectPath = '/models/design';
    } else if (modelCode === 'mx') {
      redirectPath = '/modelx/design';
    } else if (modelCode === 'my') {
      redirectPath = '/modely/design';
    } else if (modelCode === 'mt') {
      redirectPath = '/roadster/reserve';
      if (locale === 'zh_CN') {
        redirectPath = '/teslaroadster/reserve';
      }
    } else if (modelCode === 'ts') {
      redirectPath = '/semi/reserve';
    } else if ([Models.CYBERTRUCK, Models.CYBERTRUCK_MP].includes(modelCode)) {
      redirectPath = '/cybertruck/design';
    }
  }

  let normalizedLocale = locale;
  if (locale === 'en_US' || locale === 'zh_CN') {
    normalizedLocale = '';
  }

  redirectPath = localizeUrl(redirectPath, {
    base_url: locale === 'zh_CN' ? _get(window.tesla, 'sibling_base_url', '') : '',
    locale: normalizedLocale,
    delimiter: '_',
  });

  // add redirect=no if needed
  if (addRedirect) {
    urlParams.redirect = 'no';
  }

  // if the url params are not empty add the query string to the redirect path
  if (!_isEmpty(urlParams)) {
    const query = Object.keys(urlParams)
      .map(key => `${key}=${urlParams[key]}`)
      .join('&');
    redirectPath += `?${query}`;
  }

  // Add hash part if needed
  if (window.location.hash) {
    redirectPath += window.location.hash;
  }

  window.location = redirectPath;
}

export const getOrderAgreementUrl = state => {
  const { OMS, App, ReviewDetails, ApplicationFlow } = state;
  const { market, model } = OMS?.oms_params || {};
  const {
    sibling,
    isWeChatMiniEnv,
    routes: { termsFile: getTermsFileUrl, wechatMiniPdfView: pdfViewUrl },
  } = App;
  let { locale } = App;
  locale = normalizeT4BLocale(locale);
  const {
    isOldVersionOrderAgreement,
    oldOrderAgreementVersion,
    isPreSale = {},
    useCGSOrderAgreement,
    isReservationOriginallyRefundable,
  } = ReviewDetails;
  const { canModifyOrder = false } = ApplicationFlow;
  let isCGSOrderAgreementEnabled = useCGSOrderAgreement;
  const url = isCGSOrderAgreementEnabled
    ? getTermsFileUrl || 'configurator/api/v3/terms'
    : 'order/download-order-agreement?redirect=no';
  let isPreSaleOrder = isPreOrder() || isPreSale?.source;
  if (market === 'CN' && canModifyOrder && checkIfNV35Enabled(state)) {
    isPreSaleOrder = !!isReservationOriginallyRefundable;
  }
  const saleType = isPreSaleOrder ? PRESALE : SALE;

  if (isWeChatMiniEnv && !isIOS()) {
    const fullOrderAgreementUrl = `${constructUrl(
      url,
      sibling,
      locale,
      true
    )}?locale=${locale}&model=${model}&saleType=${saleType}`;
    return `${constructUrl(pdfViewUrl, sibling, locale)}?url=${encodeURIComponent(
      fullOrderAgreementUrl
    )}`;
  }

  let orderAgreementUrl;
  if (isCGSOrderAgreementEnabled) {
    orderAgreementUrl = `${constructUrl(
      url,
      sibling
    )}?locale=${locale}&model=${model}&saleType=${saleType}`;
  } else {
    const presale = isPreSaleOrder ? `&is_presale=${isPreSaleOrder}` : '';
    const orderAgreementVersion = isOldVersionOrderAgreement
      ? `&version=${oldOrderAgreementVersion}`
      : '';

    orderAgreementUrl = `${constructUrl(
      url,
      sibling,
      locale
    )}&country=${market}&model_code=${model}${presale}${orderAgreementVersion}`;
  }

  return orderAgreementUrl;
};

export const getCoeUrl = (sibling, locale, market, useCGSCoe) => {
  const coeUrl = useCGSCoe
    ? '/configurator/api/v3/coe'
    : `sites/all/modules/custom/tesla_smartling/smartling/api-translations/documents/certificate-of-entitlement/${locale.replace(
        '_',
        '-'
      )}/COE_Agreement.pdf`;
  return `${constructUrl(
    coeUrl,
    sibling
  )}?locale=${locale}&category=CertificateOfEntitlementAgreement`;
};

/**
 * A map a query object to a query params string
 *
 * @param   {object}    queryObj    Query object
 * @return  {string}    queryParams Query params string
 */
export function arrayToQueryParams(queryObj) {
  const str = [];
  const keys = Object.keys(queryObj);
  for (let i = 0; i <= keys.length; i += 1) {
    if (Object.prototype.hasOwnProperty.call(queryObj, keys[i])) {
      str.push(`${keys[i]}=${queryObj[keys[i]]}`);
    }
  }
  return str.join('&');
}

/**
 * Get full url with options & GA params that will be shared and shortened with bit.ly
 * @param  {Object} state
 * @return {String}
 */
export function getShareUrl(state) {
  const { sibling } = state.App;
  const modelName = getFullModelName(state);
  if (!sibling) {
    return null;
  }
  const options = getSelectedOptions({ state }).optionCodes.join(',');
  const ga_params = arrayToQueryParams({
    utm_medium: 'share',
    utm_source: 'sdm3-configurator',
    utm_campaign: 'save-design-lite',
  });

  return `${sibling}/${modelName}/design?redirect=no&shareUrl=true&cfg=${options}&${ga_params}`;
}

/**
 * Get url for inventory based in location
 * @param  {String} modelCode
 * @param  {String} countryCode
 * @param  {Number} zipCode
 * @param  {string} sibling

 * @return {String}
 */
export const getInventoryLink = ({ sibling, modelCode, locale, queryParams = null }) => {
  const inventoryUrl = `/inventory/new/${modelCode}`;
  let normalizedLocale = locale;
  if (locale === 'en_US' || locale === 'zh_CN') {
    normalizedLocale = '';
  }
  const url = localizeUrl(inventoryUrl, {
    base_url: locale === 'zh_CN' ? sibling : '',
    locale: normalizedLocale,
    delimiter: '_',
  });
  let queryParamsStr = '';
  if (queryParams) {
    queryParamsStr = `?${arrayToQueryParams(queryParams)}`;
  }
  return `${url}${queryParamsStr}`;
};

/**
 * Check if current hash is early delivery
 *
 * @return {Boolean}
 */
export const isHashBlockedFromReLoading = () => {
  return window.location.hash.substring(1) === NAVIGATION_VIEW_EARLYDELIVERY && !getInventorySwapPaymentErrorZip();
};

/**
 * Get Edit design navigation key
 *
 * @param  {Object} state
 * @return {String}
 */
export const getEditDesignNavigationKey = state => {
  const { isInventorySwapEnabled } = state.App || {};
  const { section } = state.Navigation || {};
  const { error, pickupLocations, earlyVehicleDesigns } = state.ReviewDetails?.vehicleDesign || {};
  let navigationKey = NAVIGATION_VIEW_OVERVIEW;
  const showEarlyDelivery = !!(pickupLocations || earlyVehicleDesigns?.length);
  if (isInventorySwapEnabled && section === NAVIGATION_VIEW_PAYMENT && showEarlyDelivery && !error) {
    navigationKey = NAVIGATION_VIEW_EARLYDELIVERY;
  }
  return navigationKey;
};
