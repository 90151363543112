import { UPDATE_INCENTIVES, OPTIMIZELY_EXP, UPDATE_FINPLAT_DATA } from 'dictionary';
import { toggleTrimSavingsPrice, setLoanTerm } from 'actions';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import { map, filter } from 'rxjs/operators';
import { memoize, getFinplatLexiconData } from 'utils';
import { parseFinplatLexicons } from 'selectors';

export const updateSelectedIncentives = (selectedIncentives = []) => {
  return (dispatch, getState) => {
    const state = getState();
    const { incentives } = state?.Financial || {};
    const { current, currentAvailable } = incentives || {};
    const data = selectedIncentives.reduce((res, incentive) => {
      if (!_isEmpty(current) && current[incentive]) {
        const { [incentive]: selected, ...rest } = (_isEmpty(res) ? current : res) || {};
        res = selected ? rest : {};
      } else {
        res = { ...res, ...current, [incentive]: currentAvailable[incentive] };
      }
      return res;
    }, {});

    return dispatch({
      type: UPDATE_INCENTIVES,
      incentives: Object.values(data).flat(),
    });
  };
};

export const updateIncentives = updatedIncentives => {
  return (dispatch, getState) => {
    if (!_isEmpty(updatedIncentives) || !_isArray(updatedIncentives)) {
      return dispatch({
        type: UPDATE_INCENTIVES,
        incentives: updatedIncentives,
      });
    }
    // should check for includedInPurchasePrice incentives and always display these
    const state = getState();
    const { incentives } = state?.Financial || {};
    const currentIncentives = incentives?.current ?? {};

    const purchasePriceIncentives = Object.values(currentIncentives).filter((incentive = []) => {
      const filteredIncentives = incentive.filter(x => x?.period === 'includedInPurchasePrice');
      return _isEmpty(filteredIncentives) ? false : true;
    });

    return dispatch({
      type: UPDATE_INCENTIVES,
      incentives: purchasePriceIncentives,
    });
  };
};

export const updateFilteredIncentives = (value, subType) => {
  return (dispatch, getState) => {
    const state = getState();
    const { showAfterSavingsPriceForTrims } = state?.ReviewDetails || {};
    const { userSelectedIncentives, incentives } = state?.Financial || {};
    const currentIncentives = incentives?.current ?? {};
    let includedInPriceIncentives = [];

    // When updating incentives, find out which ones are included in price and do not override them
    const filteredIncentives = Object.values(currentIncentives).reduce((acc, incentive = []) => {
      incentive?.map(x =>
        x?.period === 'includedInPurchasePrice'
          ? includedInPriceIncentives.push(x)
          : showAfterSavingsPriceForTrims && acc.push(x)
      );
      return acc;
    }, []);
    const selected = [
      ...(!userSelectedIncentives ? filteredIncentives : userSelectedIncentives),
      ...includedInPriceIncentives,
    ];
    let data = Array.isArray(value) ? value : [];
    // condition to uncheck input on UI
    if (selected?.find(x => x?.incentiveType === value && x?.incentiveSubType == subType)) {
      data = selected.filter(v => {
        if (subType) {
          return subType !== v?.incentiveSubType;
        }
        return !v?.incentiveSubType ? v?.incentiveType !== value : v;
      });
    } else { // condition to check/select input on UI
      data = [
        ...selected,
        incentives?.currentAvailable?.[value]?.find(x =>
          subType ? x?.incentiveSubType == subType : x
        ),
      ];
    }
    dispatch({
      type: UPDATE_INCENTIVES,
      incentives: data,
    });

    if (
      data?.length &&
      Object.keys(data)?.length === Object.keys(incentives?.currentAvailable || {})?.length &&
      !showAfterSavingsPriceForTrims
    ) {
      return dispatch(toggleTrimSavingsPrice(true));
    } else if (!data?.length && showAfterSavingsPriceForTrims) {
      return dispatch(toggleTrimSavingsPrice(false));
    }
  };
};

export const setFinplatData = (data = {}) => ({
  type: UPDATE_FINPLAT_DATA,
  data,
});

export const updateFinanceDataOnOptimizelyExp = (action$) =>
  action$.pipe(
    filter(action => [OPTIMIZELY_EXP].includes(action.type)),
    map(({ id }) => dispatch => {
      const optimizelyExpForTerm = id?.startsWith('EXPERIMENT_TERM_APR:');
      if (optimizelyExpForTerm) {
        const loanTerm = parseInt(id?.split(':')?.[1]) || null;
        loanTerm && dispatch(setLoanTerm(loanTerm));
      }
    })
  );

export const fetchFinplatData = async (state, regionCode, trimCode) => {
  const {
    OMS: { oms_params: { market, model } = {} } = {},
  } = state || {};

  try {
    const memoizedFinplatData = memoize(getFinplatLexiconData, { market, model, regionCode, trimCode });
    const result = await memoizedFinplatData(state, { regionCode, trimCode });
    return setFinplatData(parseFinplatLexicons(state, result?.data || {}));
  } catch (error) {
    console.error(`Error fetching finplat data for trim code ${trimCode}:`, error);
    return { type: 'NOOP' };
  }
};

export const getFinplatDataByTrim = trimCode => {
  return async (dispatch, getState) => {
    const state = getState();
    const { isFinplatAjaxEnabled } = state?.App || {};
    if (!trimCode || !isFinplatAjaxEnabled) {
      return;
    }
    const { region_code } = state?.SummaryPanel || {};
    return dispatch(await fetchFinplatData(state, region_code, trimCode));
  }
}