import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { i18n, getTerritoryCodeList, filterSalesRegion } from 'utils';
import { hideRegistrationTypeAboveSummary } from 'selectors';
import { setSummaryPanelRegion, openModal } from 'actions';
import { USER_LOCATION_MODAL, MODAL_SMALL } from 'dictionary';
import StateDropdown from '../StateDropdown';
import RegistrationTypeSelector from '../RegistrationTypeSelector';

const RegistrationStateAndType = ({
  showTerritoryList,
  setRegion,
  regionName,
  showLocationModal,
  classes = '',
  showRegistrationType = false,
  showRegistrationTypeSelector,
  regType,
  linkClasses = '',
  isEditDesign,
  showRegistrationSelector,
  justifyCenter = false,
  hideDisclaimer = false,
}) => {
  if (!showTerritoryList && !showRegistrationSelector) {
    return null;
  }

  const [show, setShow] = useState(showRegistrationSelector);
  const showRegType = showRegistrationTypeSelector && showRegistrationType;

  return (
    <div className={classes}>
      <Choose>
        <When condition={show}>
          <If condition={showTerritoryList}>
            <StateDropdown
              onChange={region => {
                setRegion(region);
                setShow(false);
              }}
              id="financing-options--state-selector"
            />
          </If>
          <If condition={showRegType}>
            <RegistrationTypeSelector
              radioName="summary"
              justifyCenter={justifyCenter}
              classes={classnames({'tds-text--center': justifyCenter})}
            />
          </If>
        </When>
        <Otherwise>
          <div className={linkClasses}>
            <a onClick={isEditDesign ? null : () => showLocationModal()} className={classnames({'tds-link': !isEditDesign})}>
              {i18n('FinancingOptions.registration_selection', { REGION: regionName }, null, {
                specificOverride: regType,
                specificOverrideOperator: 'OR',
              })}
            </a>
          </div>
        </Otherwise>
      </Choose>
      <If condition={!hideDisclaimer && (showRegType || showTerritoryList)}>
        <p className="tds-text--caption tds-o-vertical_padding-top--1x">{i18n('Review.summary_page__disclaimer')}</p>
      </If>
    </div>
  );
};

RegistrationStateAndType.propTypes = {
  showTerritoryList: bool.isRequired,
  setRegion: func.isRequired,
  regionName: string,
  showLocationModal: func.isRequired,
  classes: string,
  showRegistrationTypeSelector: bool.isRequired,
  isEditDesign: bool,
  showRegistrationSelector: bool,
  justifyCenter: bool,
  hideDisclaimer: bool,
};

RegistrationStateAndType.defaultProps = {
  regionName: '',
  classes: '',
  isEditDesign: false,
  showRegistrationSelector: false,
  justifyCenter: false,
  hideDisclaimer: false,
};

function mapStateToProps(state) {
  const {
    App: { countryCode } = {},
    FinancingOptions: { showTerritoryList, showRegistrationSelector, showTerritoryListForInventory } = {},
    SummaryPanel: { region_code: region } = {},
    ReviewDetails: {
      showRegistrationTypeSelector,
      RegistrationDetail: { RegistrantType: regType } = {},
      product,
    } = {},
    ApplicationFlow: { canModifyOrder: isEditDesign = false } = {},
  } = state || {};
  const { showLocationSelection } = filterSalesRegion();

  const regionsData =
    window.tesla?.regionsData?.map(({ RegionCode, ...rest }) => {
      const regionCodeNoSpaces = RegionCode.replace(' ', '');
      return {
        ...rest,
        RegionCode: regionCodeNoSpaces.charAt(0).toLowerCase() + regionCodeNoSpaces.slice(1),
      };
    }) || [];

  const regionName = regionsData.find(reg => reg?.RegionCode === region)?.DisplayName || region;
  return {
    showTerritoryList: showTerritoryList || (showTerritoryListForInventory && product) ? !!getTerritoryCodeList(countryCode, '')?.length : false,
    showRegistrationTypeSelector:
      showRegistrationTypeSelector && !hideRegistrationTypeAboveSummary(state),
    regType,
    regionName,
    isEditDesign,
    showRegistrationSelector: (product && showLocationSelection) ? false : showRegistrationSelector,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRegion: regionCode => dispatch(setSummaryPanelRegion(regionCode)),
    showLocationModal: () => dispatch(openModal(USER_LOCATION_MODAL, {
      className: 'session-time-out--container',
      genericWrapper: true,
      size: MODAL_SMALL,
    })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStateAndType);
